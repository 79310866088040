import React, { useState } from 'react';

const ConnectedIcon = ({ onClick, isConnected, isAuthenticated }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Determine the fill color based on state
  const getIconColor = () => {
    if (isHovered && !isAuthenticated) return 'green';
    if (isHovered && isAuthenticated) return 'red';
    if (isConnected && !isAuthenticated) return 'orange';
    if (isAuthenticated && isConnected) return 'green';
    return 'gray';
  };

  // Determine the opacity based on state
  const getAlpha = () => {
    if (isHovered) return '0.9';
    if (!isConnected && !isAuthenticated) return '0.7';
    if (isConnected && !isAuthenticated) return '0.85';
    if (isAuthenticated && isConnected) return '1';
    return '0.7';
  };

  // Define the icon's style based on connection and authentication states
  const iconStyle = isConnected && isAuthenticated
    ? {
        verticalAlign: 'middle',
        paddingBottom: '0.1vw',
        cursor: 'pointer',
        width: '1vw',
      }
    : {
        position: 'absolute',
        left: '40vw',
        top: '15vw',
        width: '100%',
        cursor: 'pointer',
      };

  // Determine the text to display based on state
  const getText = () => {
    if (!isConnected && !isAuthenticated) return 'Connect';
    if (isConnected && !isAuthenticated) return 'Connecting';
    return '';
  };

  return (
    <svg
      width={isConnected && isAuthenticated ? "8%" : "100%"}
      height={isConnected && isAuthenticated ? "8%" : "100%"}
      viewBox="0 0 24 24" // Maintain the original viewBox
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={iconStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      preserveAspectRatio="xMidYMid meet" // Ensure aspect ratio is maintained
    >
      <defs>
        {/* Define the shadow filter */}
        <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
          <feDropShadow dx="0.2" dy="0.2" stdDeviation="0.2" floodColor="black" floodOpacity="0.8" />
        </filter>
      </defs>

      {/* Integrated "Glare" SVG Paths */}
      <g transform="scale(0.06)"> {/* Scale down from 400x400 to 24x24 (24/400 = 0.06) */}
        <g id="Layer1_0_FILL">
          <path
            fill={getIconColor()}
            stroke="none"
            d="
              M 313.9 86.25
              Q 266.75 39.15 200.05 39.15 133.35 39.15 86.2 86.25 
              39.1 133.45 39.1 200.15 39.1 266.85 86.2 313.95 
              133.35 361.1 200.05 361.1 266.75 361.1 313.9 313.95 
              361.05 266.85 361.05 200.15 361.05 133.45 313.9 86.25
              M 65.1 200.2
              Q 65.1 261.1 104.6 304.2 137.2759765625 339.767578125 
              181.15 346 132.30390625 340.30390625 96.1 304.1 
              53.1 261.05 53.1 200.15 53.1 139.25 96.1 96.15 
              134.212890625 58.0814453125 186.35 53.75 
              139.1908203125 58.5416015625 104.6 96.15 
              65.1 139.3 65.1 200.2 Z
            "
            opacity={getAlpha()}
          />

          <path
            fill="#FFFFFF" // Maintains the original white fill
            stroke="none"
            d="
              M 104.6 304.2
              Q 65.1 261.1 65.1 200.2 65.1 139.3 104.6 96.15 
              139.1908203125 58.5416015625 186.35 53.75 
              134.212890625 58.0814453125 96.1 96.15 
              53.1 139.25 53.1 200.15 53.1 261.05 96.1 304.1 
              132.30390625 340.30390625 181.15 346 
              137.2759765625 339.767578125 104.6 304.2 Z
            "
            opacity={getAlpha()}
          />
        </g>

        <path
          id="Layer1_0_1_STROKES"
          stroke="#CCCCCC" // Maintain the original stroke color
          strokeWidth="5" // Original stroke width without scaling
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="
            M 361.05 200.15
            Q 361.05 266.85 313.9 313.95 
            266.75 361.1 200.05 361.1 
            133.35 361.1 86.2 313.95 
            39.1 266.85 39.1 200.15 
            39.1 133.45 86.2 86.25 
            133.35 39.15 200.05 39.15 
            266.75 39.15 313.9 86.25 
            361.05 133.45 361.05 200.15 Z
          "
          opacity={getAlpha()}
        />
      </g>

      {/* Conditional Text */}
      {(!isConnected || !isAuthenticated) && (
        <text
          x="12" // Center the text horizontally within 24x24 viewBox
          y="12" // Center the text vertically within 24x24 viewBox
          textAnchor="middle"
          dominantBaseline="middle"
          fill="white"
          fontSize="3.5" // Adjusted font size for 24x24 viewBox
          fontWeight="bold"
          filter="url(#shadow)"
        >
          {getText()}
        </text>
      )}
    </svg>
  );
};

export default ConnectedIcon;
