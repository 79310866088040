export const CoreERC20Address = "0x1005Da88da56906f22B7Cab517b558EDdA1C7497";
export const MemeNFTAddress = "0xe8e36C1a5Ab043cDC15c1E8b1E59a2Ba682a08bd";
export const PresaleManagerAddress = "0x0c9A4c748816731111cE6d6f2428C421966092D8";
export const VestingManagerAddress = "0x168DA67842Dbc5Fa774D6f72d031e2d9dF042D12";
export const TokenomicsManagerAddress = "0x4F6D9Bf5cA9816d9e6792363b25d748Fb29bA83C";
export const VotingManagerAddress = "0xc10fbDBa18041447F8e2386F27d1Fa739228a854";
export const NameNFTAddress = "0x1Ee36d0Ba9082D6C9eD4a9248B31cE81cF4e894C";
export const NameRegistryAddress = "0x84a6a4114c12601935dB0d983D30aDFc7C9AEb9d";
export const MasterSVGAddress = "0x22d6dF65a83C90077bF027Cd5e18D350269C94Ad";
export const AchievementManagerAddress = "0x6E446638e5a9b1f9E7ae6Ffc532726476d5548A5";
export const SVGGeneratorAddress = "0x51EfC284DF822E6d679e43ba1249BB291b04d7aC";