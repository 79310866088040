export const applyLiquifyEffect = (canvas, brushSizeRef, smoothingRef, activeLayer) => {
  
 
    const MOUSE_UPDATE_DELAY = 10;
    const MIN_DISTANCE = 6;

    let timer;
    let canUpdate = true;
    let oldMouseX = 0;
    let oldMouseY = 0;

    const updateCoords = (e) => {
        let coord_x, coord_y;
        if (e.touches && e.touches.length === 1) {
            let touch = e.touches[0];
            coord_x = touch.pageX;
            coord_y = touch.pageY;
        } else {
            coord_x = e.clientX;
            coord_y = e.clientY;
        }
        if (canUpdate) {
            let box = canvas.getBoundingClientRect();
            let cx = (coord_x - box.left) * (canvas.width / box.width);
            let cy = (coord_y - box.top) * (canvas.height / box.height);
            let dx = cx - oldMouseX;
            let dy = cy - oldMouseY;
            if ((dx * dx + dy * dy) >= MIN_DISTANCE * MIN_DISTANCE) {
                liquify(cx, cy, dx, dy);
                oldMouseX = cx;
                oldMouseY = cy;
            }
            canUpdate = false;
            timer = window.setTimeout(() => {
                canUpdate = true;
            }, MOUSE_UPDATE_DELAY);
        }
    };

    const liquify = (x, y, dx, dy) => {
        const c = canvas.getContext('2d');
        let currentBrushSize = brushSizeRef.current;
        let pinchHardness = 0.5;
    
        let radius = currentBrushSize / 2;
        x -= radius;
        y -= radius;
    
        if (x < 0 || y < 0 || x + currentBrushSize >= canvas.width || y + currentBrushSize >= canvas.height) {
            return;
        }
    
        let bitmap = c.getImageData(x, y, currentBrushSize, currentBrushSize);
        let buffer = c.createImageData(bitmap.width, bitmap.height);
        let d = bitmap.data;
        let _d = buffer.data;
    
        let maxDisplacement = radius * pinchHardness;
    
        for (let row = 0; row < bitmap.height; row++) {
            for (let col = 0; col < bitmap.width; col++) {
                let xd = col - radius;
                let yd = row - radius;
                let dist = Math.sqrt(xd * xd + yd * yd);
    
                if (dist <= radius) {
                    let intensity = Math.cos((dist / radius) * Math.PI) * 0.5 + 0.5;
                    let radiusFade = 0.5;
                    intensity = Math.pow(intensity, radiusFade);
    
                    let displaceX = -dx * intensity * maxDisplacement / radius;
                    let displaceY = -dy * intensity * maxDisplacement / radius;
    
                    let fromX = col + displaceX;
                    let fromY = row + displaceY;
    
                    // Ensure source position is within bounds
                    fromX = Math.min(Math.max(fromX, 0), bitmap.width - 1);
                    fromY = Math.min(Math.max(fromY, 0), bitmap.height - 1);
    
                    // Use a mix of nearest neighbor and bilinear interpolation
                    let x0 = Math.floor(fromX);
                    let y0 = Math.floor(fromY);
                    let x1 = Math.min(x0 + 1, bitmap.width - 1);
                    let y1 = Math.min(y0 + 1, bitmap.height - 1);
    
                    let wx = fromX - x0;
                    let wy = fromY - y0;
    
                    let targetIndex = (col + row * bitmap.width) * 4;
    
                    for (let i = 0; i < 4; i++) {
                        if (wx < 0.33 && wy < 0.33) {
                            _d[targetIndex + i] = d[(x0 + y0 * bitmap.width) * 4 + i];
                        } else if (wx > 0.67 && wy < 0.33) {
                            _d[targetIndex + i] = d[(x1 + y0 * bitmap.width) * 4 + i];
                        } else if (wx < 0.33 && wy > 0.67) {
                            _d[targetIndex + i] = d[(x0 + y1 * bitmap.width) * 4 + i];
                        } else if (wx > 0.67 && wy > 0.67) {
                            _d[targetIndex + i] = d[(x1 + y1 * bitmap.width) * 4 + i];
                        } else {
                            // Bilinear interpolation for smoother transitions
                            let top = d[(x0 + y0 * bitmap.width) * 4 + i] * (1 - wx) + d[(x1 + y0 * bitmap.width) * 4 + i] * wx;
                            let bottom = d[(x0 + y1 * bitmap.width) * 4 + i] * (1 - wx) + d[(x1 + y1 * bitmap.width) * 4 + i] * wx;
                            _d[targetIndex + i] = Math.round(top * (1 - wy) + bottom * wy);
                        }
                    }
                } else {
                    let index = (col + row * bitmap.width) * 4;
                    _d[index] = d[index];
                    _d[index + 1] = d[index + 1];
                    _d[index + 2] = d[index + 2];
                    _d[index + 3] = d[index + 3];
                }
            }
        }
    
        c.putImageData(buffer, x, y);
    };


    const startInteraction = (e, type) => {
        if (activeLayer === 'liquify') {
            let box = canvas.getBoundingClientRect();
            if (type === 'mouse') {
                oldMouseX = (e.clientX - box.left) * (canvas.width / box.width);
                oldMouseY = (e.clientY - box.top) * (canvas.height / box.height);
                canvas.onmousemove = updateCoords;
            } else if (type === 'touch') {
                let touch = e.touches[0];
                oldMouseX = (touch.pageX - box.left) * (canvas.width / box.width);
                oldMouseY = (touch.pageY - box.top) * (canvas.height / box.height);
                canvas.ontouchmove = updateCoords;
            }
            window.clearTimeout(timer);
            canUpdate = true;
        }
    };

    const endInteraction = () => {
        canvas.onmousemove = null;
        canvas.ontouchmove = null;
    };

    canvas.onmousedown = (e) => startInteraction(e, 'mouse');
    canvas.onmouseup = endInteraction;
    canvas.ontouchstart = (e) => startInteraction(e, 'touch');
    canvas.ontouchend = endInteraction;
};

export default applyLiquifyEffect;
