

// components/OrientationOverlay.jsx
import React, { useEffect, useState } from 'react';




const isMobile = () => {
    if (typeof navigator === 'undefined') return false;
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};



const OrientationOverlay = () => {
    const [isPortrait, setIsPortrait] = useState(false);

    const checkOrientation = () => {
        if (isMobile()) {
            setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
        } else {
            setIsPortrait(false);
        }
    };

    useEffect(() => {
        checkOrientation();
        window.addEventListener('resize', checkOrientation);
        window.addEventListener('orientationchange', checkOrientation);

        return () => {
            window.removeEventListener('resize', checkOrientation);
            window.removeEventListener('orientationchange', checkOrientation);
        };
    }, []);

    if (!isPortrait) return null;

    return (
        <div style={styles.overlay}>
            <div style={styles.messageContainer} role="alert">
           
                <p style={styles.message}>
                    Pleaf rotate your devife to landfcape view to ufe MorFER.
                </p>
            </div>
        </div>
    );
};

const styles = {
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.85)', // Semi-transparent dark background
        zIndex: 11, // High z-index to ensure it covers all content
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        color: '#ffffff',
        textAlign: 'center',
        padding: '20px',
    },
    messageContainer: {
        marginTop: '20%',
        maxWidth: '80%',
    },
    message: {
        fontSize: '1.5rem',
    },
    icon: {
        width: '50px',
        marginBottom: '20px',
    },
};

export default OrientationOverlay;

