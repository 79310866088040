import React, { useEffect, useState } from 'react';

const AssetLoader = ({ progress }) => {
  // Clamp progress between 0 and 100
  const clampedProgress = Math.min(Math.max(progress, 0), 100);

  // Calculate dynamic color opacity (from 0.3 to 1)
  const colorOpacity = 0.05 + (clampedProgress / 100) * 0.9; // Adjusted to 0.3 to 1

  // Calculate dynamic blur (from 1.5px to 0px)
  const blurValue = 1.5 * (1 - clampedProgress / 100); // 1.5px to 0px

  // Calculate dynamic font size (from 1rem to 2rem, adjust as needed)
  const fontSize = 1 + (clampedProgress / 100) * 4; // 1rem to 2rem

  // Calculate dynamic text color (from semi-transparent gold to opaque gold)
  const textColor = `rgba(255, 215, 0, ${colorOpacity})`; // Gold color with variable opacity

  // Number of prefalebannertext spans and stars
  const prefaleRepeats = 13;
  const starCount = 29;

  // Generate prefalebannertext spans (if needed)
  const prefaleTexts = Array.from({ length: prefaleRepeats }, (_, index) => (
    <span key={index} className="prefale-text">
      ORDER OF FER
    </span>
  ));

  // Generate star divs
  const stars = Array.from({ length: starCount }, (_, index) => (
    <div key={index} className="star"></div>
  ));

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: `rgb(${Math.round((clampedProgress / 120) * 255)}, ${Math.round(
          (clampedProgress / 120) * 255
        )}, ${Math.round((clampedProgress / 120) * 255)})`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#ffffff',
        transition: 'background-color 0.3s ease',
      }}
    >
      <div
        className="titlestatic loader"
        style={{
          position: 'relative',
          backgroundColor: 'black',
          boxShadow: '1vw 5vw 6vw 6vw #000',
          textAlign: 'center',
          width: '100%',
          lineHeight: '2',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '1rem 0', // Adjust padding as needed
        }}
      >
        <span
          className="titletext small prefaletext"
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: textColor,
            filter: `blur(${blurValue}px)`,
            fontSize: `${fontSize}rem`,
            transition: 'color 0.3s ease, filter 0.3s ease, font-size 0.3s ease',
          }}
        >
          ORDER OF FER
          {/* If you have additional elements inside, adjust accordingly */}
          <div
            style={{
              opacity: 0.3,
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          >
            {/* Additional content if needed */}
          </div>
        </span>
        {stars}
      </div>

      <div
        style={{
          position: 'relative',
          marginTop: '10vh',
          width: '50vh', // Ensures the SVG scales based on viewport height
          height: '50vh',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 1000"
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {/* Base white logo */}
          <path
            fill="#ffffff"
            d="M581.15 809.5 L581.15 966.4 670.6 966.4 670.6 809.5 790.35 809.5 790.35 980.15 878.45 980.15 878.45 700.75 392.55 700.75 392.55 809.5 581.15 809.5 M378.55 550.3 Q359.85 567.15 359.85 591 359.85 614.85 378.55 631.6 397.35 648.45 423.85 648.45 450.35 648.45 469.05 631.6 487.85 614.8 487.85 591 487.85 567.15 469.05 550.3 450.3 533.5 423.85 533.5 397.35 533.5 378.55 550.3 M676.75 591 Q676.75 567.15 658 550.3 639.2 533.5 612.8 533.5 586.35 533.5 567.5 550.3 548.75 567.15 548.75 591 548.75 614.85 567.5 631.6 586.3 648.45 612.8 648.45 639.25 648.45 658 631.6 676.75 614.8 676.75 591 M950.05 503.9 L950.05 415.8 771.1 415.8 771.1 18 228.75 18 228.75 415.8 49.8 415.8 49.8 503.9 950.05 503.9 Z"
          />
          {/* Mask for progress */}
          <mask id="progress-mask">
            <rect
              x="0"
              y={1000 * (1 - clampedProgress / 100)}
              width="1000"
              height={1000 * (clampedProgress / 100)}
              fill="white"
              style={{
                transition: 'y 0.5s ease, height 0.5s ease', // Add transition for smooth animation
              }}
            />
          </mask>
          {/* Progress-filled logo */}
          <path
            fill="#000000"
            d="M581.15 809.5 L581.15 966.4 670.6 966.4 670.6 809.5 790.35 809.5 790.35 980.15 878.45 980.15 878.45 700.75 392.55 700.75 392.55 809.5 581.15 809.5 M378.55 550.3 Q359.85 567.15 359.85 591 359.85 614.85 378.55 631.6 397.35 648.45 423.85 648.45 450.35 648.45 469.05 631.6 487.85 614.8 487.85 591 487.85 567.15 469.05 550.3 450.3 533.5 423.85 533.5 397.35 533.5 378.55 550.3 M676.75 591 Q676.75 567.15 658 550.3 639.2 533.5 612.8 533.5 586.35 533.5 567.5 550.3 548.75 567.15 548.75 591 548.75 614.85 567.5 631.6 586.3 648.45 612.8 648.45 639.25 648.45 658 631.6 676.75 614.8 676.75 591 M950.05 503.9 L950.05 415.8 771.1 415.8 771.1 18 228.75 18 228.75 415.8 49.8 415.8 49.8 503.9 950.05 503.9 Z"
            mask="url(#progress-mask)"
          />
          {/* Percentage Text */}
          <text
            x="500" // Center horizontally based on viewBox (1000 width)
            y="250" // Adjust Y position to place it in the "hat"
            textAnchor="middle"
            dominantBaseline="middle"
            fill={`rgb(${Math.round((clampedProgress / 120) * 255)}, ${Math.round(
              (clampedProgress / 120) * 255
            )}, ${Math.round((clampedProgress / 120) * 255)})`}
            fontFamily="Arial, sans-serif"
            fontWeight="bold"
          >
            <tspan fontSize="180">
              {Math.round(clampedProgress)}
            </tspan>
            <tspan fontSize="80">%</tspan>
          </text>
        </svg>
      </div>
    </div>
  );
};

export default AssetLoader;
