// WalletContext.js

import React, { createContext, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { ethers } from 'ethers';
import web3Modal from './web3modal'; // Ensure this is correctly imported
import { SiweMessage } from 'siwe';
import config from './config';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';
import { decodeToken, setAuthToken, getAuthToken, removeAuthToken, clearAllAuthData } from './authUtils'
import { DataContext } from './DataProvider';

import MemeNFTABI from './abis/MemeNFT.json';
import { initializeContracts } from './contractHelper';
import { svgToPng } from './utils/imageUtils'; // Assuming you have this utility
import { isMobile } from 'web3modal';

// Create a WalletContext for global state management
export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  // ------------------- State Variables -------------------
  const [userAddress, setUserAddress] = useState('');
  const [signer, setSigner] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [userTier, setUserTier] = useState('');
  const [connectionError, setConnectionError] = useState(null);
  const [contracts, setContracts] = useState({});
  const [contractsReady, setContractsReady] = useState(false);
  const [isCorrectChain, setIsCorrectChain] = useState(true);

  // Ref to track authentication attempts
  const authAttemptedRef = useRef(false);

  // ------------------- Web3Modal Hooks -------------------
  const { walletProvider } = useWeb3ModalProvider();
  const { address: modalAddress, isConnected, chainId } = useWeb3ModalAccount();
  
  

  // ------------------- Utility Functions -------------------

  const isMobileDevice = () => {
    if (typeof navigator === 'undefined') return false;
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

  // Function to reset background (if needed)
  const resetBackground = useCallback(() => {
    const navigation = document.querySelector('.navigafon');
    if (navigation) {
      navigation.querySelectorAll('.navigafon-background').forEach(bg => bg.remove());
      navigation.querySelectorAll('.useraddress').forEach(bg => {
        bg.className = 'useraddress textmedium';
      });
      navigation.className = 'navigafon not-a';
    }
  }, []);

  // Error handling function
  const handleError = useCallback((error, context) => {
    console.error(`Error in ${context}:`, error);
    setConnectionError(`${context}: ${error.message}`);
  }, []);

  // ------------------- Authentication Functions -------------------

  const connect = useCallback(async () => {
    try {
      await web3Modal.open();
    } catch (error) {
      handleError(error, 'connect');
    }
  }, [web3Modal, handleError]);

  const logout = useCallback(async () => {
    clearAllAuthData();
    setIsAuthenticated(false);
    setUserAddress('');
    setSigner(null);
    setAuthError(null);
    setUserTier('');
    setContracts({});
    setContractsReady(false);
    setIsCorrectChain(false);
    resetBackground();
    authAttemptedRef.current = false; // Reset authentication attempt
    removeAuthToken();
  
    try {
      await web3Modal.disconnect();
    } catch (error) {
      console.error("Error during disconnect:", error);
    }
    localStorage.removeItem('isCurtainAnimated', 'false');
  }, [web3Modal, resetBackground]);
  

  /**
   * Authenticate the user using the provided signer.
   * @param {ethers.Signer} ethersSigner - The signer obtained from ethers.js.
   * @returns {boolean} - Returns true if authentication is successful, false otherwise.
   */

  

  // ------------------- Helper Functions -------------------

  /**
   * Fetch the authentication challenge from the server.
   * @param {string} address - The user's Ethereum address.
   * @returns {object} - An object containing the message and challengeData.
   */
  const getChallengeFromServer = useCallback(async (address) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/public/get_challenge.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ address }),
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to get challenge: ${errorText}`);
      }
      const data = await response.json();
      return { message: data.message, challengeData: data.challengeData };
    } catch (error) {
      console.error('Error getting challenge:', error);
      throw error;
    }
  }, []);



  

  /**
   * Verify the signature with the server.
   * @param {string} address - The user's Ethereum address.
   * @param {string} signature - The signature generated from signing the SIWE message.
   * @param {string} siweMessage - The SIWE message that was signed.
   * @param {string} challengeData - The challenge nonce data.
   * @returns {object} - The server's verification response.
   */
  const verifySignatureWithServer = useCallback(async (address, signature, siweMessage, challengeData) => {
    try {

      const response = await fetch(`${process.env.REACT_APP_API_URL}/public/verify_signature.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          address, 
          signature, 
          challengeData,
          message: siweMessage, // Include the SIWE message
          abi: JSON.stringify(MemeNFTABI.abi)
        }),
      });
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Server response:", errorText);
        throw new Error(`Failed to verify signature: ${errorText}`);
      }
      const data = await response.json();
      

      if (data.isValid && data.token) {
        // Store the new token format
        setAuthToken(data.token, address);
        
        // Decode and log the token to ensure correctness
        const decoded = decodeToken(data.token);

      }

      return data;
    } catch (error) {
      console.error('Error verifying signature:', error);
      throw error;
    }
  }, []);

  const authenticateUser = useCallback(async (ethersSigner) => {
    if (isAuthenticating || authAttemptedRef.current) {
   
      return false;
    }
  
    setIsAuthenticating(true);
    authAttemptedRef.current = true; // Prevent further attempts
  
    try {
      if (!ethersSigner) {
        throw new Error("No signer available");
      }
  
      const address = await ethersSigner.getAddress();
   
  
      if (!address || typeof address !== 'string' || !ethers.isAddress(address)) {
        throw new Error("Invalid Ethereum address");
      }
  
      // Fetch the challenge from the server
      const { message, challengeData } = await getChallengeFromServer(address);
     
  
      // Create a SiweMessage instance
      const siweMessage = new SiweMessage({
        domain: window.location.host,
        address: address,
        statement: "Sign in to the application to prove you own this address.",
        uri: window.location.origin,
        version: "1",
        chainId: config.chain,
        nonce: atob(challengeData), // Decode the base64 nonce
        issuedAt: new Date().toISOString(),
      });
  
      // Sign the SIWE message
      const signature = await ethersSigner.signMessage(siweMessage.prepareMessage());
     
  
      // Send the signed message and signature to the server for verification
      const verificationResult = await verifySignatureWithServer(
        address,
        signature,
        siweMessage.prepareMessage(),
        challengeData
      );
  
      if (verificationResult.isValid) {
        setIsAuthenticated(true);
        setUserAddress(address);
        setAuthError(null);
        setAuthToken(verificationResult.token, address);
       
  
        const decodedToken = decodeToken(verificationResult.token);
        if (decodedToken) {
      
          setUserTier(decodedToken.userTier.toString());
        }
  
        return true;
      } else {
     
        throw new Error("Server signature verification failed");
      }
    } catch (error) {
      console.error("Error during authentication:", error);
      setIsAuthenticated(false);
      setAuthError(error.message || "An error occurred during authentication.");
      await logout(); // Reset states
      authAttemptedRef.current = false; // Allow retry
      return false;
    } finally {
      setIsAuthenticating(false);
    }
  }, [isAuthenticating, logout, getChallengeFromServer, verifySignatureWithServer, logout]);
  
  /**
   * Decode the authentication token.
   * @param {string} token - The base64-encoded authentication token.
   * @returns {object|null} - The decoded token data or null if decoding fails.
   */

  // ------------------- New Authentication Check Function -------------------

  /**
   * Check if the user is authenticated by validating the stored token.
   * This should be called every time the user enters the site.
   */
  const checkAuthentication = useCallback(async () => {
    const storedTokenObj = getAuthToken();

  
    if (storedTokenObj) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/public/check_session.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            address: storedTokenObj.address,
            token: storedTokenObj.value
          }),
        });
  
        const data = await response.json();
    
  
        if (data.isValid) {
          const decodedToken = decodeToken(storedTokenObj.value);
         
          if (decodedToken) {
            setIsAuthenticated(true);
            setUserAddress(decodedToken.address);
            setUserTier(decodedToken.userTier.toString());
            return true;
          }
        } else {
         
          // Remove the invalid token
          removeAuthToken();
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      }
    } else {

    }
  
    setIsAuthenticated(false);
    setUserAddress('');
    setUserTier('NOT A');
    return false;
  }, []);
  
  

  // ------------------- Provider Setup -------------------

  /**
   * Set up the ethers provider, signer, initialize contracts, and authenticate the user.
   */
  const setupProvider = useCallback(async () => {
    try {
  
  
      if (!isConnected || !walletProvider) {

        await logout(); // Ensure all states are reset
        return;
      }
  
      const ethersProvider = new ethers.BrowserProvider(walletProvider);
      const network = await ethersProvider.getNetwork();
      const currentChainId = BigInt(network.chainId);
  

  
      if (currentChainId !== BigInt(config.chain)) {

        setIsAuthenticated(false);
        setUserTier('NOT A');
        setAuthError("Incorrect network");
        setIsCorrectChain(false);
        setContractsReady(false);
        return;
      }
  
      setIsCorrectChain(true);
  
      const ethersSigner = await ethersProvider.getSigner();
      const currentAddress = await ethersSigner.getAddress();
  
      if (!ethersSigner) {
        throw new Error("Failed to obtain signer from provider");
      }
  
     
  
      setSigner(ethersSigner);
      setUserAddress(currentAddress);
      setContractsReady(false);
  
      // Initialize contracts
      const initializedContracts = await initializeContracts(ethersSigner);
      const contractsObject = initializedContracts.reduce((acc, contract) => {
        acc[contract.name] = contract.contract;
        return acc;
      }, {});
  
      setContracts(contractsObject);
      setContractsReady(true);
      setAuthError(null);
  
      // First, check for existing auth token
      const isAuthValid = await checkAuthentication();
  
      if (!isAuthValid && !authAttemptedRef.current) {
        // Authenticate user if not already authenticated
        await authenticateUser(ethersSigner);
      }
  
    } catch (error) {
      handleError(error, 'setupProvider');
      setIsAuthenticated(false);
      setIsCorrectChain(false);
      setContractsReady(false);
    }
  }, [isConnected, walletProvider, config.chain, authenticateUser, handleError, logout, checkAuthentication]);
  
  

  // ------------------- Event Handlers -------------------

  /**
   * Handle chain changes by reinitializing the provider and authentication.
   * @param {string|number} newChainId - The new chain ID.
   */
  const handleChainChange = useCallback(async (newChainId) => {

    const numericChainId = Number(newChainId);


    if (numericChainId === config.chain) {

      await setupProvider();
    } else {
 
      setIsAuthenticated(false);
      setUserTier('NOT A');
      setAuthError("Incorrect network");
      setIsCorrectChain(false);
      setContractsReady(false);
      setSigner(null);
    }
  }, [setupProvider, config.chain]);

  /**
   * Handle wallet disconnection by logging out the user.
   */
  const handleDisconnect = useCallback(async () => {
 
    await logout();
  }, [logout]);

  /**
   * Handle modal-specific events.
   * @param {object} data - The event data.
   */
  const handleModalEvents = useCallback(({ data }) => {
    try {
  
      // Handle modal-specific events if necessary
      // Example: If you have specific events, handle them here
    } catch (error) {
      handleError(error, 'handleModalEvents');
    }
  }, [handleError]);

  /**
   * Handle provider changes by logging out if disconnected.
   * @param {object} param0 - The provider change event data.
   */
  const handleProviderChange = useCallback(({ provider, providerType, address, error, chainId, isConnected }) => {
    try {
      
      if (!isConnected) {
        
        logout();
      }
    } catch (error) {
      handleError(error, 'handleProviderChange');
    }
  }, [logout, handleError]);

  // ------------------- useEffect Hooks -------------------

  /**
   * Set up event listeners for the wallet provider.
   */
  useEffect(() => {
    if (walletProvider) {
      // Subscribe to modal events
      const unsubscribeModal = web3Modal.subscribeEvents(handleModalEvents);


      // Subscribe to provider changes
      const unsubscribeProvider = web3Modal.subscribeProvider(handleProviderChange);
      

      // Listen to chain changes
      walletProvider.on('chainChanged', handleChainChange);
      
      // Listen to disconnect event instead of 'close'
      walletProvider.on('disconnect', handleDisconnect);

      return () => {
        try {
          
          if (unsubscribeModal) {
           
            unsubscribeModal();
          }
          if (unsubscribeProvider) {
         
            unsubscribeProvider();
          }
          if (walletProvider) {
            walletProvider.removeListener('chainChanged', handleChainChange);
            walletProvider.removeListener('disconnect', handleDisconnect);
          }
        } catch (error) {
          handleError(error, 'Cleanup function');
        }
      };
    }
  }, [walletProvider, handleChainChange, handleDisconnect, handleModalEvents, handleProviderChange]);

  /**
   * Initialize the provider and authentication on component mount.
   */
  useEffect(() => {
    let isMounted = true;
  
    const initialize = async () => {
      try {

        await setupProvider();
      } catch (error) {
        handleError(error, 'initialize');
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
  
    if (isConnected && walletProvider) {
      initialize();
    } else {
      setIsLoading(false);
    }
  
    return () => {
      isMounted = false;
    };
  }, [isConnected, walletProvider]); // Remove setupProvider and handleError from dependencies
  

  // ------------------- Memoize Context Value -------------------
  const contextValue = useMemo(() => ({
    isConnected,
    signer,
    userAddress,
    isAuthenticated,
    authenticateUser,
    logout,
    connect,
    userTier,
    authError,
    isLoading,
    contracts, // Share contracts with other components if needed
    contractsReady,
    connectionError,
    isCorrectChain,
    isMobileDevice,
    // ...contracts // Spread contracts if you need individual contract properties
    // Add other states and functions as needed
  }), [
    isConnected,
    signer,
    userAddress,
    isAuthenticated,
    authenticateUser,
    logout,
    connect,
    userTier,
    authError,
    isLoading,
    contracts,
    contractsReady,
    connectionError,
    isCorrectChain,
    isMobileDevice,
  ]);

  // ------------------- Return the Context Provider -------------------
  return (
    <WalletContext.Provider value={contextValue}>
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
