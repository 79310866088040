import React, { useState, useEffect, useCallback, useMemo } from 'react';

const ImageViewer = React.memo(({ images, initialIndex, onClose }) => {
  const [state, setState] = useState({
    currentIndex: initialIndex,
    nextIndex: initialIndex,
    isChanging: false,
    isVisible: false,
  });

  useEffect(() => {
    setState(prev => ({ ...prev, isVisible: true }));
  }, []);

  const changeImage = useCallback((direction) => {
    if (state.isChanging) return;

    const nextIndex = (state.currentIndex + direction + images.length) % images.length;
    
    setState(prev => ({
      ...prev,
      isChanging: true,
      nextIndex: nextIndex,
    }));

    setTimeout(() => {
      setState(prev => ({
        ...prev,
        currentIndex: nextIndex,
        isChanging: false,
      }));
    }, 300);
  }, [state.isChanging, state.currentIndex, images.length]);

  const handleClose = useCallback((e) => {
    e.stopPropagation();
    setState(prev => ({ ...prev, isVisible: false }));
    setTimeout(onClose, 300);
  }, [onClose]);

  const handleNext = useCallback((e) => {
    e.stopPropagation();
    changeImage(-1);
  }, [changeImage]);

  const handlePrevious = useCallback((e) => {
    e.stopPropagation();
    changeImage(1);
  }, [changeImage]);

  // Define separate components for each message type (optional)
  const MessageIndex0 = () => (
    <>
          <span className='textsmall'>*only x1 FREE FERFona PER TIER. MORE Memberfipf will NOT give your more FREE FERFonaf. Addifional FERFonaf COFT $FER </span>
      <span className="textmedium goldtext darkbg">+100 FER FCORE, x1 FREE GOLD FERFONA*, FULL MORFER ACFEF</span>

      <span className='textmediumsmall'>Lift and fell Filver or Bronf FERFonaf!<br /> You will earn 50% of your fale, 
        while 50% if diftributed to you and other Gold memberf bafed on Fer Fcore.<br />Are you creative, active and can add value to the Order of Fer? Thif memberfip if for you!
        </span>

  
  
      <span className="textlarge goldtext darkbg">GOLD MEMBERFIP</span>
    </>
  );

  const MessageIndex1 = () => (
    <>
          <span className='textsmall'>*only x1 FREE FERFona PER TIER. MORE Memberfipf will NOT give your more FREE FERFonaf. Addifional FERFonaf COFT $FER </span>
    <span className="textmedium filvertext darkbg">+10 FER FCORE, x1 FREE FILVER FERFONA*, EKFTENDED MORFER ACFEF</span>


    <span className="textlarge filvertext darkbg">FILVER MEMBERFIP</span>
  </>
  );

  const MessageIndex2 = () => (
    <>
          <span className='textsmall'>*only x1 FREE FERFona PER TIER. MORE Memberfipf will NOT give your more FREE FERFonaf. Addifional FERFonaf COFT $FER </span>
    <span className="textmedium bronftext">+1 FER FCORE, x1 FREE BRONF FERFONA*, LIMITED MORFER ACFEF</span>


    <span className="textlarge bronftext">BRONF MEMBERFIP</span>
  </>
  );

  const messageLines = useMemo(() => {
    const lines = {
      0: <MessageIndex0 />,
      1: <MessageIndex1 />,
      2: <MessageIndex2 />,
    };
    return lines[state.currentIndex] || <></>;
  }, [state.currentIndex]);

  return (
    <div className={`image-viewer-overlay ${state.isVisible ? 'active' : ''}`}>
      <div className="image-viewer-content">
        <div className={'tokenomifchat left bounce'}>
          {messageLines}
        </div>
        <div className="image-container">
          <img
            src={images[state.currentIndex]}
            alt={`Tier ${state.currentIndex + 1}`}
            className={`viewer-image ${state.isChanging ? 'fade-out' : ''}`}
          />
          <img
            src={images[state.nextIndex]}
            alt={`Tier ${state.nextIndex + 1}`}
            className={`viewer-image overlay ${state.isChanging ? 'fade-in' : ''}`}
          />
        </div>
        <button className="butn plus imgbutton prev" onClick={handlePrevious}>{"<--"}</button>
        <button className="butn plus imgbutton next" onClick={handleNext}>{"-->"}</button>
        <button className="butn red close-button" onClick={handleClose}>Clofe</button>
      </div>
    </div>
  );
});

export default ImageViewer;
