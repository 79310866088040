import React, { useState, useEffect } from 'react';
import './styles/main.css';
import { WalletProvider } from './WalletContext';
import Content from './components/Content';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ImageProvider } from './components/ImageContext';
import Banner from './components/PrefaleBanner';
import AssetLoader from './components/AssetLoader';
import DataProvider from './DataProvider';

function importAll(r) {
  return r.keys().map(r);
}

function App() {
  const [assetsLoaded, setAssetsLoaded] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const loadAssets = async () => {
      const imageContext = require.context('./', true, /\.(png|jpe?g|svg|gif)$/);
      const imagePaths = imageContext.keys();
      const totalAssets = imagePaths.length;
      let loadedAssets = 0;

      const loadImage = (src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => {
            loadedAssets++;
            setProgress((loadedAssets / totalAssets) * 100);
            resolve();
          };
          img.onerror = reject;
          img.src = imageContext(src);
        });
      };

      try {
        await Promise.all(imagePaths.map(loadImage));
        setAssetsLoaded(true);
      } catch (error) {
        console.error("Failed to load all assets:", error);
        // Optionally set assetsLoaded to true even if some assets failed
        setAssetsLoaded(true);
      }
    };

    loadAssets();
  }, []);

  if (!assetsLoaded) {
    return <AssetLoader progress={progress} />;
  }

  return (
    <ImageProvider>
      <WalletProvider>
        <DataProvider>
        <div className="App">
         
          <Content />
        </div>
        </DataProvider>
      </WalletProvider>
    </ImageProvider>
  );
}

export default App;