import React, { useEffect, useRef, useState } from 'react';
import applyLiquifyEffect from './liquifyEffect';

const CanvasLiquify = ({ src, canvasRef, imageRef, dimensions, brushSize, smoothing, activeLayer }) => {
    
    const isInitialized = useRef(false);
    const brushSizeRef = useRef(brushSize);
    const smoothingRef = useRef(smoothing);

    useEffect(() => {
        brushSizeRef.current = brushSize;
    }, [brushSize]);

    useEffect(() => {
        smoothingRef.current = smoothing;
    }, [smoothing]);

    useEffect(() => {
        if (!isInitialized.current && src) {
            const canvas = canvasRef.current;
            const image = imageRef.current;

            if (!canvas || !image) {
                console.error("Canvas or image reference is not initialized");
                return;
            }

            const resetCanvas = () => {
                canvas.width = dimensions.width;
                canvas.height = dimensions.height;
                
                const c = canvas.getContext('2d');
                c.drawImage(image, 0, 0, canvas.width, canvas.height);
            };

            const img = new Image();
            img.src = src;
            img.onload = () => {
                if (image) {
                    image.width = img.width;
                    image.height = img.height;
                    resetCanvas();
                    applyLiquifyEffect(canvas, brushSizeRef, smoothingRef, activeLayer);
                }
            };

            img.onerror = () => {
                console.error("Image failed to load:", src);
            };

            isInitialized.current = true;
        }
    }, [src, canvasRef, imageRef, dimensions, activeLayer]);
    
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
        if (canvasRef.current) {
            const rect = canvasRef.current.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            setCursorPosition({ x, y });
        }
    };

    return (
        <div style={{ position: 'relative', width: dimensions.width, height: dimensions.height }}>
            <canvas
                ref={canvasRef}
                onMouseMove={handleMouseMove}
                style={{
                    display: activeLayer === 'liquify' ? 'block' : 'none',
                    width: '100%',
                    height: '100%',
                }}
            />
            {activeLayer === 'liquify' && (
                <div
                    className="custom-cursor"
                    style={{
                        width: `${brushSize}px`,
                        height: `${brushSize}px`,
                        borderRadius: '50%',
                        border: '2px solid green',
                        position: 'absolute',
                        pointerEvents: 'none',
                        left: `${cursorPosition.x}px`,
                        top: `${cursorPosition.y}px`,
                        transform: 'translate(-50%, -50%)',
                        zIndex: 15,
                    }}
                />
            )}
        </div>
    );
};

export default CanvasLiquify;
