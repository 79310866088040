import React, { useState, useContext } from 'react';
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import config from '../config';
import { WalletContext} from '../WalletContext'; // Import WalletContext


const WithdrawETH = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const {isAuthenticated, userAddress, presaleManagerContract} = useContext(WalletContext); // Use both isAuthenticated and userAddress from WalletContext
  const isAdmin = userAddress === config.adminAddress; // Check if the user is the admin

  const connectWalletAndWithdrawETH = async () => {
    if (!isAuthenticated || !isAdmin) {
      alert('Please connect your wallet and ensure you are the admin.');
      return;
    }

    setIsProcessing(true);
    try {


      await presaleManagerContract.withdrawETH();
      alert('ETH withdrawn successfully.');
    } catch (error) {
      console.error('Error withdrawing ETH:', error);
      alert('Failed to withdraw ETH. Ensure you are the admin.');
      alert(error);
    } finally {
      setIsProcessing(false);
    }
  };

  // Only render the button if the user is connected and is the admin
  if (!isAuthenticated || !isAdmin) return null;

  return (
    <div className='row'>
        <div className="col-sm signin">
    <button className='btn btn-primary' onClick={connectWalletAndWithdrawETH} disabled={isProcessing}>
      {isProcessing ? 'Processing...' : 'Withdraw ETH'}
    </button>
    </div>
    </div>
  );
};

export default WithdrawETH;
