// Navigation.js
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { WalletContext } from '../WalletContext';
import config from '../config';
import MiniDashboard from './MiniDashboard';
import FolioContainer from './FolioContainer'; // Import the new component
import Alerts from './Alerts';
import { DataContext } from '../DataProvider';


const Navigation = ({
  activeButton,
  handleSectionChange,
  isMobileMenu,
  onFolioTabChange, // New prop for folio tab changes
}) => {
  const {
    isAuthenticated,
    logout,
    userAddress,
    isConnected,
    connect,
    isMobileDevice,
  } = useContext(WalletContext);


  const {
  
    userTier
  } = useContext(DataContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isAdmin = userAddress === config.adminAddress;
  const [isFolio, setIsFolio] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  // New state for active folio tab
  const [activeFolioTab, setActiveFolioTab] = useState('memberfip');

  // New state for delayed rendering
  const [showFolioContainer, setShowFolioContainer] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);


  const toggleFolio = () => {

    setIsFolio(!isFolio);
    setIsExpanded(false);
    const navigafonBackground = document.querySelector('.navigafon-background');
    const titleHeader = document.querySelector('.titleheader');
    const minidashboard = document.querySelector('.minidashboard');
    const titletextLarge = document.querySelector('.titletext.large');
    const titletextSmall = document.querySelector('.titletext.small');
    
    
    if (navigafonBackground) {
      navigafonBackground.classList.toggle('maximized');
    }
    if (titleHeader) {
      titleHeader.classList.toggle('maximized');
    }

    if (minidashboard) {
      minidashboard.classList.toggle('maximized');
    }
    if (titletextLarge) {
      titletextLarge.classList.toggle('maximized');
    }
    if (titletextSmall) {
      titletextSmall.classList.toggle('maximized');
    }
  };


  useEffect(() => {
    setIsAnimating(true);
    if (isFolio) {
      const timer = setTimeout(() => {
        setShowFolioContainer(true);
        setIsAnimating(false);
      }, 600); // 0.5 second delay
      return () => clearTimeout(timer);
    } else {
      setShowFolioContainer(false);
    }
  }, [isFolio]);



  const handleCloseDashboard = () => {
    setIsFolio(false);
  };
  

  const handleMenuClick = (section) => {
    handleSectionChange(section);
    setIsMenuOpen(false);
  };

  const handleFolioTabClick = (tab) => {
    setActiveFolioTab(tab);
    if (onFolioTabChange) {
      onFolioTabChange(tab); // Notify parent component if needed
    }
    setIsMenuOpen(false);
  };

  const handleOutsideClick = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  const connectWallet = async () => {
    await connect();
  };

  const disconnectWallet = async () => {
    logout();
  };

  // Define folio tabs with display names
  const folioTabs = [
    { key: 'memberfip', label: 'Memberfip' },
    { key: 'ferfona', label: 'FERFonaf' },
  ];

  return (
    <>
      <div className={`navigafon ${isFolio ? 'folio' : ''}`}>
        <div className="navbuttons">
          <MiniDashboard
            handleMenuClick={handleMenuClick}
            disconnectWallet={disconnectWallet}
            connectWallet={connectWallet}
            isAuthenticated={isAuthenticated}
            userAddress={userAddress}
            isFolio={isFolio}
            setIsFolio={setIsFolio}
            toggleFolio={toggleFolio}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            handleSectionChange={handleSectionChange} 
          />

          {(isFolio && isAnimating) || (!isFolio) ? (
            // Render existing buttons when not in folio mode
            <>
              <button
                className={`navbutn navprefale ${activeButton === 'prefale' ? 'active' : ''}`}
                disabled={!isAuthenticated}
                onClick={() => handleMenuClick('prefale')}
              >
                <span className='darkbglarge'>Prefale</span>
               
              </button>
              <button
                className={`navbutn tokenomif ${activeButton === 'tokenomif' ? 'active' : ''}`}
                disabled={!isAuthenticated}
                onClick={() => handleMenuClick('tokenomif')}
              >
                <span className='darkbglarge'>Tokenomif</span>
              </button>
              <button
                className={`navbutn ferfona ${activeButton === 'ferfona' ? 'active' : ''}`}
                disabled={!isAuthenticated}
                onClick={() => handleMenuClick('ferfona')}
              >
                <span className='darkbglarge'>FERFona</span>
              </button>
      
              <button
                className={`navbutn fergiving ${activeButton === 'fergiving' ? 'active' : ''}`}
                disabled={!isAuthenticated}
                onClick={() => handleMenuClick('fergiving')}
              >
                <span className='darkbglarge'>FERGiving</span>
              </button>

              <button
  className={`navbutn MorFER ${activeButton === 'MorFER' ? 'active' : ''}`}
  disabled={!isAuthenticated}
  onClick={() => handleMenuClick('MorFER')}
>
  <span className='darkbglarge'>
    {isMobileDevice() ? ( // Added parentheses to call the function
      'MorFER'
    ) : (
      <>
        Mor&nbsp;&nbsp;&#8201;&#8202;&#8202;
        <span style={{
          position:'absolute', 
          fontWeight:'800', 
          transform:'rotate(90deg) translate(70%, 42%)', 
          zIndex:'-1'
        }}>F</span>
        <span style={{
          position:'absolute', 
          fontWeight:'700', 
          transform:'translate(-160%, -26%) rotate(0deg)', 
          zIndex:'-1'
        }}>..</span>
        ER
      </>
    )}
  </span>
</button>

              {isAdmin && (
                <>
                  <button
                    className={`navbutn ${activeButton === 'admin' ? 'active' : ''}`}
                    onClick={() => handleMenuClick('admin')}
                  >
                    System Admin
                  </button>
                  <button
                    className={`navbutn ${activeButton === 'adminVoting' ? 'active' : ''}`}
                    onClick={() => handleMenuClick('adminVoting')}
                  >
                    Voting Admin
                  </button>
                </>
              )}
            </>
          ) : (
            // Render folio buttons when in folio mode
            <>
              {/* Individual Folio Buttons */}
              <button
                onClick={() => handleFolioTabClick('memberfip')}
                className={`navbutn memberfip  ${userTier.toLowerCase()} ${activeFolioTab === 'memberfip' ? 'active' : ''}`}
              >
                Memberfip
              </button>
              <button
                onClick={() => handleFolioTabClick('ferfona')}
                className={`navbutn ferfonaf ${activeFolioTab === 'ferfona' ? 'active' : ''}`}
              >
                FERFonaf
              </button>
              {/* End of Individual Folio Buttons */}

              {isFolio ? <Alerts
                setIsExpanded={setIsExpanded}
                handleSectionChange={handleSectionChange} 
                isFolio={isFolio}
                toggleFolio={toggleFolio}
              /> : ''}
            </>
          )}
        </div>
    
        <div className="navigafon-background"></div>
        {isFolio && showFolioContainer && !isAnimating && (
          <div className="folioholder">
            <FolioContainer
              activeTab={activeFolioTab}
              toggleFolio={toggleFolio}
              // Pass other necessary props here
            />
          </div>
        )}
      </div>

      {isMobileMenu && isMenuOpen && <div className="overlay" onClick={handleOutsideClick} />}
      {isFolio && !isAnimating && (
        <div className='darkener'
          
        ></div>
      )}
    </>
  );
};

Navigation.propTypes = {
  activeButton: PropTypes.string.isRequired,
  handleSectionChange: PropTypes.func.isRequired,
  isMobileMenu: PropTypes.bool.isRequired,
  onFolioTabChange: PropTypes.func, // New prop
};

export default Navigation;
