import React from 'react';
import './HeartStats.css';
import { formatSeasonTimeLeft } from '../../utils/formatUtils';
import PropTypes from 'prop-types';
import { color, fontString } from 'chart.js/helpers';

const Hourglass = ({
  timeLeft,
  timeLeftPercent,
  seasonStatus,
}) => {
  // Ensure percentages are within 0-100
  const safeTimeLeftPercent = Math.min(Math.max(timeLeftPercent, 0), 100);
  
  // Convert to fractions for calculations
  const timeLeftFraction = safeTimeLeftPercent / 100;
  
  // Calculate elapsed percentage (0 to 1)
  const elapsedPercent = (100 - safeTimeLeftPercent) / 100;
  
  // Maximum vertical movement for sandhill
  const sandhillMaxMove = 120;
  
  // Calculate positions:
  // - Sandhill starts at 464 and moves up by elapsedPercent * maxMove
  // - Bottom fill should sync exactly with sandhill movement
  const sandhillY = 464 - (elapsedPercent * sandhillMaxMove);
  
  // Use same elapsed percentage for bottom fill
  const timeElapsedFraction = elapsedPercent;

  const textStyle = {
    position: 'absolute',
    left: '50%',
    color:'#fff',
    transform: 'translateX(-50%)',
    bottom: '103%',
  };

  return (
    <div className="hourglass-container" style={{ position: 'relative' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        preserveAspectRatio="none"
        viewBox="0 0 400 600"
        aria-label="Time Left"
        className="hourglass-svg"
      >
        <defs>
          {/* Stream */}
          <g id="stream_0_Layer0_0_FILL">
            <path
              fill="#FFCC00"
              stroke="none"
              d="M 7.5 0 L 0 0 0 276.7 7.5 276.7 7.5 0 Z"
            />
          </g>

          {/* Time Left Sand */}
          <g id="timeleft_0_Layer0_0_FILL">
            <path
              fill="#FFCC00"
              stroke="none"
              d="
                M 255.8 137.6
                L 255.8 0 0 0 0 137.6 255.8 137.6
                M 255.8 140.5
                L 255.8 137.6 0 137.6 0 140.5 121.5 262 134.3 262 255.8 140.5 Z"
            />
          </g>

          {/* Time Elapsed Sand */}
          <g id="timeelapsed_0_Layer0_0_FILL">
            <path
              fill="#FFCC00"
              stroke="none"
              d="
                M 255.8 -124.6
                L 0 -124.6 0 14.85 255.8 14.85 255.8 -124.6 Z"
            />
          </g>

          {/* Sandhill */}
          <g id="sandhill_0_Layer0_0_FILL">
            <path
              fill="#FFCC00"
              stroke="none"
              d="
                M 255.8 124.4
                L 255.8 121.5 131.85 0 124.8 0 0 121.5 0 124.4 255.8 124.4 Z"
            />
          </g>

          {/* Frame */}
          <path
            id="frame_0_Layer0_0_1_STROKES"
            stroke="#CCCCCC"
            strokeWidth="5"
            strokeLinejoin="round"
            strokeLinecap="round"
            fill="rgba(255, 255, 255, 0.1)"
            d="
              M 0 406.9
              L 121.5 285.4 0 163.9 0 0 255.8 0
              255.8 163.9 134.3 285.4 255.8 406.9
              255.8 570.8 0 570.8 0 406.9 Z"
          />

          {/* Updated ClipPaths */}
          <clipPath id="timeLeftClip" clipPathUnits="userSpaceOnUse">
            <rect
              x="0"
              y={2.7 * (100 - safeTimeLeftPercent)}
              width="400"
              height="600"
            />
          </clipPath>
          <clipPath id="timeElapsedClip" clipPathUnits="userSpaceOnUse">
            <rect
              x="0"
              y={-1.3 * (100 - safeTimeLeftPercent)}
              width="400"
              height="600"
            />
          </clipPath>
        </defs>

        {/* Stream */}
        <g
          id="stream"
          transform="matrix(1, 0, 0, 1, 194.4, 300.6)"
          style={{ visibility: timeLeftFraction === 0 ? 'hidden' : 'visible' }}
        >
          <use xlinkHref="#stream_0_Layer0_0_FILL" />
        </g>

        {/* Time Left Sand */}
        <g
          id="timeleft"
          transform="matrix(1, 0, 0, 1, 70.35, 38.6)"
          clipPath="url(#timeLeftClip)"
        >
          <use xlinkHref="#timeleft_0_Layer0_0_FILL" />
        </g>

        {/* Time Elapsed Sand */}
        <g
          id="timeelapsed"
          transform="matrix(1, 0, 0, 1, 70.35, 571.7)"
          clipPath="url(#timeElapsedClip)"
        >
          <use xlinkHref="#timeelapsed_0_Layer0_0_FILL" />
        </g>

        {/* Sandhill */}
        <g
          id="sandhill"
          className="sandhill"
          transform={`matrix(1, 0, 0, 1, 70.35, ${sandhillY})`}
        >
          <use xlinkHref="#sandhill_0_Layer0_0_FILL" />
        </g>

        {/* Frame */}
        <g id="frame" 
        transform="matrix(1, 0, 0, 1, 70.35, 15.2)">
          <use xlinkHref="#frame_0_Layer0_0_1_STROKES" />
        </g>
      </svg>

      {/* Time Left Text */}
      <div className="hourglass-text" style={textStyle}>
        <div className="textmediumsmall goldtext">
          {safeTimeLeftPercent > 0 ?  formatSeasonTimeLeft(timeLeft, seasonStatus) : seasonStatus}
        </div>
      </div>
    </div>
  );
};

Hourglass.propTypes = {
  timeLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  timeLeftPercent: PropTypes.number.isRequired,
  seasonStatus: PropTypes.string.isRequired,
};

export default Hourglass;