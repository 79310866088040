import React, { useState, useContext } from 'react';

import config from '../config';

import { WalletContext } from '../WalletContext'; // Import WalletContext

const TransferOwnership = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const newOwnerAddress = config.contractAddress; // ERC20 contract address as the new owner
  const { isAuthenticated, userAddress, erc721ContractMint, contractAddressMint, contractABIMint,erc20Contract } = useContext(WalletContext); // Use both isAuthenticated and userAddress from WalletContext
  const isAdmin = userAddress === config.adminAddress; // Check if the user is the admin

  const transferOwnership = async () => {
    if (!isAuthenticated || !isAdmin) {
      alert('Please connect your wallet and ensure you are the admin.');
      return;
    }

    setIsProcessing(true);
    
    try {
    
      const transaction = await erc721ContractMint.transferOwnership(newOwnerAddress);
      await transaction.wait();

      alert('Ownership transferred successfully to the ERC20 contract.');
    } catch (error) {
      console.error('Error transferring ownership:', error);
      alert('Failed to transfer ownership. Ensure you are the admin and the current owner of the ERC721 contract.');
      alert(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  // Only render the button if the user is connected and is the admin
  if (!isAuthenticated) return null;

  return (
    <div>
      <button className='btn btn-primary' onClick={transferOwnership} disabled={isProcessing}>
        {isProcessing ? 'Processing...' : 'Transfer Ownership to ERC20'}
      </button>
    </div>
  );
};

export default TransferOwnership;
