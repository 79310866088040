import React, { useState, useContext } from 'react';
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import config from '../config';
import { WalletContext } from '../WalletContext'; // Import WalletContext



const EndPreSale = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const {isAuthenticated, userAddress, contracts} = useContext(WalletContext);// Use both isAuthenticated and userAddress from WalletContext
  const isAdmin = userAddress === config.adminAddress; // Check if the user is the admin

  const connectWalletAndEndPreSale = async () => {
    if (!isAuthenticated || !isAdmin) {
      alert('Please connect your wallet and ensure you are the admin.');
      return;
    }

    setIsProcessing(true);
    try {
 

      const transactionResponse = await contracts.presaleManagerContract.endPreSaleAndExecuteActions();
      const transactionReceipt = await transactionResponse.wait();
  
      alert('Pre-sale ended and actions executed successfully.');
    } catch (error) {
      console.error('Error ending pre-sale and executing actions:', error);

      alert(`Failed to end pre-sale and execute actions: ${error.message}`);
      if (error.data) {
          
      }
  }
   finally {
      setIsProcessing(false);
    }
  };

  // Only render the button if the user is connected and is the admin
  if (!isAuthenticated || !isAdmin) return null;

  return (
    <div className='row'>
        <div className="col-sm signin">
    <button className='btn btn-primary' onClick={connectWalletAndEndPreSale} disabled={isProcessing}>
      {isProcessing ? 'Processing...' : 'End Pre-Sale and Execute Actions'}
    </button>
    </div>
    </div>
  );
};

export default EndPreSale;
