import React from 'react';
import { formatAmount } from '../../utils/formatUtils';
import Hourglass from './Hourglass';
import { FERIcon } from '../../utils/formatUtils';

export const HeartStats = ({
  participationPercent,
  valuePerPoint,
  timeLeft,
  timeLeftPercent,
  seasonStatus
}) => {
  const safeParticipationPercent = Math.min(Math.max(participationPercent, 0), 100);
  const participationFraction = safeParticipationPercent / 100;

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%'
    },
    heartItem: {
      position: 'relative',
      width: '100%',
      aspectRatio: '1/1',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    heartSvg: {
      width: '100%',
      height: '100%',
      filter: 'drop-shadow(0 2px 2px rgba(0,0,0,0.15))'
    },
    absoluteHeart: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      filter: 'drop-shadow(0 2px 2px rgba(0,0,0,0.15))'
    },
    text: {
      position: 'absolute',
      top: '45%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      lineHeight: '1',
      color: '#fff',
      width: '100%'
    },
    pot: {
      position: 'absolute',
      bottom: '89%',
      left: '0%',
      textAlign: 'center',
      width: '100%'
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.heartItem}>
        <svg
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-label="Participation Rate and Value per Point Base"
          style={styles.heartSvg}
        >
          <defs>
            <linearGradient id="baseGradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style={{ stopColor: '#ffffff', stopOpacity: 0.7 }} />
              <stop offset="45%" style={{ stopColor: '#ffffff', stopOpacity: 0.5 }} />
              <stop offset="100%" style={{ stopColor: '#dddddd', stopOpacity: 0.5 }} />
            </linearGradient>
            <linearGradient id="baseEdgeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{ stopColor: '#999999', stopOpacity: 0.3 }} />
              <stop offset="50%" style={{ stopColor: '#ffffff', stopOpacity: 0 }} />
              <stop offset="100%" style={{ stopColor: '#999999', stopOpacity: 0.3 }} />
            </linearGradient>
            <linearGradient id="baseShineGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" style={{ stopColor: '#ffffff', stopOpacity: 0.85 }} />
              <stop offset="25%" style={{ stopColor: '#ffffff', stopOpacity: 0 }} />
              <stop offset="100%" style={{ stopColor: '#ffffff', stopOpacity: 0 }} />
            </linearGradient>
          </defs>
          <g>
            <path
              d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
              fill="url(#baseGradient)"
            />
            <path
              d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
              fill="url(#baseEdgeGradient)"
            />
            <path
              d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
              fill="url(#baseShineGradient)"
            />
          </g>
        </svg>

        <svg
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-label="Participation Rate and Value per Point"
          style={styles.absoluteHeart}
        >
          <defs>
            <clipPath id="participationClip" clipPathUnits="objectBoundingBox">
              <rect x="0" y={1 - participationFraction} width="1" height={participationFraction} />
            </clipPath>
            <linearGradient id="fillGradient" x1="0%" y1="0%" x2="0%" y2="100%">
  <stop offset="0%" style={{ stopColor: '#ff4444' }} />
  <stop offset="45%" style={{ stopColor: '#ee0000' }} />
  <stop offset="100%" style={{ stopColor: '#cc0000' }} />
</linearGradient>
<linearGradient id="fillEdgeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
  <stop offset="0%" style={{ stopColor: '#cc0000', stopOpacity: 0.5 }} />
  <stop offset="50%" style={{ stopColor: '#ee0000', stopOpacity: 0 }} />
  <stop offset="100%" style={{ stopColor: '#cc0000', stopOpacity: 0.5 }} />
</linearGradient>
            <linearGradient id="fillShineGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" style={{ stopColor: '#ffffff', stopOpacity: 0.85 }} />
              <stop offset="25%" style={{ stopColor: '#ffffff', stopOpacity: 0 }} />
              <stop offset="100%" style={{ stopColor: '#ffffff', stopOpacity: 0 }} />
            </linearGradient>
          </defs>
          <g clipPath="url(#participationClip)">
            <path
              d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
              fill="url(#fillGradient)"
            />
            <path
              d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
              fill="url(#fillEdgeGradient)"
            />
            <path
              d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
              fill="url(#fillShineGradient)"
            />
          </g>
        </svg>

        <div style={styles.text} className="textmedium">
          <div>{formatAmount(valuePerPoint)}<FERIcon style={{marginBottom:'2%', marginLeft:'2%'}} /></div>
          <div>{safeParticipationPercent.toFixed(1)}%</div>
        </div>
        
        <div style={styles.pot} className="textmediumsmall goldtext">
          Partifipafon multiplier
        </div>
      </div>
    </div>
  );
};

export default HeartStats;