import React, { useContext, useState, useEffect } from 'react';
import { WalletContext } from '../WalletContext';
import ClaimVestedTokens from './ClaimVestedTokens';
import ClaimVotingRewards from './ClaimVotingRewards';

const Dashboard = () => {
    const { isAuthenticated } = useContext(WalletContext);
 
    useEffect(() => {
        if (isAuthenticated) {
           
        }
    }, [isAuthenticated]);

    if (!isAuthenticated) return null;

    return (
      
            
           
                <div className='board dafboardholder'>
                    
                    <div className='leftpanel vested'>
                
                    <ClaimVestedTokens />
                
                </div>
                
                <div className='rightpanel votes'>
                
              
                    <ClaimVotingRewards />
                
                </div>
               
            </div>
       
    );
};


export default Dashboard;
