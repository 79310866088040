// authUtils.js

export const decodeToken = (token) => {
  try {
    const decoded = JSON.parse(atob(token));
    return decoded;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

export const getAuthTokenRaw = () => {
  const itemStr = localStorage.getItem('authToken');
  if (!itemStr) return null;
  try {
    const item = JSON.parse(itemStr);
    return item.value; // Return the token string without checking expiresAt
  } catch (error) {
    console.error('Error parsing authToken:', error);
    localStorage.removeItem('authToken');
    return null;
  }
};



export const getAuthToken = () => {
  const token = getAuthTokenRaw();
  if (!token) return null;
  return {
    value: token,
    ...decodeToken(token),
  };
};

export const setAuthToken = (token, address) => {
  const decoded = decodeToken(token);
  if (!decoded || !decoded.expiresAt) {
    console.error('Invalid token data. Cannot set authToken.');
    return;
  }
  const item = {
    value: token,
    address: address,
    expiresAt: decoded.expiresAt * 1000, // Convert to milliseconds
  };
  localStorage.setItem('authToken', JSON.stringify(item));
};

export const removeAuthToken = () => {
  localStorage.removeItem('authToken');
};

export const clearAllAuthData = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('isCurtainAnimated');
  // Add any other auth-related items that need to be cleared
};
