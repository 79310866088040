// CollectedNameCard.jsx

import React, { useRef, useEffect, useState } from 'react';
import tier1 from '../goldmember.png';
import tier2 from '../filvermember.png';
import tier3 from '../bronfmember.png';
import ferCoinLogo from '../fer_coin_logo.png';
import { formatAmount, formatAddress } from '../utils/formatUtils';

const tierIcons = {
  1: tier1,
  2: tier2,
  3: tier3,
};

const getTierName = (tier) => {
  switch (tier) {
    case 1:
      return 'Gold';
    case 2:
      return 'Filver';
    case 3:
      return 'Bronf';
    default:
      return 'Unknown';
  }
};

const CollectedNameCard = ({
  name,
  allowedPricesPerTier,
  onUpdatePrice,
  onDelist, // New prop for delisting
  isProcessing,
  isDelisting, // New prop to track delisting state
  setMainName,
}) => {
  const { tokenId, name: nameText, tier } = name;
  const priceInt = Number(name.priceInt) || 0; // Convert to number


  const plateRef = useRef(null);
  const [plateWidth, setPlateWidth] = useState(100);

  // State for current price and tracking changes
  const [currentPrice, setCurrentPrice] = useState(priceInt);
  const [priceChanged, setPriceChanged] = useState(false);

  // New state for hover
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (currentPrice === undefined || currentPrice === null) {
      setCurrentPrice(0);
    }
  }, [currentPrice]);

  useEffect(() => {
    if (plateRef.current) {
      const width = plateRef.current.scrollWidth;
      setPlateWidth(width + 25);
    }
  }, [nameText]);

  // Synchronize currentPrice with priceInt and reset priceChanged
  useEffect(() => {
    setCurrentPrice(priceInt);
    setPriceChanged(false);
  }, [priceInt]);

  // Function to get price tag color based on tier and price
  const getPriceTagColor = (price, tier) => {
    if (tier === 1) {
      return 'url(#gold-gradient)';
    }

    if (tier === 2) {
      if (price < 1) {
        return '#818181';
      }
      return price < 2500 ? '#E0E0E0' : '#A9A9A9';
    }

    if (tier === 3) {
      if (price < 1) {
        return '#818181';
      }
      return '#8B4513';
    }

    return '#c3c3c3';
  };

  const displayPrice =
    currentPrice === 0 ? 'x1 Free' : `${formatAmount(currentPrice)}`;
  const priceColor = getPriceTagColor(currentPrice, tier);

  return (
    <div className="name-card-wrapper sold">
      <div
        className={`name-card-container ${isProcessing ? 'processing' : ''}`}
        onClick={() => setMainName(name.tokenId)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* SVG and Name Plate */}
        <svg
          className="name-card-svg"
          xmlns="http://www.w3.org/2000/svg"
          height={120}
          width={plateWidth}
        >
          {tier === 1 && (
            <defs>
              <linearGradient id="gold-gradient" x1="0" y1="0" x2="1" y2="1">
                <stop offset="0%" stopColor="#FFD700" />
                <stop offset="100%" stopColor="#FFEC8B" />
              </linearGradient>
            </defs>
          )}
          <line
            x1={plateWidth / 2 - 85}
            y1={90}
            x2={plateWidth / 2}
            y2={33}
            stroke="#000"
            strokeWidth="2"
          />
          <line
            x1={plateWidth / 2 + 85}
            y1={90}
            x2={plateWidth / 2}
            y2={33}
            stroke="#000"
            strokeWidth="2"
          />
          <image
            href={tierIcons[tier]}
            x={plateWidth / 2 - 33}
            y={-6}
            width="66"
            height="66"
            alt={`${getTierName(tier)} Tier Icon`}
          />
          <text
            x={plateWidth / 2}
            y={50}
            textAnchor="middle"
            style={{ opacity: 0, fontSize: '2.5em' }}
            pointerEvents="none"
            fill="#870606"
          >
            ×
          </text>
        </svg>

        <div
          className={`name-plate ${getTierName(tier).toLowerCase()} ${
            isProcessing ? 'processing' : ''
          }`}
          ref={plateRef}
          style={{
            width: plateWidth,
            fontSize: nameText.length > 10 ? '0.8em' : '1em',
          }}
        >
          <span className={`name-plate-title  ${getTierName(tier).toLowerCase()}text ${getTierName(tier).toLowerCase()}`}>
            FER
          </span>
          <span
            className={`name-text textmedium ${getTierName(tier).toLowerCase()}text`}
            title={isHovered ? 'MAIN' : nameText}
          >
            {isHovered ? 'MAIN' : nameText}
          </span>
        </div>

       
      </div>

      {/* Action Buttons */}
      <div className="name-action-buttons">
        {priceChanged && (
          <button
            className={`butn updatename ${isProcessing ? 'disabled' : ''}`}
            disabled={isProcessing}
            title="Update Price"
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering the setMainName onClick
              onUpdatePrice(tokenId, currentPrice);
            }}
          >
            ⟳
          </button>
        )}
      </div>
    </div>
  );
};

export default CollectedNameCard;
