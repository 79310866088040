import React, { useState, useEffect, useRef } from 'react';

const NFTPreviewImage = ({ src, alt, className, onClick, ferfolio = false }) => {
    const [isHovering, setIsHovering] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasInteracted, setHasInteracted] = useState(false);
    const timeoutRef = useRef(null);
    const animationInProgressRef = useRef(false);

    const ANIMATION_DURATION = 800; // 800ms for both enter and leave animations

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const handleMouseEnter = () => {
        if (isLoaded && !animationInProgressRef.current) {
            animationInProgressRef.current = true;
            setIsHovering(true);
            setHasInteracted(true);
            timeoutRef.current = setTimeout(() => {
                animationInProgressRef.current = false;
            }, ANIMATION_DURATION);
        }
    };

    const handleMouseLeave = () => {
        if (isLoaded) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(() => {
                setIsHovering(false);
                animationInProgressRef.current = false;
            }, ANIMATION_DURATION);
        }
    };

    const handleImageLoad = () => {
        setIsLoaded(true);
    };

    const baseClassName = ferfolio ? 'ferfolio-preview-image' : 'nft-preview-image';

    return (
        <img
            src={src}
            alt={alt}
            className={`${baseClassName} ${isHovering ? 'hovering' : ''} ${isLoaded ? 'loaded' : ''} ${hasInteracted ? 'interacted' : ''} ${className}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onLoad={handleImageLoad}
            onClick={onClick}
        />
    );
};

export default NFTPreviewImage;