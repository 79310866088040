import React from 'react';
import NameCard from './NameCard';

const MintingOverlay = ({ name, tier }) => {
  const isFerminator = name?.name === 'FERMINATOR';
  
  const overlayStyle = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: isFerminator ? 'rgba(0, 0, 0, 0.9)' : 'rgba(0, 0, 0, 0.7)',
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backdropFilter: 'blur(4px)',
    WebkitBackdropFilter: 'blur(4px)',
    transition: 'all 0.3s ease-in-out',
    flexDirection: 'column',
    gap: '2rem'
  };

  const containerStyle = {
    backgroundColor: isFerminator ? 'rgba(0, 0, 0, 0.95)' : 'rgba(0, 0, 0, 0.8)',
    padding: '3.5rem 7.5rem',
    borderRadius: '0.75rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    border: isFerminator ? '1px solid #333' : 'none',
    boxShadow: isFerminator ? '0 0 20px rgba(255, 255, 255, 0.1)' : 'none'
  };

  const cardWrapperStyle = {
    transform: 'scale(1.5)',
    transformOrigin: 'center',
    marginBottom: '2rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    border: '2px solid transparent',
    borderTopColor: isFerminator ? '#000' : '#fff',
    borderBottomColor: isFerminator ? '#000' : '#fff',
    borderRadius: '50%',
    animation: 'spin 2s linear infinite'
  };

  const textStyle = {
    color: isFerminator ? '#000' : '#fff',
    fontSize: '1.25rem',
    fontWeight: 500,
    fontFamily: 'inherit',
    textTransform: isFerminator ? 'uppercase' : 'none',
    letterSpacing: isFerminator ? '0.05em' : 'normal'
  };

  return (
    <div style={overlayStyle}>
      <div style={containerStyle}>
        <div style={cardWrapperStyle}>
          <NameCard
            name={name}
            tier={tier}
            eligible={false}
            isProcessing={true}
            onMint={() => {}}
            isFerminator={isFerminator}
            
          />
        </div>
        <div style={spinnerStyle} />
        <style>
          {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
        </style>
        <span style={textStyle} className='textmedium'>
          {isFerminator ? 'AWAITING FERMINATION...' : 'Pleafe confirm in your wallet and wait...'}
        </span>
      </div>
    </div>
  );
};

export default MintingOverlay;