// formatUtils.js

import EthersLogo from '../../src/eth_logo.png';
import FerCoinLogo from '../../src/fer_coin_logo.png';

const formatAmount = (amount) => {
  const numAmount = Number(amount);
  if (isNaN(numAmount)) {
    console.error("Invalid amount passed to formatAmount:", amount);
    return 'N/A';
  }
  if (numAmount >= 1e9) {
    return (numAmount / 1e9).toFixed(3) + 'B';
  } else if (numAmount >= 1e6) {
    return (numAmount / 1e6).toFixed(2) + 'M';
  } else if (numAmount >= 1e3) {
    const numK = numAmount / 1e3;
    return (Number.isInteger(numK) ? numK.toFixed(0) : numK.toFixed(1)) + 'K';
  } else if (numAmount >= 10) {
    return Math.floor(numAmount).toString();
  } else {
    return Number.isInteger(numAmount) ? numAmount.toString() : numAmount.toFixed(2);
  }
};

const formatAmountPot = (amount = 0) => {
  const numAmount = Number(amount);
  if (isNaN(numAmount)) {
    console.error("Invalid amount passed to formatAmountPot:", amount);
    return 'N/A';
  }
  if (numAmount >= 1e9) {
    return Math.round(numAmount / 1e9) + 'B';
  } else if (numAmount >= 1e6) {
    return Math.round(numAmount / 1e6) + 'M';
  } else if (numAmount >= 1e3) {
    return Math.round(numAmount / 1e3) + 'K';
  } else {
    return Math.round(numAmount).toString();
  }
};

const formatAddress = (address) => {
  if (address && address.length > 6) {
    return `${address.substring(0, 6)}..${address.substring(address.length - 4)}`;
  }
  return address;
};

function formatDate(timestamp) {
  if (!timestamp) return 'N/A';
  const numericTimestamp = Number(timestamp.toString());
  const date = new Date(numericTimestamp * 1000);
  return date.toLocaleString();
}


const formatCooldown = (seconds) => {
  if (seconds < 1) return '';
  
  if (seconds < 60) {
    return `${Math.floor(seconds)}s`;
  }
  
  return `${Math.floor(seconds / 60)}m`;
};

const formatTimeLeft = (releaseTime, claimed) => {
  if (claimed) return 'Claimed';
  const now = Math.floor(Date.now() / 1000);
  const timeLeft = releaseTime - now;
  if (timeLeft <= 0) return 'Claimable now';
  const days = Math.floor(timeLeft / 86400);
  const hours = Math.floor((timeLeft % 86400) / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  
  let parts = [];
  if (days > 0) parts.push(`${days}d`);
  if (hours > 0) parts.push(`${hours}h`);
  if (minutes > 0 || parts.length === 0) parts.push(`${minutes}m`);
  
  return parts.join(' ');
};

const formatSeasonTimeLeft = (releaseTime, status) => {
  if (releaseTime == null || typeof releaseTime !== 'number') return 'N/A';

  let timeDifference = releaseTime; // timeLeft from determineSeasonStatus
  let prefix = "";
  let suffix = "";
  let absTimeLeft = Math.abs(timeDifference);

  if (status === 'active') {
    prefix = "";
  } else if (status === 'concluding' || status === 'ended') {
    prefix = "Ended ";
    suffix = " ago";
  } else if (status === 'not_active') {
    prefix = "Starts in ";
  } else {
    return 'N/A';
  }

  const days = Math.floor(absTimeLeft / 86400);
  const hours = Math.floor((absTimeLeft % 86400) / 3600);
  const minutes = Math.floor((absTimeLeft % 3600) / 60);

  let parts = [];
  if (days > 0) parts.push(`${days}d`);
  if (hours > 0) parts.push(`${hours}h`);
  if (minutes > 0 || parts.length === 0) parts.push(`${minutes}m`);

  return `${prefix}${parts.join(' ')}${suffix}`;
};



const applyFerSpeak = (text) => {
  const transformed = text
      .replace(/s/gi, 'F')
      .replace(/z/gi, 'F')
      .replace(/ce/gi, 'FE')
      .replace(/ci/gi, 'FI')
      .replace(/fir/gi, 'FER')
      .toUpperCase()
      .trim(); // Add trim() to remove any whitespace
  

  
  return transformed.length > 14 
      ? transformed.substring(0, 14)  // Try substring instead of slice
      : transformed;
};

const applyFerSpeakForg = (text) => {
  const transformed = text
      .replace(/s/gi, 'F')
      .replace(/z/gi, 'F')
      .replace(/ce/gi, 'FE')
      .replace(/ci/gi, 'FI')
      .replace(/fir/gi, 'FER')
      .toUpperCase();
  

  
  return transformed.length > 40 
      ? transformed.substring(0, 40)  // Try substring instead of slice
      : transformed;
};


// Utility function to format wei (BigInt) to ether string
const formatEther = (wei) => {
  const weiStr = BigInt(wei).toString();
  if (weiStr.length <= 18) {
    return '0.' + weiStr.padStart(18, '0');
  }
  const integerPart = weiStr.slice(0, weiStr.length - 18);
  const decimalPart = weiStr.slice(weiStr.length - 18).replace(/0+$/, '');
  return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
};

// Utility function to shorten Ethereum addresses for display
const shortenAddress = (address) => {
  return address.slice(0, 6) + '...' + address.slice(-4);
};

// Utility function to safely convert BigNumber or other types to number
const convertToNumber = (value) => {
  if (value && typeof value.toNumber === 'function') {
    return value.toNumber();
  }
  return Number(value);
};

const FERIcon = ({ style }) => (
  <img
    src={FerCoinLogo}
    alt="ETH Logo"
    style={{
      width: '1em',
      height: '1em',
      position:'relative',
      bottom:'0.05em',
      verticalAlign: 'middle',
      ...style,
    }}
  />
);


const ETHIcon = ({ style }) => (
  <img
    src={EthersLogo}
    alt="ETH Logo"
    style={{
      width: '1em',
      height: '1em',
      position:'relative',
      bottom:'0.05em',
      verticalAlign: 'middle',
      ...style,
    }}
  />
);



export { formatAmount,
        formatAmountPot,
        formatAddress,
        formatDate,
        formatTimeLeft,
        formatCooldown,
        applyFerSpeak,
        applyFerSpeakForg, 
        formatEther,
        shortenAddress, 
        convertToNumber,
        formatSeasonTimeLeft,
        FERIcon,
        ETHIcon
};