// FolioOverview.js
import React, { useMemo, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import NFTPreviewImage from './NFTPreviewImage';
import { formatAmount, formatEther } from '../utils/formatUtils';
import './Folio.css'; // Shared CSS
import ferCoinLogo from '../fer_coin_logo.png';
import { DataContext } from '../DataProvider'; // Import DataContext
import FolioHeader from './FolioHeader';
import useVotingComponentLogic from './Voting/VotingComponentLogic';
import goldIcon from '../goldmember.png'
import { FERIcon } from '../utils/formatUtils';
import Heart from './Voting/Heart';

// Helper function to select the appropriate image
const getTierImage = (tierNumber, dynamicTierImages, optimizedTierImages) => {
  return dynamicTierImages[tierNumber] || optimizedTierImages[tierNumber];
};


const GoldIconComponent = ({ style }) => (
  <img
    src={goldIcon}
    alt="ETH Logo"
    style={{
      width: '1.5em',
      height: 'auto',
      verticalAlign: 'middle',
      ...style,
    }}
  />
);


const RewardsSection = ({
  safeHighestTier,
  totalPoints,
  availablePoints,
  usedPoints,
  participationPercent,
  totalVotesReceived,
  isLoadingValuePerPoint,
  valuePerPoint,
  seasons,
  votesReceivedPerSeason, // Add this line
  rewardsPerSeason,       // Add this line
}) => {
  const {
    // MemeNFT Rewards
    memeRewardsData,
    memeHasClaimableRewards,
    loadingMemeRewards,
    memeError,
    totalRewardsClaimedMeme,
    handleClaimMemeRewards,
    fetchMemeRewards, // If needed

    // Other Sales Rewards
    otherSalesRewards,
    otherSalesHasClaimableRewards,
    loadingOtherSales,
    otherSalesError,
    totalRewardsPaidOtherSales,
    handleClaimOtherSalesRewards,

    // Own Sales Rewards
    ownSalesRewards,
    ownSalesHasClaimableRewards,
    loadingOwnSales,
    ownSalesError,
    totalRewardsEarnedOwnSales,
    handleClaimOwnSalesRewards,
    presaleActive,
    presaleEnded,
    nftData,
  } = useContext(DataContext);

  const hasGoldNFT = nftData.some(nft => nft.tier === 1 && nft.balance > 0);
  const hasMemeNFT = nftData.some(nft => nft.balance > 0);



  // Calculate totals for Ferfona Rewards
  const yourFalef = totalRewardsEarnedOwnSales
    ? parseFloat(totalRewardsEarnedOwnSales)/2
    : 0;

  const otherFalef = otherSalesRewards
    ? parseFloat(otherSalesRewards.claimableRewards) + parseFloat(totalRewardsPaidOtherSales || '0')
    : 0;

  const totalFalef = yourFalef + otherFalef;

  return (
    <div className="rewards-section">
      {/* Accumulated Total Rewards */}


      <div className="rewards-section-holder">
        {/* Ferfona Rewards */}
        <div className={`rewards-category ${hasGoldNFT ? '' : 'disabled'}`}>
          <h2 className="textmedium"><GoldIconComponent style={{position: 'absolute', left: '2%', top:'9%'}} /> FERFona Rewardf </h2>    

          {loadingOwnSales || loadingOtherSales ? (
            <div className="grid-row">
            <i className="textmediumsmall">Loading FERFona rewardf...</i>
            </div>
          ) : ownSalesError || otherSalesError ? (
            <div className="grid-row">
            <i className="textmediumsmall error">
              {ownSalesError || otherSalesError}
            </i>
            </div>
          ) : (
            <>
              {/* Your Falef */}
              <div className="grid-row">
                <h3 className="textmediumsmall">Your Falef</h3>
                <i className="textmediumsmall">
                  {formatAmount(yourFalef)} <FERIcon />
                </i>
              </div>

              {/* Other Falef */}
              <div className="grid-row">
                <h3 className="textmediumsmall">Other Falef</h3>
                <i className="textmediumsmall">
                  {formatAmount(otherFalef)}  <FERIcon />
                </i>
              </div>

              {/* Total Falef */}
              <div className="grid-row">
                <h3 className="textmediumsmall">Total</h3>
                <i className="textmediumsmall">
                  {formatAmount(totalFalef)}  <FERIcon />
                </i>
              </div>

              {/* Optional: Claim Buttons if applicable */}
              
            </>
          )}
        </div>

        {/* Memberfip Falef Rewardf */}
        <div
          className={`rewards-category ${safeHighestTier.toLowerCase()} ${
            hasMemeNFT && presaleEnded ? '' : 'disabled'
          }`}
        >
          <h2 className="textmedium">New Member Rewardf</h2>

          {loadingMemeRewards ? (
            <div className="grid-row">
              <h3 className="textmediumsmall">Loading</h3>
              <i className="textmediumsmall">Loading rewardf...</i>
            </div>
          ) : memeRewardsData ? (
            !presaleEnded ? (
              <div className="grid-row">
                <i className="textmediumsmall">
                  Ftarting after Prefale: <br />
                  Every memberfip fale will diftribute 50% of the $FER prife to
                  current memberf.<br />
                  <br /> Allocation if bafed on your <span className='ff'>F</span>er<span className='ff'>F</span>core
                </i>
              </div>
            ) : (
              <>
                <div className="grid-row">
                  <h3 className="textmediumsmall">Claimable</h3>
                  <i className="textmediumsmall">
                    {formatAmount(memeRewardsData.claimableRewards)} <FERIcon />
                  </i>
                </div>
                <div className="grid-row">
                  <h3 className="textmediumsmall">Claimed</h3>
                  <i className="textmediumsmall">
                    {formatAmount(totalRewardsClaimedMeme)} <FERIcon />
                  </i>
                </div>
                <div className="grid-row">
                  <h3 className="textmediumsmall">Total</h3>
                  <i className="textmediumsmall">
                    {formatAmount(memeRewardsData.totalEarned)} <FERIcon />
                  </i>
                </div>
              </>
            )
          ) : (
            <div className="grid-row">
              <h3 className="textmediumsmall">No Data</h3>
              <i className="textmediumsmall">
                No MemeNFT rewards data available. This could be because no
                rewards have been distributed yet.
              </i>
            </div>
          )}
        </div>

        {/* Fergiving Rewards */}
   
        <VotingOverviewSection
        seasons={seasons}
        votesReceivedPerSeason={votesReceivedPerSeason}
        rewardsPerSeason={rewardsPerSeason}
        safeHighestTier={safeHighestTier} // Pass the tier prop
      />
      
      </div>
    </div>
  );
};



const VotingOverviewSection = ({
  seasons,
  votesReceivedPerSeason,
  rewardsPerSeason,
  safeHighestTier,
}) => {
  const hasSeasonStarted = seasons.some(
    (season) => ['active', 'concluding', 'ended'].includes(season.status.toLowerCase())
  );

  const getTotalVotesForSeason = (seasonId) => {
    if (!votesReceivedPerSeason[seasonId]) return '0';
    return Object.values(votesReceivedPerSeason[seasonId])
      .reduce((sum, votes) => sum + Number(votes), 0)
      .toString();
  };

  const getStatusDisplay = (status) => {
    switch (status.toLowerCase()) {
      case 'active':
        return 'Active';
      case 'concluding':
        return 'Concluding';
      case 'ended':
        return 'Ended';
      case 'not_active':
        return 'Not Ftarted';
      case 'not ftarted':
        return 'Not Ftarted';
      default:
        return 'Unknown';
    }
  };

  // Calculate total rewards across all seasons
  const totalRewards = Object.values(rewardsPerSeason).reduce((total, seasonReward) => {
    const reward = BigInt(seasonReward.toString());
    return total + reward;
  }, BigInt(0));

  return (
    <div className={`rewards-category voting-overview ${safeHighestTier.toLowerCase()} ${!hasSeasonStarted ? 'disabled' : ''}`}>
      <h2 className="textmedium">FERGiving Overview</h2>

      {seasons && seasons.length > 0 ? (
        <>
          <div className="grid-row feafon header-row">
            <h3 className="textmediumsmall">Feafon</h3>
            <h3 className="textmediumsmall">Ftatuf</h3>
            <h3 className="textmediumsmall">FERGiven</h3>
            <h3 className="textmediumsmall">Rewardf</h3>
          </div>

          {seasons.map((season) => {
            const { id, status } = season;
            const totalVotes = getTotalVotesForSeason(id);
            const rewards = rewardsPerSeason[id]
              ? `${Number(formatEther(rewardsPerSeason[id].toString())).toFixed(2)}` 
              : '0.00';

            return (
              <div key={id} className={`grid-row feafon ${status.toLowerCase()}`}>
                <div className="grid-cell feafon">
                  <span className="textmediumsmall">{id}</span>
                </div>
                <div className="grid-cell feafon">
                  <span className={`status-badge textmediumsmall ${status.toLowerCase()}`}>
                    {getStatusDisplay(status)}
                  </span>
                </div>
                <div className="grid-cell feafon">
                  <i className="textmediumsmall">
                    <Heart
                      key={'display'}
                      status={'available'}
                      isMaxed={totalVotes.length < 1 ? 'true' : 'false'}
                      isAnimating={false}
                      canHover={false}
                      scale={0.8}
                    />
                    x{totalVotes}
                  </i>
                </div>
                <div className="grid-cell feafon">
                  <i className="textmediumsmall">≈{formatAmount(rewards)} <FERIcon /></i>
                </div>
              </div>
            );
          })}

          {/* Total Row */}
          <div className="grid-row feafon">
            <div className="grid-cell feafon">
              <h3 className="textmediumsmall">Total</h3>
            </div>
            <div className="grid-cell feafon"></div>
            <div className="grid-cell feafon"></div>
            <div className="grid-cell feafon">
              <i className="textmediumsmall">
                ≈{formatAmount(formatEther(totalRewards.toString()))} <FERIcon />
              </i>
            </div>
          </div>
        </>
      ) : (
        <div className="grid-row feafon">
          <i className="textmediumsmall">Loading...</i>
        </div>
      )}
    </div>
  );
};




const FolioOverview = ({
  ferBalance,
  totalVested,
  ferScore,
  tierNames,
  dynamicTierImages,
  optimizedTierImages,
  tierMap,
  handleNavigateToTier,
  isAuthenticated,
  userAddress,
  memeNFTContract,
  nameNFTContract,
  nameRegistryContract,
  nftData,
  userMainName
}) => {
  const [expandedImage, setExpandedImage] = useState(null); // New state to track expanded image

  const {
    loadFolioData, // Function to trigger data fetching
  } = useContext(DataContext);

  useEffect(() => {
    if (isAuthenticated) {
      loadFolioData();
    }
  }, [isAuthenticated, loadFolioData]);

  const tierOrder = ['GOLD', 'FILVER', 'BRONF', 'BAFIC'];

  const {
    totalPoints,
    availablePoints,
    usedPoints,
    participationPercent,
    totalVotesReceived,
    isLoadingValuePerPoint,
    valuePerPoint,
    seasons,
    votesReceivedPerSeason,
    rewardsPerSeason,
    // Any other data you need
  } = useVotingComponentLogic();



  // Mapping from tier names to their respective priority (higher number = higher tier)
  const tierPriorityMap = {
    'NOT A': 0,
    'BAFIC': 1,
    'BRONF': 2,
    'FILVER': 3,
    'GOLD': 4,
  };

  // Array containing tier-specific information
  const tiersInfo = [
    { // GOLD
      ferScore: 100,
      MorFER: 'Full Acfef',
      ferFona: 'Create, Fell + Rewardf!',
    },
    { // FILVER
      ferScore: 10, // Adjusted based on your tierDescriptions
      MorFER: 'Ekfended Acfef',
      ferFona: 'Filver Acfef',
    },
    { // BRONF
      ferScore: 1, // Adjusted based on your tierDescriptions
      MorFER: 'Standard Acfef',
      ferFona: 'Bronf Acfef',
    },
    { // BAFIC
      ferScore: 0,
      MorFER: 'Bafic Acfef',
      ferFona: 'None',
    },
  ];

  
  // Compute the highest tier based on nftData and ferBalance
  const highestTier = useMemo(() => {
    const userTiers = new Set(['NOT A']);

    // Process NFT data to determine owned tiers
    nftData.forEach(nft => {
      if (nft && typeof nft.tier === 'number') {
        // Assuming tier numbers correspond to the tierOrder array (1-based index)
        const tierName = tierOrder[nft.tier - 1];
        if (tierName) {
          userTiers.add(tierName);
        } else {
          console.warn(`Unknown tier number: ${nft.tier}`);
        }
      } else {
        console.warn("Invalid NFT data:", nft);
      }
    });

    // Add token balance tier
    const tokenBalanceTier = parseFloat(ferBalance) >= 1 ? 'BAFIC' : 'NOT A';
    userTiers.add(tokenBalanceTier);

    // Convert Set to Array and sort based on tier priority
    const sortedTiers = Array.from(userTiers).sort((a, b) => tierPriorityMap[b] - tierPriorityMap[a]);

    // The first element is the highest tier
    const computedTier = sortedTiers[0] || 'NOT A';
  
    return computedTier;
  }, [nftData, ferBalance]);

  // Ensure highestTier is valid
  const safeHighestTier = tierPriorityMap[highestTier] !== undefined ? highestTier : 'NOT A';
  

  return (
    <>      
    

    <div className="dashboard-section overview-container">
      {/* Top Section: Total Fer and Vested Fer */}
      <FolioHeader />
      {/* NFT Images Row */}
      <div className={`overview-nft-ff ${safeHighestTier.toLowerCase()}`}>
        <div className="overview-nft-row">
          {tierNames.map((tier) => {
            const tierKey = tier.toUpperCase();
            const tierNumber = tierMap[tierKey];
            const imageSrc = getTierImage(tierNumber, dynamicTierImages, optimizedTierImages);
            const nftForTier = nftData.find((nft) => nft.tier === tierNumber);
            const count = nftForTier ? nftForTier.balance : 0;

            // Retrieve tier-specific info based on tierNumber
            // Assuming tierNumber starts at 1 and tiersInfo array is 0-indexed
            const tierInfo = tiersInfo[tierNumber - 1] || {};

            return (
              <div
                key={tier}
                className={`overview-nft-card ${tier.toLowerCase()}card ${count < 1 ? 'grayed' : ''} ${
                  expandedImage === tier ? 'expanded' : expandedImage ? 'shrunk' : ''
                }`
              }
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering the card's onClick
                setExpandedImage(expandedImage === tier ? null : tier);
              }}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') handleNavigateToTier(tier);
                }}
                aria-label={`Navigate to ${tier} Tier`}
              >
                <span className={`tier-name ${tier.toLowerCase()}text textlarge ${expandedImage === tier ? 'expanded' : ''}`}>
                  {tier}
                </span>
                <div className="card-properties">
                  <span className={expandedImage === tier ? 'textmediumsmall expanded' : 'textmediumsmall'}>
                    <span className='goldtext'>F</span>er<span className='goldtext'>F</span>core <span className={`${tier.toLowerCase()}text`}>{tierInfo.ferScore}</span>
                  </span>
                  <span className={expandedImage === tier ? 'textmediumsmall expanded' : 'textmediumsmall'}>
                   MorFER: <span className={`${tier.toLowerCase()}text`}>{tierInfo.MorFER}</span>
                  </span>
                  <span className={expandedImage === tier ? 'textmediumsmall expanded' : 'textmediumsmall'}>
                    FerFona: <span className={`${tier.toLowerCase()}text`}>{tierInfo.ferFona}</span>
                  </span>
                </div>
                {imageSrc ? (
                  <img
                    src={imageSrc}
                    alt={`${tier} Tier`}
                    className="overview-tier-image"
                
                  />
                ) : (
                  <div className="tier-placeholder">No Image</div>
                )}
                <span className={`textmedium ${tier.toLowerCase()}text`}> x{count}</span>
              </div>
            );
          })}
        </div>

        {/* Total Fer Score */}
        <div className={`overview-ferfcore-rewards ${safeHighestTier.toLowerCase()}`}>
          <div className="overview-fer-score">
            <span className={`titletext large ${ferScore >= 100 ? 'gold' : ferScore >= 10 ? 'filver' : ferScore >=1 ? 'bronf' : ''}text`}>
              {ferScore} 
            </span>
            <span className={'textlarge'}>
              <span className='goldtext'>F</span>er<span className='goldtext'>F</span>core
            </span>
          </div>

          <RewardsSection
  safeHighestTier={safeHighestTier}
  totalPoints={totalPoints}
  availablePoints={availablePoints}
  usedPoints={usedPoints}
  participationPercent={participationPercent}
  totalVotesReceived={totalVotesReceived}
  isLoadingValuePerPoint={isLoadingValuePerPoint}
  valuePerPoint={valuePerPoint}
  seasons={seasons}
  votesReceivedPerSeason={votesReceivedPerSeason} // Add this line
  rewardsPerSeason={rewardsPerSeason}             // Add this line
/>
        </div>
      </div>
    </div>
    </>
  );
};

FolioOverview.propTypes = {
  ferBalance: PropTypes.string.isRequired,
  totalVested: PropTypes.number.isRequired,
  ferScore: PropTypes.string.isRequired,
  tierNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  dynamicTierImages: PropTypes.object.isRequired,
  optimizedTierImages: PropTypes.object.isRequired,
  tierMap: PropTypes.object.isRequired,
  handleNavigateToTier: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  userAddress: PropTypes.string.isRequired,
  memeNFTContract: PropTypes.object, // Adjust based on your contract instance
  nameNFTContract: PropTypes.object,
  nameRegistryContract: PropTypes.object,
  nftData: PropTypes.array.isRequired,
};

export default FolioOverview;
