// FolioFerfona.jsx

import React, { useContext, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import './NameCard.css'; // Shared CSS
import { DataContext } from '../DataProvider';
import CollectedNameCard from './CollectedNameCard'; // Ensure correct path
import { WalletContext } from '../WalletContext';
import FolioHeader from './FolioHeader';

// Import filter icons
import filverIcon from '../filvermember.png';
import bronfIcon from '../bronfmember.png';
import goldIcon from '../goldmember.png';

const FolioFerfona = () => {
  const { userNames, refreshNameData, fetchMintingStatusAndNames } = useContext(DataContext);
  const { contracts } = useContext(WalletContext);

  const [isProcessingSetMainName, setIsProcessingSetMainName] = useState({});

  // === Filtering and Pagination States ===
  const [filterBronf, setFilterBronf] = useState(false);
  const [filterFilver, setFilterFilver] = useState(false);
  const [filterGold, setFilterGold] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const namesPerPage = 25;

  // === Filtering Logic ===
  const highestTier = useMemo(() => {
    if (!userNames || userNames.length === 0) return null;
    const tiers = userNames.map(name => name.tier);
    return Math.min(...tiers);
  }, [userNames]);

  const filteredNames = useMemo(() => {
    let filtered = userNames.filter(name => !name.minted && !name.delisted);

    // Apply Tier Filters
    if (filterGold || filterFilver || filterBronf) {
      filtered = filtered.filter(name => {
        if (filterGold && name.tier === 1) return true;
        if (filterFilver && name.tier === 2) return true;
        if (filterBronf && name.tier === 3) return true;
        return false;
      });
    }

    // Apply Search Filter
    if (searchText.trim() !== '') {
      const lowerSearch = searchText.toLowerCase();
      filtered = filtered.filter(name => {
        const nameLower = name.name.toLowerCase();
        return (
          nameLower.startsWith(lowerSearch) ||
          nameLower.includes(lowerSearch)
        );
      });
    }

    // Shuffle the filtered names for random display
    const shuffled = [...filtered].sort(() => Math.random() - 0.5);

    return shuffled;
  }, [
    userNames,
    filterGold,
    filterFilver,
    filterBronf,
    searchText,
    highestTier,
  ]);

  // Pagination Logic
  const paginatedNames = useMemo(() => {
    const indexOfLastName = currentPage * namesPerPage;
    const indexOfFirstName = indexOfLastName - namesPerPage;
    return filteredNames.slice(indexOfFirstName, indexOfLastName);
  }, [filteredNames, currentPage]);

  const totalPages = Math.ceil(filteredNames.length / namesPerPage);

  // Reset to first page when filters or search change
  useEffect(() => {
    setCurrentPage(1);
  }, [
    filterBronf,
    filterFilver,
    filterGold,
    searchText,
  ]);

  // === setMainName Function ===
  const setMainName = async (tokenId) => {
    setIsProcessingSetMainName(prev => ({ ...prev, [tokenId]: true }));
    try {
      const transaction = await contracts.nameRegistryContract.setMainName(tokenId);
 
      await transaction.wait();
      await refreshNameData();
      await fetchMintingStatusAndNames();
    } catch (error) {
      console.error("Error setting main name:", error);
    } finally {
      setIsProcessingSetMainName(prev => ({ ...prev, [tokenId]: false }));
    }
  };

  // === Render Pagination Controls ===
  const renderPagination = () => (
    <div className='filter-buttons' style={{ display: 'flex', gap: '1vw', marginTop: '20px' }}>
      {/* Previous Button */}
      <div
        className={`filter-button ${currentPage === 1 ? 'disabled' : ''}`}
        style={{ opacity: currentPage === 1 ? 0.5 : 1, cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}
        onClick={() => {
          if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
          }
        }}
        title={currentPage === 1 ? 'Already on the first page' : 'Go to previous page'}
      >
        <span className='textmedium filtertext'>&lt;</span>
      </div>

      {/* Page Numbers */}
      {[...Array(totalPages)].map((_, index) => (
        <div
          key={index}
          className={`filter-button ${currentPage === index + 1 ? 'active' : ''}`}
          style={{ cursor: 'pointer' }}
          onClick={() => setCurrentPage(index + 1)}
        >
          <span className='textmedium filtertext'>{index + 1}</span>
        </div>
      ))}

      {/* Next Button */}
      <div
        className={`filter-button ${currentPage === totalPages ? 'disabled' : ''}`}
        style={{ opacity: currentPage === totalPages ? 0.5 : 1, cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
        onClick={() => {
          if (currentPage < totalPages) {
            setCurrentPage(prev => prev + 1);
          }
        }}
        title={currentPage === totalPages ? 'Already on the last page' : 'Go to next page'}
      >
        <span className='textmedium filtertext'>&gt;</span>
      </div>
    </div>
  );

  return (
    <>
      <FolioHeader />
 
      <div className='folio'>
        {/* Filter and Search Section */}
        <div className='other-names-section ferfolio'>
        <div className='header-filter-holder'>
          <h3 className='other-names-header ferfolio textlarge'>Who are you today, Fer?</h3>


          

          {/* === Filter Options === */}
          <div className='filter-options'>
            {/* Tier Filters */}
            <div className='tier-filters ferfolio' >
              {/* Gold Filter Icon */}
              <div
                className={`filter-icon ${filterGold ? 'active' : ''}`}
                onClick={() => setFilterGold(!filterGold)}
                title='Gold'
                style={{ cursor: 'pointer', padding: '5px' }}
              >
                <img src={goldIcon} alt='Gold Filter' width='50px' />
              </div>

              {/* Filver Filter Icon */}
              <div
                className={`filter-icon ${filterFilver ? 'active' : ''}`}
                onClick={() => setFilterFilver(!filterFilver)}
                title='Filver'
                style={{ cursor: 'pointer', padding: '5px' }}
              >
                <img src={filverIcon} alt='Filver Filter' width='50px' />
              </div>

              {/* Bronf Filter Icon */}
              <div
                className={`filter-icon ${filterBronf ? 'active' : ''}`}
                onClick={() => setFilterBronf(!filterBronf)}
                title='Bronf'
                style={{ cursor: 'pointer', padding: '5px' }}
              >
                <img src={bronfIcon} alt='Bronf Filter' width='50px' />
              </div>
            </div>

            {/* Search Input */}
            <div className={`search-group ${isSearchActive ? 'active' : ''}`} style={{ position: 'relative' }}>
              {!isSearchActive ? (
                <button
                  className='butn medium search-button'
                  onClick={() => setIsSearchActive(true)}
                  title='Search Names'
                  style={{ padding: '5px 10px', cursor: 'pointer' }}
                >
                  🔍
                </button>
              ) : (
                <div className='search-input-container' style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type='text'
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder='Fearf FERFona'
                    className='textmedium search-input'
                    style={{ width: '200px', padding: '5px' }}
                  />
                  <button
                    className='butn smol close textmediumsmall'
                    onClick={() => {
                      setSearchText('');
                      setIsSearchActive(false);
                    }}
                    title='Clear Search'
                    style={{ marginLeft: '0.5vw', padding: '5px 10px', cursor: 'pointer' }}
                  >
                    X
                  </button>
                </div>
              )}
            </div>
          </div>
          {/* === End of Filter Options === */}
        </div>

        {/* Names Grid */}
        <div className='names-grid' style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
          {paginatedNames.length > 0 ? (
            paginatedNames.map(name => {
              const eligible = highestTier !== null && highestTier <= name.tier && !name.minted;
              return (
                <CollectedNameCard
                  key={name.tokenId}
                  name={name}
                  setMainName={setMainName}
                  isProcessing={isProcessingSetMainName[name.tokenId]}
                  eligible={eligible}
                />
              );
            })
          ) : (
            <p>No names match your criteria.</p>
          )}
        </div>

        {/* Pagination Controls */}
        {totalPages > 1 && renderPagination()}
      </div>
    </div>
    </>
  );
};

// PropTypes validation (optional, based on your data structure)
FolioFerfona.propTypes = {
  // Define prop types if you pass any props to FolioFerfona
};

export default FolioFerfona;
