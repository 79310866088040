import { createWeb3Modal, defaultConfig } from '@web3modal/ethers';
import config from './config';

const ethersConfig = defaultConfig({
  ...config.ethersConfig,
  metadata: config.metadata,
});

const web3Modal = createWeb3Modal({
  ethersConfig,
  chains: [config.mainnet],
  projectId: config.projectId,
  enableAnalytics: true,
  // Add the following themeVariables
  themeVariables: {
    '--w3m-z-index': '9999',
    '--w3m-overlay-top': '0',
    '--w3m-overlay-bottom': 'auto',
    '--w3m-overlay-transform': 'translate3d(-50%, 0, 0)',
  }
});

export default web3Modal;