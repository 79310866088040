// FolioContainer.js
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { WalletContext } from '../WalletContext';
import { DataContext } from '../DataProvider';
import FolioMembership from './FolioMembership';
import FolioFerfona from './FolioFerfona';
import './Folio.css'; // Shared CSS

// Header Component
const Header = ({ userMainName, userAddress }) => (
''
);

Header.propTypes = {
  userMainName: PropTypes.string,
  userAddress: PropTypes.string.isRequired,
};

const FolioContainer = ({ activeTab, toggleFolio }) => {
  const { isLoading, isAuthenticated, contracts } = useContext(WalletContext);

  const {
    ferBalance,
    totalVested,
    userAddress,
    userMainName,
    dynamicTierImages,
    optimizedTierImages,
    imagesLoaded,
    nftData,
    ferScore,
    // New context data for rewards
    memeRewardsData,
    memeHasClaimableRewards,
    loadingMemeRewards,
    memeError,
    totalRewardsClaimedMeme,

    otherSalesRewards,
    otherSalesHasClaimableRewards,
    loadingOtherSales,
    otherSalesError,
    totalRewardsPaidOtherSales,

    ownSalesRewards,
    ownSalesHasClaimableRewards,
    loadingOwnSales,
    ownSalesError,
    totalRewardsEarnedOwnSales,

    handleClaimMemeRewards,
    handleClaimOtherSalesRewards,
    handleClaimOwnSalesRewards,
    presaleActive,

    loadFolioData,
  } = useContext(DataContext);

  const tierNames = ['Gold', 'Filver', 'Bronf'];

  const tierDescriptions = {
    GOLD: {
      'FER Score': 'Earn 100 FerFcore per card.',
      'MORFER Access': 'Ekftended acfeff to premium content in the MorFER.',
      Achievements: 'Unlock six achievement emblems.',
    },
    FILVER: {
      'FER Score': 'Earn 10 FerFcore per card.',
      'MorFER Access': 'Standard acfeff to content in the MorFER',
      Achievements: 'Unlock four achievement emblems.',
    },
    BRONF: {
      'FER Score': 'Earn 1 FerFcore per card.',
      'MorFER Access': 'Basic access to content in the MorFER.',
      Achievements: 'Unlock two achievement emblems.',
    },
  };

  const tierMap = {
    GOLD: 1,
    FILVER: 2,
    BRONF: 3,
  };

  // Handler to navigate to specific tier section
  const handleNavigateToTier = (tier) => {
    // Implement the logic to change the activeTab
    // For example, you can use a prop function passed from the parent component
    // to set the activeTab to the selected tier

    // Example: setActiveTab(tier.toLowerCase());
    // Make sure the parent component provides a way to change the activeTab
  };

  // Function to render content based on activeTab
  const renderContent = () => {
    if (isLoading || !imagesLoaded) {
      return <div className="loading">Loading...</div>;
    }

    switch (activeTab.toLowerCase()) {
        case 'memberfip': // Membership Tab
        return (
          <FolioMembership
          ferBalance={ferBalance}
          totalVested={totalVested}
          ferScore={ferScore}
          tierNames={tierNames}
          dynamicTierImages={dynamicTierImages}
          optimizedTierImages={optimizedTierImages}
          tierMap={tierMap}
          handleNavigateToTier={handleNavigateToTier}
          isAuthenticated={isAuthenticated}
          userAddress={userAddress}
          nftData={nftData}
          userMainName={userMainName}
            // handleImageClick can remain if used for image enlarging
          />
        );

        case 'ferfona': // Membership Tab
        return (
          <FolioFerfona
            tierNames={tierNames}
            tierMap={tierMap}
            nftData={nftData}
            dynamicTierImages={dynamicTierImages}
            optimizedTierImages={optimizedTierImages}
            tierDescriptions={tierDescriptions}
            // handleImageClick can remain if used for image enlarging
          />
        );
      default:
        return (
          <div className="dashboard-section">
            <p>Invalid Tab</p>
          </div>
        );
    }
  };

  // Optional: If you want to trigger Folio data fetch when the activeTab is 'overview'
  useEffect(() => {
    if (activeTab.toLowerCase() === 'overview' && isAuthenticated) {
      loadFolioData();
    }
  }, [activeTab, isAuthenticated, loadFolioData]);

  return (
    <div className="maximized-dashboard">
 
      <Header userMainName={userMainName} userAddress={userAddress} />
      <div className="dashboard-content">{renderContent()}</div>
      
    </div>
  );
};

FolioContainer.propTypes = {
  activeTab: PropTypes.string.isRequired,
  toggleFolio: PropTypes.func.isRequired,
};

export default FolioContainer;
