import React, { useState, useContext } from 'react';
import { WalletContext } from '../WalletContext'; // Ensure the path is correct
import config from '../config';
import { ethers } from 'ethers'; // For address validation

const AchievementManager = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [userAddress, setUserAddress] = useState('');
  const [status, setStatus] = useState(false);

  const { 
    isAuthenticated, 
    userAddress: currentUserAddress,
    contracts
  } = useContext(WalletContext);

  const isAdmin = currentUserAddress === config.adminAddress; // Check if the user is the admin

  const handleSetWinner = async () => {
    // Early return if not admin or not authenticated
    if (!isAdmin || !isAuthenticated) return;

    // Validate Ethereum address
    if (!ethers.isAddress(userAddress)) {
      alert('Please enter a valid Ethereum address.');
      return;
    }

    setIsProcessing(true);
    try {
      // Send transaction to set Picaffo winner
      const tx = await contracts.achievementManagerContract.setPicaffoWinner(userAddress, status);
      await tx.wait(); // Wait for the transaction to be mined

      alert(`Successfully set Picaffo winner status for ${userAddress} to ${status}.`);
      // Optionally, reset the form
      setUserAddress('');
      setStatus(false);
    } catch (error) {
      console.error('Error setting Picaffo winner:', error);
      alert('Failed to set Picaffo winner. Please check the console for more details.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSetMVFRecognized = async () => {
    // Early return if not admin or not authenticated
    if (!isAdmin || !isAuthenticated) return;

    // Validate Ethereum address
    if (!ethers.isAddress(userAddress)) {
      alert('Please enter a valid Ethereum address.');
      return;
    }

    setIsProcessing(true);
    try {
      // Send transaction to set MVF recognized status
      const tx = await contracts.achievementManagerContract.setMVFRecognized(userAddress, status);
      await tx.wait(); // Wait for the transaction to be mined

      alert(`Successfully set MVF recognized status for ${userAddress} to ${status}.`);
      // Optionally, reset the form
      setUserAddress('');
      setStatus(false);
    } catch (error) {
      console.error('Error setting MVF recognized:', error);
      alert('Failed to set MVF recognized. Please check the console for more details.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSetPreaferRecognized = async () => {
    // Early return if not admin or not authenticated
    if (!isAdmin || !isAuthenticated) return;

    // Validate Ethereum address
    if (!ethers.isAddress(userAddress)) {
      alert('Please enter a valid Ethereum address.');
      return;
    }

    setIsProcessing(true);
    try {
      // Send transaction to set Preafer recognized status
      const tx = await contracts.achievementManagerContract.setPreaferRecognized(userAddress, status);
      await tx.wait(); // Wait for the transaction to be mined

      alert(`Successfully set Preafer recognized status for ${userAddress} to ${status}.`);
      // Optionally, reset the form
      setUserAddress('');
      setStatus(false);
    } catch (error) {
      console.error('Error setting Preafer recognized:', error);
      alert('Failed to set Preafer recognized. Please check the console for more details.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Only render the component if the user is the admin
  if (!isAdmin) return null;

  return (
    <div className="set-picaffo-winner-container">
      <h3>Achievement Manager</h3>
      <div className="form-group">
        <label htmlFor="userAddress">User Address:</label>
        <input
          type="text"
          id="userAddress"
          className="form-control"
          value={userAddress}
          onChange={(e) => setUserAddress(e.target.value)}
          placeholder="0x..."
        />
      </div>
      <div className="form-group form-check">
        <input
          type="checkbox"
          id="status"
          className="form-check-input"
          checked={status}
          onChange={(e) => setStatus(e.target.checked)}
        />
        <label htmlFor="status" className="form-check-label">
          Status
        </label>
      </div>
      <div className="button-group">
        <button 
          className="btn btn-success mr-2"
          onClick={handleSetWinner} 
          disabled={isProcessing}
        >
          {isProcessing ? 'Processing...' : 'Set Picaffo Winner'}
        </button>
        <button 
          className="btn btn-primary mr-2"
          onClick={handleSetMVFRecognized} 
          disabled={isProcessing}
        >
          {isProcessing ? 'Processing...' : 'Set MVF Recognized'}
        </button>
        <button 
          className="btn btn-warning"
          onClick={handleSetPreaferRecognized} 
          disabled={isProcessing}
        >
          {isProcessing ? 'Processing...' : 'Set Preafer Recognized'}
        </button>
      </div>
    </div>
  );
};

export default AchievementManager;
