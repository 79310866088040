// DataProvider.js
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import { WalletContext } from './WalletContext';
import MemeNFTABI from './abis/MemeNFT.json';
import { svgToPng } from './utils/imageUtils';
import noMemberImg from './nomember.png';
import basicMemberImg from './baficmember.png';
import bronzeMemberImg from './bronfmember.png';
import silverMemberImg from './filvermember.png';
import goldMemberImg from './goldmember.png';
import web3Modal from './web3modal'; // Ensure this is correctly imported
import { decodeToken, setAuthToken, getAuthToken, removeAuthToken, clearAllAuthData } from './authUtils'


// Create a DataContext for managing application data
export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const {
    isAuthenticated,
    userAddress,
    contracts,
    contractsReady,
    logout,
    isCorrectChain
  } = useContext(WalletContext);

  // State variables
  const [nftData, setNftData] = useState(null);

  const [ethBalance, setEthBalance] = useState('');
  const [ferBalance, setFerBalance] = useState('');
  const [vestingDetails, setVestingDetails] = useState([]);
  const [totalVested, setTotalVested] = useState('0');
  const [hasClaimableTokens, setHasClaimableTokens] = useState(false);
  const [totalClaimableTokens, setTotalClaimableTokens] = useState('0');
  const [claimableRewards, setClaimableRewards] = useState('0');
  const [hasClaimableRewards, setHasClaimableRewards] = useState(false);
  
  const [nameSellRewards, setNameSellRewards] = useState('0');
  const [hasNameSellRewards, setHasNameSellRewards] = useState(false);
  
  const [nameCommunitySellRewards, setNameCommunitySellRewards] = useState('0');
  const [hasNameCommunitySellRewards, setHasNameCommunitySellRewards] = useState(false);
  
  const [userNamesForSale, setUserNamesForSale] = useState([]);
  const [userNamesSold, setUserNamesSold] = useState([]);
  const [hasVestedTokens, setHasVestedTokens] = useState(false);
  const [userTier, setUserTier] = useState('');
  const [claimTimes, setClaimTimes] = useState([]);

  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [refreshNameDataTrigger, setRefreshNameDataTrigger] = useState(0);
  

  const [isFetchingImages, setIsFetchingImages] = useState(false);


    // MemeNFT Rewards
    const [memeRewardsData, setMemeRewardsData] = useState(null);
    const [memeHasClaimableRewards, setMemeHasClaimableRewards] = useState(false);
    const [loadingMemeRewards, setLoadingMemeRewards] = useState(false);
    const [memeError, setMemeError] = useState(null);
    const [totalRewardsClaimedMeme, setTotalRewardsClaimedMeme] = useState('0');
  
    // Other Sales Rewards
    const [otherSalesRewards, setOtherSalesRewards] = useState(null);
    const [otherSalesHasClaimableRewards, setOtherSalesHasClaimableRewards] = useState(false);
    const [loadingOtherSales, setLoadingOtherSales] = useState(false);
    const [otherSalesError, setOtherSalesError] = useState(null);
    const [totalRewardsPaidOtherSales, setTotalRewardsPaidOtherSales] = useState('0');
  
    // Own Sales Rewards
    const [ownSalesRewards, setOwnSalesRewards] = useState(null);
    const [ownSalesHasClaimableRewards, setOwnSalesHasClaimableRewards] = useState(false);
    const [loadingOwnSales, setLoadingOwnSales] = useState(false);
    const [ownSalesError, setOwnSalesError] = useState(null);
    const [totalRewardsEarnedOwnSales, setTotalRewardsEarnedOwnSales] = useState('0');

    //Status
    const [presaleActive, setPresaleActive] = useState(false); // Changed to null to handle loading state
    const [presaleEnded, setPresaleEnded] = useState(true); // Changed to null to handle loading state
    const [seasonActive, setSeasonActive] = useState(false);
  
  
  const [hasMintedTier, setHasMintedTier] = useState({});
  const [canMintName, setCanMintName] = useState(false);
  const [eligibleTiers, setEligibleTiers] = useState([]);
  const [namesList, setNamesList] = useState([]);
  const [userNames, setUserNames] = useState([]);
  const [userMainName, setUserMainName] = useState('');
  const [ferScore, setFerScore] = useState(0);
  const [membershipData, setMembershipData] = useState({
    GOLD: 0,
    FILVER: 0,
    BRONF: 0,
    BAFIC: 0
  });

  const [isCurtainAnimated, setIsCurtainAnimated] = useState(() => {
    return localStorage.getItem('isCurtainAnimated') === 'false';
  });

  const [allTierImages, setAllTierImages] = useState({});
  const [dynamicTierImages, setDynamicTierImages] = useState({});
  const [optimizedTierImages, setOptimizedTierImages] = useState({});
  const [imagesLoadedOnce, setImagesLoadedOnce] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [dataError, setDataError] = useState(null);

  // Tier Images Mapping
  const tierImages = {
    'NOT A': noMemberImg,
    'BAFIC': basicMemberImg,
    'BRONF': bronzeMemberImg,
    'FILVER': silverMemberImg,
    'GOLD': goldMemberImg
  };

  // Fetch Balances and Vesting Details



  


  const fetchBalancesAndVesting = useCallback(async () => {
    if (isAuthenticated && contractsReady && userAddress && isCorrectChain) {
      try {
        const provider = new ethers.BrowserProvider(web3Modal.getWalletProvider());
        const ethBalanceBigInt = await provider.getBalance(userAddress);
        const ethBalance = ethers.formatEther(ethBalanceBigInt);
  
        const ferBalanceBigInt = await contracts.coreERC20Contract.balanceOf(userAddress);
        const ferBalance = ethers.formatUnits(ferBalanceBigInt, 18);
  
        setEthBalance(ethBalance >= 0.01 ? parseFloat(ethBalance).toFixed(2) : parseFloat(ethBalance).toFixed(5));
        setFerBalance(ferBalance);
  
        const vestingCount = await contracts.vestingManagerContract.getVestingScheduleCount(userAddress);



        const configData = await contracts.presaleManagerContract.getPresaleStatus();
  
    
        setPresaleActive(configData[0]);
        setPresaleEnded(configData[1]);
 

        if(presaleEnded){
        const isActive = await contracts.votingManagerContract.votingSeasonActive();
        setSeasonActive(isActive);
      }

        // Fetch Claimable Rewards
        try {
          const memeClaimable = await contracts.memeNFTContract.getClaimableRewards(userAddress);
          const memeClaimableAmount = ethers.formatEther(memeClaimable);
          setClaimableRewards(memeClaimableAmount);
          setHasClaimableRewards(parseFloat(memeClaimableAmount) > 0);
        } catch (error) {
          console.error('Error fetching MemeNFT claimable rewards:', error);
          setClaimableRewards('0');
          setHasClaimableRewards(false);
        }
  
        // Vesting Details
        if (vestingCount > 0) {
          const details = await contracts.vestingManagerContract.getVestingSchedule(userAddress, 0, vestingCount);
          const amounts = details[0];
          const releaseTimes = details[1];
          const claimed = details[2];
          const claimTimes = details[3];
  
          const formattedDetails = amounts.map((amount, index) => ({
            amount: parseFloat(ethers.formatEther(amount)),
            releaseTime: parseInt(releaseTimes[index].toString()),
            claimed: claimed[index],
            claimTime: parseInt(claimTimes[index].toString())
          }));
          setVestingDetails(formattedDetails);
          setClaimTimes(claimTimes.map(time => parseInt(time.toString())));
  
          let totalVestedAmount = 0;
          let totalClaimableAmount = 0;
          const currentTime = Math.floor(Date.now() / 1000);
  
          formattedDetails.forEach(detail => {
            if (!detail.claimed) {
              totalVestedAmount += detail.amount;
              if (detail.releaseTime <= currentTime) {
                totalClaimableAmount += detail.amount;
              }
            }
          });
  
          setTotalVested(totalVestedAmount);
          setTotalClaimableTokens(totalClaimableAmount);
          setHasClaimableTokens(totalClaimableAmount > 0);
          setHasVestedTokens(totalVestedAmount > 0);
  
          // Fetch NAME-sell Rewards
          try {
            const nameSellClaimable = await contracts.nameNFTContract.getClaimableOwnSalesRewards(userAddress);
            const nameSellClaimableAmount = ethers.formatEther(nameSellClaimable);
            const tier1points = await contracts.memeNFTContract.isTier1Holder(userAddress)
      
            setNameSellRewards(nameSellClaimableAmount);
            setHasNameSellRewards(parseFloat(nameSellClaimableAmount) > 0);
          } catch (error) {
            console.error('Error fetching NAME-sell claimable rewards:', error);
            setNameSellRewards('0');
            setHasNameSellRewards(false);
          }
  
          try {
            const nameCommunitySellClaimable = await contracts.nameNFTContract.getClaimableOtherSalesRewards(userAddress);
            const nameCommunitySellClaimableAmount = ethers.formatEther(nameCommunitySellClaimable);
          
            setNameCommunitySellRewards(nameCommunitySellClaimableAmount);
            setHasNameCommunitySellRewards(parseFloat(nameCommunitySellClaimableAmount) > 0);
          } catch (error) {
            console.error('Error fetching NAME-sell claimable rewards:', error);
            setNameCommunitySellRewards('0');
            setHasNameCommunitySellRewards(false);
          }
  
        } else {
          // Reset vesting data if no vesting schedules
          setVestingDetails([]);
          setTotalVested('0');
          setHasClaimableTokens(false);
          setTotalClaimableTokens('0');
          setHasVestedTokens(false);
        }
  
        // Fetch Fer Score
        try {
          const userInfo = await contracts.memeNFTContract.userInfo(userAddress);
          setFerScore(userInfo.points.toString());
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
  
      } catch (error) {
        console.error("Error fetching balances and vesting details:", error);
        // Reset all related states on error
        setVestingDetails([]);
        setTotalVested('0');
        setHasClaimableTokens(false);
        setTotalClaimableTokens('0');
        setHasVestedTokens(false);
        setClaimableRewards('0');
        setHasClaimableRewards(false);
        setNameSellRewards('0');
        setHasNameSellRewards(false);
      }
    } else {
      // Reset states if not authenticated or other conditions fail
      setUserTier('');
      
     
      setHasVestedTokens(false);
      setVestingDetails([]);
      setTotalVested('0');
      setHasClaimableTokens(false);
      setTotalClaimableTokens('0');
      setEthBalance('');
      setFerBalance('');
      setClaimableRewards('0');
      setHasClaimableRewards(false);
      setNameSellRewards('0');
      setHasNameSellRewards(false);
    }
  }, [isAuthenticated, contractsReady, userAddress, isCorrectChain, contracts.coreERC20Contract, contracts.vestingManagerContract, contracts.memeNFTContract, contracts.nameNFTContract]);


 

  // Fetch Minting Status and Names
  const fetchMintingStatusAndNames = useCallback(async () => {
    if (
      isAuthenticated &&
      contractsReady &&
      userAddress &&
      contracts &&
      contracts.coreERC20Contract &&
      nftData
    ) {
      try {
        // Fetch hasMintedTier for each tier from NameNFT
        const mintedTiers = {};
        for (let tier = 1; tier <= 3; tier++) {
          const hasMinted = await contracts.nameNFTContract.userMintedTier(userAddress, tier);
          mintedTiers[tier] = hasMinted;
        }
        setHasMintedTier(mintedTiers);
  
        // Fetch total number of names from NameRegistry
        const totalNames = await contracts.nameRegistryContract.getTotalNames();
        const totalNamesNumber = Number(totalNames);
  
        // Adjust the chunk size or limit the total names fetched
        const chunkSize = 100;
        const maxChunks = 10; // Limit to prevent too many requests
        const namesArray = [];
        const fetchPromises = [];
  
        for (let start = 0; start < totalNamesNumber && start < chunkSize * maxChunks; start += chunkSize) {
          const count = Math.min(chunkSize, totalNamesNumber - start);
          // Push fetch promises into an array
          fetchPromises.push(
            contracts.nameRegistryContract
              .getNames(BigInt(start), BigInt(count))
              .then((nameChunk) => {
                namesArray.push(
                  ...nameChunk.map((nameInfo, index) => {
                    const priceFormatted = ethers.formatUnits(nameInfo.price, 18);
                    return {
                      tokenId: start + index + 1,
                      name: nameInfo.name,
                      tier: Number(nameInfo.tier),
                      minted: nameInfo.minted,
                      price: priceFormatted,
                      priceInt: priceFormatted,
                      creator: nameInfo.creator,
                      delisted: nameInfo.delisted,
                    };
                  })
                );
              })
              .catch((error) => {
                console.error(`Error fetching names chunk starting at ${start}:`, error);
              })
          );
        }
  
        // Limit the number of concurrent requests
        const concurrencyLimit = 5;
        for (let i = 0; i < fetchPromises.length; i += concurrencyLimit) {
          await Promise.all(fetchPromises.slice(i, i + concurrencyLimit));
        }
  
        setNamesList(namesArray);
  
        // Determine eligibility for minting based on ownership and minting status
        const eligible = [];
        for (let tier = 1; tier <= 3; tier++) {
          const ownsTierNFT = nftData.some((nft) => nft.tier === tier);
          if (ownsTierNFT && !mintedTiers[tier]) {
            eligible.push(tier);
          }
        }
  
        const userListedNames = namesArray.filter(
          (name) =>
            name.creator.toLowerCase() === userAddress.toLowerCase() &&
            !name.minted &&
            !name.delisted
        );
  
        setUserNamesForSale(userListedNames);
  
        const userSoldNames = namesArray.filter(
          (name) =>
            name.creator.toLowerCase() === userAddress.toLowerCase() &&
            name.minted &&
            !name.delisted
        );
  
        setUserNamesSold(userSoldNames);
  
        setEligibleTiers(eligible);
        setCanMintName(eligible.length > 0);
      } catch (error) {
        console.error("Error fetching minting status and names from NameRegistry:", error);
      }
    }
  }, [isAuthenticated, contractsReady, userAddress, isCorrectChain, nftData, contracts]);
  

  // Fetch Main Name
  const fetchMainName = useCallback(async () => {
    if (isAuthenticated && contractsReady && userAddress && contracts && contracts.coreERC20Contract) {
      try {
        const nameInfo = await contracts.nameRegistryContract.getMainNameDetails(userAddress); // Fetch from NameRegistry
        setUserMainName(nameInfo.name);
       
      } catch (error) {
        console.error("Error fetching main name from NameRegistry:", error);
      }
    } else {
      setUserMainName('');
    }
  }, [isAuthenticated, contractsReady, userAddress, isCorrectChain, contracts]);


// WalletContext.js



  // Fetch User Names
  const fetchUserNames = useCallback(async () => {
    if (
      isAuthenticated &&
      contractsReady &&
      userAddress &&
      contracts &&
      contracts.nameNFTContract
    ) {
      try {
        const userNamesInfo = await contracts.nameNFTContract.getUserNames(userAddress);
        const nameArray = userNamesInfo.map(info => ({
          tokenId: info.tokenId.toString(),
          name: info.name,
          tier: Number(info.tier)
        }));
        setUserNames(nameArray);
      } catch (error) {
        console.error("Error fetching user names from NameNFT:", error);
      }
    } else {
      setUserNames([]);
    }
  }, [isAuthenticated, contractsReady, userAddress, contracts]);


// Fetch Membership Images
const fetchMembershipImages = async () => {
  if (
    isAuthenticated &&
    contractsReady &&
    userAddress &&
    contracts &&
    contracts.coreERC20Contract &&
    !isFetchingImages &&
    nftData !== null
  ) {
   
    setIsFetchingImages(true);
    try {
      const allTierImageMapTemp = {};
      const optimizedImagesTemp = {};
      const userNftImageMapTemp = {};

      // Array to hold promises for fetching and decoding each tier
      const tierPromises = [1, 2, 3].map(async (tier) => {
        try {
          // Fetch SVG for the tier
          const svgContent = await contracts.masterSVGContract.compileFullSVG(tier, "", ethers.ZeroAddress);
          allTierImageMapTemp[tier] = svgContent;
      
          // Decode the SVG to PNG
          const pngDataUrl = await svgToPng(svgContent);
          optimizedImagesTemp[tier] = pngDataUrl;
  
        } catch (error) {
          console.error(`Error processing tier ${tier}:`, error);
          // Handle individual tier errors without stopping the entire process
        }
      });

      // Wait for all tiers to be processed concurrently
      await Promise.all(tierPromises);

      // Process user-specific NFT images concurrently
      const userNftPromises = nftData.map(async (nft) => {
        if (nft.balance > 0 && nft.nftIds && nft.nftIds.length > 0) {
          try {
            const tokenId = nft.nftIds[0];
            const encodedTokenURI = await contracts.memeNFTContract.tokenURI(tokenId);
            const decodedTokenURI = atob(encodedTokenURI.split(',')[1]);
            const metadata = JSON.parse(decodedTokenURI);
            const encodedImage = metadata.image;
            const decodedImage = atob(encodedImage.split(',')[1]);
          
  
            const pngDataUrl = await svgToPng(decodedImage);
            userNftImageMapTemp[nft.tier] = pngDataUrl;
          } catch (error) {
            console.error(`Error processing NFT for tier ${nft.tier}:`, error);
            // Handle individual NFT errors without stopping the entire process
          }
        }
      });

      await Promise.all(userNftPromises);

      // Merge all tier images with user-specific NFT images
      const finalDynamicTierImages = { ...optimizedImagesTemp, ...userNftImageMapTemp };

      // Batch state updates
      setAllTierImages(allTierImageMapTemp);
      setOptimizedTierImages(optimizedImagesTemp);
      setDynamicTierImages(finalDynamicTierImages);
    } catch (error) {
      console.error("Error fetching and processing images:", error);
    } finally {
      setImagesLoaded(true);
      setIsFetchingImages(false);
    }
  } else {

    // Do not set imagesLoaded to true here because images haven't been fetched
  }
};



 // Fetch MemeNFT Rewards
 const fetchMemeRewards = useCallback(async () => {
  setLoadingMemeRewards(true);
  setMemeError(null);

  try {
    if (!isAuthenticated || !userAddress || !contracts.memeNFTContract) {
      throw new Error('User not connected or MemeNFT contract not available.');
    }

    const claimableRewardsBN = await contracts.memeNFTContract.getClaimableRewards(userAddress);
    const totalRewardsClaimedBN = await contracts.memeNFTContract.totalRewardsClaimed(userAddress);

    // Convert to BigInt if necessary
    const claimableRewards = BigInt(claimableRewardsBN.toString());
    const totalRewardsClaimed = BigInt(totalRewardsClaimedBN.toString());

    // Calculate totalEarned = claimableRewards + totalRewardsClaimed
    const totalEarned = claimableRewards + totalRewardsClaimed;

    // Convert BigInts to decimal strings using ethers.formatUnits
    const formattedClaimableRewards = ethers.formatUnits(claimableRewards, 18);
    const formattedTotalRewardsClaimed = ethers.formatUnits(totalRewardsClaimed, 18);
    const formattedTotalEarned = ethers.formatUnits(totalEarned, 18);

    // Determine if the user has rewards above the minimum threshold
    const MIN_REWARD_THRESHOLD = 0.001; // 0.001 tokens
    const hasRewards = parseFloat(formattedClaimableRewards) >= MIN_REWARD_THRESHOLD;


    // Update state with the fetched data
    setMemeRewardsData({
      claimableRewards: formattedClaimableRewards,
      totalEarned: formattedTotalEarned,
    });
    setMemeHasClaimableRewards(hasRewards);
    setTotalRewardsClaimedMeme(formattedTotalRewardsClaimed);

  } catch (err) {
    console.error('Error fetching MemeNFT rewards:', err);
    setMemeError(`Failed to fetch MemeNFT rewards: ${err.message}`);
    setMemeRewardsData(null);
    setTotalRewardsClaimedMeme('0');
  } finally {
    setLoadingMemeRewards(false);
  }
}, [isAuthenticated, userAddress, contracts]);

// Fetch Other Sales Rewards from NameNFT
const fetchOtherSalesRewards = useCallback(async () => {
  setLoadingOtherSales(true);
  setOtherSalesError(null);
  try {
    if (!isAuthenticated || !userAddress || !contracts.nameNFTContract || !contracts.memeNFTContract) {
      throw new Error('User not connected or contracts not available.');
    }


    const isTier1Holder = await contracts.memeNFTContract.isTier1Holder(userAddress);
    if (!isTier1Holder) {
    
      setOtherSalesRewards({
        totalEarned: '0',
        claimableRewards: '0',
      });
      setOtherSalesHasClaimableRewards(false);
      setTotalRewardsPaidOtherSales('0');
      return;
    }

 
    const userPointsBN = await contracts.memeNFTContract.getUserPoints(userAddress);
    const userPoints = BigInt(userPointsBN.toString());
 

   
    const cumulativeRewardPerPointStoredBN = await contracts.nameNFTContract.cumulativeRewardPerPointStored();
    const cumulativeRewardPerPointStored = BigInt(cumulativeRewardPerPointStoredBN.toString());
   

    const PRECISION = BigInt('1000000000000000000'); // 1e18

    // Calculate total earned rewards
    const totalEarned = (userPoints * cumulativeRewardPerPointStored) / PRECISION;
   


    const claimableRewardsBN = await contracts.nameNFTContract.getClaimableOtherSalesRewards(userAddress);
    const claimableRewards = BigInt(claimableRewardsBN.toString());
   

    const MIN_REWARD_THRESHOLD = BigInt('1000000000000000'); // 0.001 tokens in wei
    const hasRewards = claimableRewards >= MIN_REWARD_THRESHOLD;
    
    // Convert BigInt values to decimal strings using ethers.formatUnits
    const formattedTotalEarned = ethers.formatUnits(totalEarned, 18);
    const formattedClaimableRewards = ethers.formatUnits(claimableRewards, 18);

    setOtherSalesRewards({
      totalEarned: formattedTotalEarned,
      claimableRewards: formattedClaimableRewards,
    });
    setOtherSalesHasClaimableRewards(hasRewards);

    // Fetch totalRewardsPaid from NameNFT

    const totalPaidOtherSalesBN = await contracts.nameNFTContract.getTotalRewardsPaid(userAddress);
    const totalPaidOtherSales = ethers.formatUnits(totalPaidOtherSalesBN, 18);

    setTotalRewardsPaidOtherSales(totalPaidOtherSales);
  } catch (err) {
    console.error('Error fetching other sales rewards:', err);
    setOtherSalesError(`Failed to fetch other sales rewards: ${err.message}`);
    setOtherSalesRewards(null);
    setTotalRewardsPaidOtherSales('0');
  } finally {
    setLoadingOtherSales(false);
  }
}, [isAuthenticated, userAddress, contracts]);

// Fetch Own Sales Rewards
const fetchOwnSalesRewards = useCallback(async () => {
  setLoadingOwnSales(true);
  setOwnSalesError(null);
  try {
    if (!isAuthenticated || !userAddress || !contracts.nameNFTContract || !contracts.nameRegistryContract) {
      throw new Error('User not connected or contracts not available.');
    }


    const totalNames = await contracts.nameRegistryContract.getTotalNames();  // Use nameRegistryContract here
    const totalNamesNumber = Number(totalNames);
  

    const chunkSize = 100; // Adjust based on performance considerations
    let totalEarned = BigInt(0);

 
    for (let start = 0; start < totalNamesNumber; start += chunkSize) {
      const count = Math.min(chunkSize, totalNamesNumber - start);
      try {
        const nameChunk = await contracts.nameRegistryContract.getNames(BigInt(start), BigInt(count));
        nameChunk.forEach(nameInfo => {
          if (
            nameInfo.creator.toLowerCase() === userAddress.toLowerCase() &&
            nameInfo.minted === true
          ) {
            totalEarned += BigInt(nameInfo.price.toString());
          }
        });
      
      } catch (error) {
        console.error(`Error fetching names chunk starting at ${start}:`, error);
        throw new Error('Failed to fetch names from the registry.');
      }
    }



    const formattedTotalEarned = ethers.formatUnits(totalEarned, 18);

    const MIN_REWARD_THRESHOLD = BigInt('1000000000000000'); // 0.001 tokens in wei
    const hasRewards = totalEarned >= MIN_REWARD_THRESHOLD;

    setOwnSalesRewards({
      claimableRewards: formattedTotalEarned,
    });
    setOwnSalesHasClaimableRewards(hasRewards);
    setTotalRewardsEarnedOwnSales(formattedTotalEarned);
  } catch (err) {
    console.error('Error fetching own sales rewards:', err);
    setOwnSalesError(`Failed to fetch own sales rewards: ${err.message}`);
    setOwnSalesRewards(null);
    setTotalRewardsEarnedOwnSales('0');
  } finally {
    setLoadingOwnSales(false);
  }
}, [isAuthenticated, userAddress, contracts]);

// Handle Claim Rewards for MemeNFT
const handleClaimMemeRewards = useCallback(async () => {
  try {
    if (!contracts.memeNFTContract) throw new Error('MemeNFT contract not available.');

    const tx = await contracts.memeNFTContract.claimRewards();

    await tx.wait();
   

    fetchMemeRewards();
    alert('Successfully claimed MemeNFT rewards!');
  } catch (err) {
    console.error('Error claiming MemeNFT rewards:', err);
    setMemeError(`Failed to claim MemeNFT rewards: ${err.message}`);
    alert(`Failed to claim MemeNFT rewards: ${err.message}`);
  }
}, [contracts, fetchMemeRewards]);

// Handle Claim Other Sales Rewards
const handleClaimOtherSalesRewards = useCallback(async () => {
  try {
    if (!contracts.nameNFTContract) throw new Error('NameNFT contract not available.');

    const tx = await contracts.nameNFTContract.claimRewards();
   
    await tx.wait();
    

    fetchOtherSalesRewards();
    alert('Successfully claimed Other Sales rewards!');
  } catch (err) {
    console.error('Error claiming Other Sales rewards:', err);
    setOtherSalesError(`Failed to claim Other Sales rewards: ${err.message}`);
    alert(`Failed to claim Other Sales rewards: ${err.message}`);
  }
}, [contracts, fetchOtherSalesRewards]);

// Handle Claim Own Sales Rewards
const handleClaimOwnSalesRewards = useCallback(async () => {
  try {
    if (!contracts.nameNFTContract) throw new Error('NameNFT contract not available.');

    // Assuming there's a function to claim own sales rewards, e.g., claimOwnSalesRewards()
    const tx = await contracts.nameNFTContract.claimOwnSalesRewards();
   
    await tx.wait();
   

    fetchOwnSalesRewards();
    alert('Successfully claimed Own Sales rewards!');
  } catch (err) {
    console.error('Error claiming Own Sales rewards:', err);
    setOwnSalesError(`Failed to claim Own Sales rewards: ${err.message}`);
    alert(`Failed to claim Own Sales rewards: ${err.message}`);
  }
}, [contracts, fetchOwnSalesRewards]);

// ----- Trigger Function to Load Folio Data -----

const loadFolioData = useCallback(() => {
  fetchBalancesAndVesting(); // Add this line
  fetchMemeRewards();
  fetchOtherSalesRewards();
  fetchOwnSalesRewards();
}, [fetchMemeRewards, fetchOtherSalesRewards, fetchOwnSalesRewards]);

// ---------------------------------------------

// Refresh NFT Data Function
const refreshNFTData = useCallback(async () => {
  if (!isAuthenticated || !contractsReady || !userAddress || !contracts || !contracts.coreERC20Contract) {
    return;
  }

  try {
    const authTokenObj = getAuthToken();
    const token = authTokenObj ? authTokenObj.value : '';

    if (typeof userAddress !== 'string' || typeof token !== 'string') {
      console.error("Invalid userAddress or token type");
      return;
    }

    const abiString = JSON.stringify(MemeNFTABI.abi);
    if (typeof abiString !== 'string') {
      console.error("Invalid ABI type after stringification:", typeof abiString);
      return;
    }


    const response = await fetch(`${process.env.REACT_APP_API_URL}/public/refresh_nft_data.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        address: userAddress, 
        token: token,
        abi: abiString
      }),
    });

    const responseData = await response.json();


    if (!response.ok) {
      throw new Error(`Server responded with ${response.status}: ${responseData.error || 'Unknown Error'}`);
    }

    if (responseData.isValid && responseData.token) {
      setAuthToken(responseData.token, userAddress);


      const decodedToken = decodeToken(responseData.token);
   

      if (decodedToken) {
        setUserTier(decodedToken.userTier.toString());
        setNftData(decodedToken.nftData || []); // Update nftData
       
        
        // Optionally, trigger Folio data fetch after NFT data is updated
        loadFolioData();
      }
    } else {
     
    }
  } catch (error) {
    console.error("Error refreshing NFT data:", error.message || error);
  }
}, [isAuthenticated, userAddress, contractsReady, contracts, loadFolioData]);

// Existing useEffect hooks...


  // Effect to fetch data when authenticated and contracts are ready
  useEffect(() => {
    const fetchBigData = async () => {
    if (isAuthenticated && contractsReady) {


      await Promise.all([
        setIsLoadingData(true),
        fetchBalancesAndVesting(),
        refreshNFTData(),
        fetchMainName(),
        fetchUserNames(),
        setIsLoadingData(false),
      ]);
      
  
    } else {
      // Reset data states when not authenticated
      setEthBalance('');
      setFerBalance('');
      setVestingDetails([]);
      setTotalVested('0');
      setHasClaimableTokens(false);
      setTotalClaimableTokens('0');
      setHasVestedTokens(false);
      setClaimableRewards('0');
      setHasClaimableRewards(false);
      setNameSellRewards('0');
      setHasNameSellRewards(false);
      setNameCommunitySellRewards('0');
      setHasNameCommunitySellRewards(false);
      setUserNamesForSale([]);
      setUserNamesSold([]);
      setHasVestedTokens(false);
      setClaimTimes([]);
      setHasMintedTier({});
      setCanMintName(false);
      setEligibleTiers([]);
      setNamesList([]);
      setUserNames([]);
      setUserMainName('');
      setFerScore(0);
      setMembershipData({
        GOLD: 0,
        FILVER: 0,
        BRONF: 0,
        BAFIC: 0
      });
      setAllTierImages({});
      setDynamicTierImages({});
      setOptimizedTierImages({});
      setImagesLoaded(false);
      setImagesLoadedOnce(false);
    };
  };
  fetchBigData();
}, [isAuthenticated, contractsReady, refreshTrigger]);


useEffect(() => {
  const fetchData = async () => {
    if (isAuthenticated && contractsReady) {
      await fetchMainName();
      await fetchMintingStatusAndNames();
      await fetchUserNames();
    }
  };
  fetchData();
}, [
  isAuthenticated,
  contractsReady,
  fetchMintingStatusAndNames,
  fetchUserNames,
  refreshNameDataTrigger,
]);

useEffect(() => {
  const fetchImages = async () => {
    if (
      isAuthenticated &&
      contractsReady &&
      userAddress &&
      contracts &&
      contracts.coreERC20Contract &&
      nftData !== null &&
      !isFetchingImages
    ) {
      await fetchMembershipImages();
    }
  };
  fetchImages();
}, [
  isAuthenticated,
  contractsReady,
  userAddress,
  contracts,
  contracts.coreERC20Contract,
  nftData,
]);

// Existing refresh functions...
const refreshData = useCallback(() => {
  setRefreshTrigger(prev => prev + 1);
}, []);

const refreshNameData = useCallback(() => {
  setRefreshNameDataTrigger(prev => prev + 1);
}, []);

// -----------------------------------------

return (
  <DataContext.Provider
    value={{
      ethBalance,
      ferBalance,
      vestingDetails,
      totalVested,
      hasClaimableTokens,
      totalClaimableTokens,
      claimableRewards,
      hasClaimableRewards,
      nameSellRewards,
      hasNameSellRewards,
      nameCommunitySellRewards,
      hasNameCommunitySellRewards,
      userNamesForSale,
      hasVestedTokens,
      claimTimes,
      hasMintedTier,
      canMintName,
      eligibleTiers,
      namesList,
      userNames,
      userMainName,
      ferScore,
      membershipData,
      allTierImages,
      optimizedTierImages, 
      imagesLoaded,  
      dynamicTierImages,
      isCurtainAnimated,
      setIsCurtainAnimated,
      isLoadingData,
      dataError,
      refreshNFTData,
      nftData,
      userTier,
      setUserTier,
      userAddress,
      refreshData,
      refreshNameData,

      // ----- Exposed Folio Data and Functions -----
      memeRewardsData,
      memeHasClaimableRewards,
      loadingMemeRewards,
      memeError,
      totalRewardsClaimedMeme,

      otherSalesRewards,
      otherSalesHasClaimableRewards,
      loadingOtherSales,
      otherSalesError,
      totalRewardsPaidOtherSales,

      ownSalesRewards,
      ownSalesHasClaimableRewards,
      loadingOwnSales,
      ownSalesError,
      totalRewardsEarnedOwnSales,

      handleClaimMemeRewards,
      handleClaimOtherSalesRewards,
      handleClaimOwnSalesRewards,
      userNamesSold,
      presaleActive,
      presaleEnded,
      seasonActive,

      loadFolioData, // Function to trigger Folio data fetching
      // -----------------------------------------------
    }}
  >
    {children}
  </DataContext.Provider>
);
};

export default DataProvider;