import React, { useState, useEffect } from 'react';

const Heart = ({ 
  status,
  isMaxed = false,
  isAnimating = false,
  isLarge = false,
  onClick,
  canHover = true,
  scale = 1,
  pointer = true,
  style = []
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicking, setIsClicking] = useState(false);
  

  useEffect(() => {
    if (isClicking) {
      const preventDefault = (e) => {
        e.preventDefault();
        return false;
      };
      
      document.addEventListener('selectstart', preventDefault);
      document.addEventListener('mousedown', preventDefault);
      
      return () => {
        document.removeEventListener('selectstart', preventDefault);
        document.removeEventListener('mousedown', preventDefault);
      };
    }
  }, [isClicking]);

  const handleClick = (e) => {
    if (status !== 'used' && !isMaxed && onClick) {
      setIsClicking(true);
      onClick(e);
      // Reset clicking state after a short delay
      setTimeout(() => setIsClicking(false), 300);
    }
  };

  const getColors = () => {
    const colors = {
      base: '#ee0000',
      highlight: '#ff4444',
      shadow: '#cc0000',
      deepShadow: '#990000',
      shine: '#fff',
      stroke: '#fff',
      opacity: 1
    };

    switch (status) {
      case 'used':
        colors.base = '#861e1e';
        colors.highlight = '#962222';
        colors.shadow = '#761a1a';
        colors.deepShadow = '#561414';
        colors.shine = '#aaa';
        colors.opacity = 0.23;
        break;
      case 'selected':
        colors.base = '#ee0000';
        colors.highlight = '#ff4444';
        colors.shadow = '#cc0000';
        colors.deepShadow = '#990000';
        colors.shine = '#fff';
        colors.opacity = 1;
        break;
      case 'available':
        if (isMaxed) {
          colors.base = '#ff0000';
          colors.highlight = '#ff4444';
          colors.shadow = '#cc0000';
          colors.deepShadow = '#990000';
          colors.shine = '#fff';
          colors.opacity = 0.5;
        }
        break;
      default:
        break;
    }

    return colors;
  };

  const colors = getColors();

  const getAnimationStyle = () => {
    if (isAnimating) {
      return {
        animation: 'scaleFade 0.3s forwards'
      };
    }
    if (status === 'selected') {
      return {
        animation: 'scaleFadeInHeart forwards 1s'
      };
    }
    return {};
  };

  return (
    <div 
      className={`heart-wrapper ${status} ${isMaxed ? 'maxed' : ''} ${isAnimating ? 'animating' : ''}`}
      style={{
        cursor: status === 'used' || isMaxed ? 'not-allowed' : pointer ? 'pointer' : 'normal',
        display: 'inline-block',
        transform: isHovered && !isMaxed && status !== 'used' ? 'scale(1.5)' : 'scale(1)',
        transition: 'all 0.3s ease',
        position: 'relative',
        zIndex: isHovered && !isMaxed && status !== 'used' ? 10 : 1,
        userSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        ...getAnimationStyle(),
        ...style
      }}
      onMouseEnter={() => setIsHovered(canHover ? true : false)}
      onMouseLeave={() => {
        setIsHovered(false);
        setIsClicking(false);
      }}
      onClick={handleClick}
    >
      <svg
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: isLarge ? '96px' : '24px',
          height: isLarge ? '96px' : '24px',
          opacity: colors.opacity,
          transition: 'all 0.3s ease',
          transform: `scale(${scale})`,

        }}
      >
        <defs>
          <linearGradient id="heartGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{ stopColor: colors.highlight }} />
            <stop offset="45%" style={{ stopColor: colors.base }} />
            <stop offset="100%" style={{ stopColor: colors.shadow }} />
          </linearGradient>
          <linearGradient id="edgeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: colors.shadow, stopOpacity: 0.5 }} />
            <stop offset="50%" style={{ stopColor: colors.base, stopOpacity: 0 }} />
            <stop offset="100%" style={{ stopColor: colors.shadow, stopOpacity: 0.5 }} />
          </linearGradient>
          <linearGradient id="shineGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: colors.shine, stopOpacity: 0.85 }} />
            <stop offset="25%" style={{ stopColor: colors.shine, stopOpacity: 0 }} />
            <stop offset="100%" style={{ stopColor: colors.shine, stopOpacity: 0 }} />
          </linearGradient>
          <filter id="innerShadow">
            <feOffset dx="0" dy="1" />
            <feGaussianBlur stdDeviation="1" result="offset-blur" />
            <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse" />
            <feFlood floodColor="black" floodOpacity="0.2" result="color" />
            <feComposite operator="in" in="color" in2="inverse" result="shadow" />
            <feComposite operator="over" in="shadow" in2="SourceGraphic" />
          </filter>
        </defs>
        <style>
          {`
            @keyframes scaleFade {
              0% {
                transform: scale(1.5);
                opacity: 1;
              }
              30% {
                transform: scale(1.5);
                opacity: 1;
              }
              60% {
                transform: scale(0.8);
                opacity: 0.5;
              }
              100% {
                transform: scale(0.05);
                opacity: 0;
              }
            }

            @keyframes scaleFadeInHeart {
              0% {
                transform: scale(0.1);
                opacity: 0;
              }
              20% {
                transform: scale(0.8);
                opacity: 1;
              }
              40% {
                transform: scale(2);
                opacity: 1;
              }
              100% {
                transform: scale(1);
                opacity: 1;
              }
            }
          `}
        </style>
        <g filter="url(#innerShadow)">
          <path
            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 
               2 5.42 4.42 3 7.5 3c1.74 0 
               3.41.81 4.5 2.09C13.09 3.81 
               14.76 3 16.5 3 19.58 3 
               22 5.42 22 8.5c0 3.78-3.4 
               6.86-8.55 11.54L12 21.35z"
            fill="url(#heartGradient)"
            stroke={colors.stroke}
            strokeWidth={isHovered && !isMaxed && status !== 'used' ? '0.5' : '0.05'}
          />
          <path
            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 
               2 5.42 4.42 3 7.5 3c1.74 0 
               3.41.81 4.5 2.09C13.09 3.81 
               14.76 3 16.5 3 19.58 3 
               22 5.42 22 8.5c0 3.78-3.4 
               6.86-8.55 11.54L12 21.35z"
            fill="url(#edgeGradient)"
            strokeWidth="0"
          />
          <path
            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 
               2 5.42 4.42 3 7.5 3c1.74 0 
               3.41.81 4.5 2.09C13.09 3.81 
               14.76 3 16.5 3 19.58 3 
               22 5.42 22 8.5c0 3.78-3.4 
               6.86-8.55 11.54L12 21.35z"
            fill="url(#shineGradient)"
            strokeWidth="0"
          />
        </g>
      </svg>
    </div>
  );
};

export default Heart;