import React, { useState, useEffect, useRef } from 'react';

const ImageUploader = ({ onUpload }) => {
    const [url, setUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const uploaderRef = useRef(null);

    const MAX_SIZE = 2000; // Maximum size for the largest dimension
    const MIN_SIZE = 1080; // Minimum size for the smallest dimension

    // Function to resize the image
    const resizeImage = (src, callback) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = () => {
            let newWidth = img.width;
            let newHeight = img.height;

            // Scale up if the image's smallest dimension is less than MIN_SIZE
            if (newWidth < newHeight && newWidth < MIN_SIZE) {
                newHeight *= MIN_SIZE / newWidth;
                newWidth = MIN_SIZE;
            } else if (newHeight < MIN_SIZE) {
                newWidth *= MIN_SIZE / newHeight;
                newHeight = MIN_SIZE;
            }

            // Scale down if the image exceeds MAX_SIZE
            if (newWidth > newHeight && newWidth > MAX_SIZE) {
                newHeight *= MAX_SIZE / newWidth;
                newWidth = MAX_SIZE;
            } else if (newHeight > MAX_SIZE) {
                newWidth *= MAX_SIZE / newHeight;
                newHeight = MAX_SIZE;
            }

            // Round the new dimensions to integers
            newWidth = Math.round(newWidth);
            newHeight = Math.round(newHeight);

            // Create canvas and resize image
            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext('2d');
            
            // Use the best image smoothing quality
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';

            // Draw the image onto the canvas, filling it completely
            ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
            
            // Get the resized image as a data URL, preserving transparency
            const resizedDataUrl = canvas.toDataURL('image/png', 1.0); // Using PNG with 100% quality
            
          
            
            callback(resizedDataUrl, { width: newWidth, height: newHeight });
        };
        img.src = src;
    };

    // Function to load and resize the image
    const loadImage = (src) => {
        resizeImage(src, (resizedSrc, dimensions) => {
            onUpload(resizedSrc, dimensions);
        });
    };

    // Handle image upload via file input
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                loadImage(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setFileName('');
        }
    };

    // Handle image upload via URL
    const handleUrlUpload = () => {
        if (isValidImageUrl(url)) {
            loadImage(url);
        }
    };

    // Validate image URL
    const isValidImageUrl = (url) => {
        const validExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.tif', '.bmp'];
        return validExtensions.some(ext => url.toLowerCase().endsWith(ext));
    };

    // Handle paste events
    const handlePaste = (event) => {
        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf("image") !== -1) {
                const blob = items[i].getAsFile();
                const reader = new FileReader();
                reader.onload = function(evt) {
                    loadImage(evt.target.result);
                };
                reader.readAsDataURL(blob);
                event.preventDefault(); // Prevent the default paste action
                break; // Only handle the first image found
            }
        }
    };

    // Add event listener for paste when component mounts
    useEffect(() => {
        const currentRef = uploaderRef.current;
        if (currentRef) {
            currentRef.addEventListener('paste', handlePaste);
        }
        // Cleanup event listener on unmount
        return () => {
            if (currentRef) {
                currentRef.removeEventListener('paste', handlePaste);
            }
        };
    }, []);

    return (
        <div 
            className='uploaderholder' 
            ref={uploaderRef} 
            tabIndex={0} // Make the div focusable to capture paste events
            style={{ outline: 'none' }} // Remove default focus outline
        >
          
            <span 
                className='titletext small goldtext' 
                style={{ textAlign: 'center', lineHeight: 'normal' }}
            >
                The more FER, the better.
            </span>< br/>
            <span className='textmedium filvertext' style={{textAlign:'center'}}>CTRL/CMD + V Copied image
            <br />
           or
           </span>
            <input 
                type="file" 
                accept="image/*" 
                id="file" 
                className="inputfile" 
                onChange={handleImageUpload} 
                style={{ display: 'none' }} // Hide the default file input
            />
            
            <label 
                htmlFor="file" 
                className='editorbutn'  
                style={{ textAlign: 'center',  cursor: 'pointer' }}
            >
                {fileName || (
                    <>
                        <span>Choofe image<br /> 
                          
                        </span>
                    </>
                )}
            </label>
            
            <i className='textmediumsmall filvertext' style={{textAlign:'center'}}>
                <br />
                {'MorFER if the FERfect tool to recruit new memberf! However, with great power comef great refponfibility.'}<br /> {'A FER will affume a FERTain level of felf diftanfe, but your intefon muft never be to hurt anyone.'}<br />{'Have fun, Fer'}
            </i>
            
            {/* Optional: Provide instructions for pasting */}
     
        </div>
    );
};

export default ImageUploader;
