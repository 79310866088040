import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const EditorToolbar = ({ 
    canvas, 
    activeLayer, 
    setActiveLayer, 
    addText, 
    deleteSelected,
    selectedText,
    textContent,
    setTextContent,
    textFontSize,
    setTextFontSize,
    textColor,
    setTextColor,
    textFontFamily,
    setTextFontFamily,
    addImageToCanvas,
    morferBrushSize,
    setMorferBrushSize,
    paintBrushSize,
    setPaintBrushSize,
    smoothing,
    setSmoothing,
    brushColor,
    setBrushColor,
    paintEnabled,
    setPaintEnabled,
    libraryData,
    setIsLibraryModalOpen,
    setSelectedCategory,
    strength,
    setStrength,
    handleUndo,  
    handleRedo,
    activeButton,
    setActiveButton,
    disabled,
    userTier,
}) => {

    const handleStrengthChange = (e) => {
        const newStrength = Number(e.target.value);
        setStrength(newStrength);
    };

    function HandleAddImageToCanvas (src) {
        addImageToCanvas(src)
        setActiveButton('select')
    }

    const activateMorfer = () => {
        setActiveLayer('liquify');
        setPaintEnabled(false);
        setActiveButton('morfer');
        if (canvas) {
            canvas.isDrawingMode = false;
            canvas.discardActiveObject();
            canvas.renderAll();
        }
    };

    const enableBrush = () => {
        setActiveLayer('fabric');
        setPaintEnabled(true);
        setActiveButton('paint');
        if (canvas) {
            canvas.isDrawingMode = true;
            canvas.freeDrawingBrush.width = paintBrushSize;
            canvas.freeDrawingBrush.color = brushColor;
            canvas.renderAll();
        }
    };

    const selectTool = () => {
        disableBrush();
        setActiveButton('select');
    };

    const disableBrush = () => {
        setActiveLayer('fabric');
        setPaintEnabled(false);
        if (canvas) {
            canvas.isDrawingMode = false;
            canvas.renderAll();
        }
    };

    const handleAddText = () => {
        addText();
        setActiveButton('text');
        setPaintEnabled(false);
        if (canvas) {
            canvas.isDrawingMode = false;
            canvas.renderAll();
        }
    };

    const handleMorferBrushSizeChange = (e) => {
        const newSize = Number(e.target.value);
        setMorferBrushSize(newSize);
    };

    useEffect(() => {
        if (canvas && activeLayer === 'fabric') {
            canvas.isDrawingMode = paintEnabled;
            if (paintEnabled) {
                canvas.freeDrawingBrush.width = paintBrushSize;
                canvas.freeDrawingBrush.color = brushColor;
            }
            canvas.renderAll();
        }
    }, [canvas, activeLayer, paintEnabled, paintBrushSize, brushColor]);

    useEffect(() => {
        if (!paintEnabled && activeLayer === 'fabric') {
            disableBrush();
        }
    }, [paintEnabled, activeLayer]);

    const handleTextChange = (e) => {
        const newText = e.target.value;
        setTextContent(newText);
        if (selectedText) {
            selectedText.set('text', newText);
            canvas.renderAll();
        }
    };

    const handleFontSizeChange = (e) => {
        const newSize = parseInt(e.target.value, 10);
        setTextFontSize(newSize);
        if (selectedText) {
            selectedText.set('fontSize', newSize);
            canvas.renderAll();
        }
    };

    const handleTextColorChange = (e) => {
        const newColor = e.target.value;
        setTextColor(newColor);
        if (selectedText) {
            selectedText.set('fill', newColor);
            canvas.renderAll();
        }
    };

    const handleFontFamilyChange = (e) => {
        const newFontFamily = e.target.value;
        setTextFontFamily(newFontFamily);
        if (selectedText) {
            selectedText.set('fontFamily', newFontFamily);
            canvas.renderAll();
        }
    };

    const handleBrushColorChange = (e) => {
        const newColor = e.target.value;
        setBrushColor(newColor);
        if (canvas && canvas.isDrawingMode) {
            canvas.freeDrawingBrush.color = newColor;
        }
    };

    const getFilenameWithoutExtension = (path) => {
        const parts = path.split('/');
        const filename = parts[parts.length - 1];
        return filename.split('.')[0];
    };

    EditorToolbar.propTypes = {
        // ... other prop types
        libraryData: PropTypes.object.isRequired,
        setIsLibraryModalOpen: PropTypes.func.isRequired,
        setSelectedCategory: PropTypes.func.isRequired,  
    };

    return (
        <>
            {/* Select Tool Category */}
            
                <button 
                    className={`toolbarbutn ${activeButton === 'select' ? 'active' : ''}`} 
                    onClick={selectTool}
                    disabled={disabled}
                >
                    <img 
                        src={process.env.PUBLIC_URL +'/felect.png'}
                        alt="Select Icon" 
                        className='toolbarbutn-image'
                    />
                </button>
                <div className={`buttoncategory ${['morfer'].includes(activeButton) ? 'active' : ''}`}>

                <button 
                    className={`toolbarbutn morfer ${activeButton === 'morfer' ? 'active' : ''}`} 
                    onClick={activateMorfer}
                    disabled={disabled}
                >
                    <img 
                        src={process.env.PUBLIC_URL +'/morfer.png'}
                        alt="Morfer Icon" 
                        className='toolbarbutn-image'
                    />
                </button>

                {activeLayer === 'liquify' && (
                    <>
                   
                        <div className="brush-controls">
                        <span className='textmediumsmall goldtext'>fife</span>
                            <input
                                type="range"
                                id="morferBrushSize"
                                name="morferBrushSize"
                                min="10"
                                max="700"
                                value={morferBrushSize}
                                onChange={handleMorferBrushSizeChange}
                                className="toolbar-slider"
                                disabled={disabled}
                            />
                        </div> 
                        <div className="brush-controls">
                        <span className='textmediumsmall goldtext'>hardneff</span>
                            <input
                                type="range"
                                id="strength"
                                name="strength"
                                min="0.0012"
                                max="0.007"
                                step="0.0002"
                                value={strength}
                                onChange={handleStrengthChange}
                                className="toolbar-slider"
                                disabled={disabled}
                            />
                        </div>
                        <div className="action-buttons">
                        <button className="toolbarbutn" onClick={handleRedo}>Redo</button>
                            <button className="toolbarbutn" onClick={handleUndo}>Undo</button>
                            
                        </div>
                    </>
                )}
            </div>

            {/* Paint Tool Category */}
            <div className={`buttoncategory ${activeButton === 'paint' ? 'active' : ''}`}>
                <button 
                    className={`toolbarbutn ${activeButton === 'paint' ? 'active' : ''}`}  
                    onClick={enableBrush}
                    disabled={disabled}
                >
                    <img 
                        src={process.env.PUBLIC_URL +'/paint.png'}
                        alt="Paint Icon" 
                        className='toolbarbutn-image'
                    />
                </button>

                {activeLayer === 'fabric' && paintEnabled && (
                    <div className="brush-controls">
                        <input
                            type="range"
                            id="paintBrushSize"
                            name="paintBrushSize"
                            min="1"
                            max="100"
                            value={paintBrushSize}
                            disabled={disabled}
                            onChange={(e) => {
                                const newSize = Number(e.target.value);
                                setPaintBrushSize(newSize);
                                if (canvas && canvas.isDrawingMode) {
                                    canvas.freeDrawingBrush.width = newSize;
                                }
                            }}
                            className="toolbar-slider"
                        />
                      
                        <input
                            type="color"
                            id="brushColor"
                            name="brushColor"
                            value={brushColor}
                            onChange={handleBrushColorChange}
                            className="toolbar-color-picker"
                            disabled={disabled}
                        />
                    </div>
                )}
            </div>

            {/* Text Tool Category */}
            <div className={`buttoncategory ${activeButton === 'text' ? 'active' : ''}`}>
                <button 
                    className={`toolbarbutn ${activeButton === 'text' ? 'active' : ''}`}  
                    onClick={handleAddText}
                    disabled={disabled}
                >
                    <img 
                        src={process.env.PUBLIC_URL +'/tekft.png'}
                        alt="Text Icon" 
                        className='toolbarbutn-image'
                    />
                </button>

                {selectedText && (
                    <div className="brush-controls">
                 
                            <input
                                className='toolbar-color-picker'
                                type="color"
                                value={textColor}
                                onChange={handleTextColorChange}
                                disabled={disabled}
                            />
                       
                    </div>
                )}
            </div>
        </>
    );
};

export default EditorToolbar;
