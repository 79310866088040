// TokenomicsChart.jsx
import React, { useState, useEffect, useContext, useRef } from 'react'; // Added useRef
import { ethers } from 'ethers';
import { WalletContext } from '../WalletContext';
//import { TokenomicsManagerAddress } from '../contractAddresses';
import PresaleProgress from './PresaleProgressBar'

import { formatAmount } from '../utils/formatUtils';

import PieChart from './PieChart';

const TokenomicsChart = () => {
    const { isAuthenticated, contracts } = useContext(WalletContext);
    const [constantTokenomics, setConstantTokenomics] = useState({});
    const [dynamicValues, setDynamicValues] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [mode, setMode] = useState('initial');
    const [currentStep, setCurrentStep] = useState(0);
    const [highlightedSlices, setHighlightedSlices] = useState([]);
    const [hoveredItems, setHoveredItems] = useState([]);
    const [message, setMessage] = useState('');
    const [totalTokenomics, setTotalTokenomics] = useState({});
    const [totalTokenSupply, setTotalTokenSupply] = useState({});

    // Add a ref for the hoverLeave timer
    const hoverLeaveTimer = useRef(null);

    useEffect(() => {
        if (isAuthenticated) {
            fetchPresaleData();
        }
        // Cleanup timer on unmount
        return () => {
            if (hoverLeaveTimer.current) {
                clearTimeout(hoverLeaveTimer.current);
            }
        };
    }, [isAuthenticated]);

    const fetchPresaleData = async () => {
        setIsLoading(true);
        try {
            // Fetching constant tokenomics
            const constantData = await contracts.tokenomicsManagerContract.getConstantTokenomics();
            const totalTokenSupplyBigInt = await contracts.coreERC20Contract.totalSupply();
            const totalSupply = Number(ethers.formatUnits(totalTokenSupplyBigInt, 18));
            setTotalTokenSupply(totalSupply);
            setConstantTokenomics({
                initialRate: constantData[0].toString(),
                ethLPPercent: constantData[1].toString(),
                ethMarketingPercent: constantData[2].toString(),
                ethFoundersPercent: constantData[3].toString(),
                memeLPPercent: constantData[4].toString(),
                memeVotingPercent: constantData[5].toString(),
                memeCexListingPercent: constantData[6].toString(),
                memeMarketingPercent: constantData[7].toString(),
                memeFoundersPercent: constantData[8].toString(),
                priceIncreasePercent: constantData[9].toString(),
                lpMemeRatio: constantData[10].toString()
            });

            // Fetching dynamic values
            const dynamicData = await contracts.presaleManagerContract.getDynamicValues();
            setDynamicValues({
                ethRaised: ethers.formatEther(dynamicData[0]),
                tokensIssued: ethers.formatEther(dynamicData[1]),
                ethLP: ethers.formatEther(dynamicData[2]),
                ethMarketing: ethers.formatEther(dynamicData[3]),
                ethFounders: ethers.formatEther(dynamicData[4]),
                memeProjectedLP: ethers.formatEther(dynamicData[5]),
                memeVoting: ethers.formatEther(dynamicData[6]),
                memeCexListing: ethers.formatEther(dynamicData[7]),
                memeMarketing: ethers.formatEther(dynamicData[8]),
                memeFounders: ethers.formatEther(dynamicData[9]),
                UnclaimedVestingMeme: ethers.formatEther(dynamicData[10])
            });

            const presaleAllocationPercent = 40; // Presale is 40% of total supply

            setTotalTokenomics({
                presalePercent: presaleAllocationPercent,
                memeLPPercent: presaleAllocationPercent * parseFloat(constantData[4]) / 100,
                memeVotingPercent: presaleAllocationPercent * parseFloat(constantData[5]) / 100,
                memeCexListingPercent: presaleAllocationPercent * parseFloat(constantData[6]) / 100,
                memeMarketingPercent: presaleAllocationPercent * parseFloat(constantData[7]) / 100,
                memeFoundersPercent: presaleAllocationPercent * parseFloat(constantData[8]) / 100,
                otherAllocationsPercent: 100 - presaleAllocationPercent
            });

            // Existing code to fetch dynamicValues...
        } catch (error) {
            console.error('Failed to fetch Presale data:', error);
        } finally {
            setIsLoading(false);
        }
    }
    const colors = {
        fergiving: '#f45188',    // Pink from site's color scheme
        Prefale: '#ffdd00',      // Yellow from site
        lp: '#4db431',          // Green from site
        marketing: '#FFA07A',    // Light coral (marketing color from site)
        Founderf: '#36A2EB',     // Blue from site
        cexListing: '#9966FF'    // Grayish purple from site
    };
    
    const ethData = [
        { label: 'ETH LP', value: parseFloat(dynamicValues.ethLP), color: colors.lp },
        { label: 'Marketing', value: parseFloat(dynamicValues.ethMarketing), color: colors.marketing },
        { label: 'Founderf', value: parseFloat(dynamicValues.ethFounders), color: colors.Founderf },
    ];
    
    const memeData = [
        { label: 'FERGiving', value: parseFloat(dynamicValues.memeVoting), color: colors.fergiving },
        { label: 'CEKF Lifting', value: parseFloat(dynamicValues.memeCexListing), color: colors.cexListing },
        { label: 'Marketing', value: parseFloat(dynamicValues.memeMarketing), color: colors.marketing },
        { label: 'Founderf', value: parseFloat(dynamicValues.memeFounders), color: colors.Founderf },
        { label: 'Prefale', value: parseFloat(dynamicValues.tokensIssued), color: colors.Prefale },
        { label: 'FER LP', value: parseFloat(dynamicValues.memeProjectedLP), color: colors.lp },
    ];

    const steps = [
        {
            title: "Overview",
            contents: [
                {
                    speech: "Welcome to the $FER tokenomif overview! Let'f go through the baficf quickly.",
                    highlight: []
                },
                {
                    speech: "During the Memberfip Prefale, Memberfipf are purfafed with ETH. By purfafing a Memberfip during prefale, the $FER tokenf are given for FREE with the purfafe! Memberfipf are FOULBOUND and CANNOT be tranferred, fold or deleted. ORDER OF FER if for life.",
                    highlight: [{ chart: 'eth', slice: 'ETH LP' }]
                },
              
                {
                    speech: "{constantTokenomics.ethMarketingPercent}% of the ETH raifed during Memberfip Prefale. For ekfample; liftingf on Coinmarketcap, Competifon, Eventf, Fofial Media Advertifment, Hofting of fer.meme and fo on.",
                    highlight: [{ chart: 'eth', slice: 'Marketing' }]
                },
                {
                    speech: "The remaining {constantTokenomics.ethFoundersPercent}% of ETH if allocated to the founderf, who haf tireleffly haf been building everything FER for almoft a year.",
                    highlight: [{ chart: 'eth', slice: 'Founderf' }]
                },
                {
                    speech: "Now let'f look at how FER tokenf are diftributed. The large'f porfon if diftributed during the Prefale, accounting for {totalTokenomics.presalePercent}% of the total fupply. However, 50% of thefe free tokenf are vefted for a period of time.",
                    highlight: [{ chart: 'fer', slice: 'Prefale' }]
                },
                {
                    speech: "{totalTokenomics.memeLPPercent}% of FER tokenf will be locked up in the liquidity pool, together with the ETH LP allocation. Thif amount and ratio if great to allow the prife to move fteadily.",
                    highlight: [{ chart: 'fer', slice: 'FER LP' }]
                },

                {
                    speech: "Ekfangef are picky. They Often want a lot of free tokenf and even a fee to lift the token. Therefore we've referved {totalTokenomics.memeCexListingPercent}% for all potential lifting cekf liftingf.",
                    highlight: [{ chart: 'fer', slice: 'CEKF Lifting' }]
                },
                {
                    speech: "{totalTokenomics.memeVotingPercent}% of tokenf are allocated to the FERGiving fyftem. The FERGiving fyftem allowf Memberf to earn rewardf while rewarding other Memberf for being a good Fer. Fer ekfample, recruiting many new ORDER OF FER memberf or contributing to the growth in other wayf.",
                    highlight: [{ chart: 'fer', slice: 'FERGiving' }]
                },
                {
                    speech: "{totalTokenomics.memeMarketingPercent}% Thif porfion of $FER, memberf in the community can help defide what marketing ftrategy if beft. Airdrop other communitief? Competition? Burn? We all defide together!",
                    highlight: [{ chart: 'fer', slice: 'Marketing' }]
                },
                {
                    speech: "Laftly, to even further align the infentivef having ORDER OF FER take over the world, the founding team if refeiving {totalTokenomics.memeFoundersPercent}% of the $FER tokenf.",
                    highlight: [{ chart: 'fer', slice: 'Founderf' }]
                }
            ]
        }
    ];

    const evaluateSpeech = (speech) => {
        if (!speech) return '';
        return speech.replace(/\{(.+?)\}/g, (_, expr) => {
            try {
                // Create a context with constantTokenomics, dynamicValues, and totalTokenomics
                const context = { constantTokenomics, dynamicValues, totalTokenomics };
                
                // Safely access the nested property using the expression
                const value = expr.split('.').reduce((acc, part) => acc && acc[part], context);
                
                return value !== undefined ? value : `{${expr}}`;
            } catch (error) {
                console.error('Error evaluating expression:', expr, error);
                return '{ERROR}';
            }
        });
    };
    
    const handleHover = (item) => {
        if (item) { // mouseEnter
            // If there's a pending hoverLeaveTimer, clear it
            if (hoverLeaveTimer.current) {
                clearTimeout(hoverLeaveTimer.current);
                hoverLeaveTimer.current = null;
            }

            if (mode !== 'guided') {
                let newHoveredItems = [];
                let newHighlightedSlices = [];
                
                if (item === 'ETH LP' || item === 'FER LP') {
                    newHoveredItems = ['ETH LP', 'FER LP'];
                    newHighlightedSlices = ['ETH LP', 'FER LP'];
                } else if (item === 'Marketing') {
                    newHoveredItems = ['Marketing'];
                    newHighlightedSlices = ['Marketing'];
                } else if (item === 'Founderf') {
                    newHoveredItems = ['Founderf'];
                    newHighlightedSlices = ['Founderf'];
                } else if (item === 'FERGiving') {
                    newHoveredItems = ['FERGiving'];
                    newHighlightedSlices = ['FERGiving'];
                } else if (item === 'CEKF Lifting') {
                    newHoveredItems = ['CEKF Lifting'];
                    newHighlightedSlices = ['CEKF Lifting'];
                } else if (item === 'Prefale') {
                    newHoveredItems = ['Prefale'];
                    newHighlightedSlices = ['Prefale'];
                } else {
                    newHoveredItems = [item];
                    newHighlightedSlices = [item];
                }

                setHoveredItems(newHoveredItems);
                setHighlightedSlices(newHighlightedSlices);

                if (newHoveredItems.length > 0) {
                    const stepIndex = steps[0].contents.findIndex(step => 
                        step.highlight && step.highlight.some(h => newHighlightedSlices.includes(h.slice))
                    );
                    if (stepIndex !== -1) {
                        setMessage(evaluateSpeech(steps[0].contents[stepIndex].speech));
                    }
                } else {
                    setMessage('');
                }
            }
        } else { // mouseLeave
            // Set a timeout to clear hover state after 500ms
            hoverLeaveTimer.current = setTimeout(() => {
                setHoveredItems([]);
                setHighlightedSlices([]);
                setMessage('');
                hoverLeaveTimer.current = null;
            }, 500); // 500ms cooldown
        }
    };

    const renderContent = () => {
        if (isLoading) return <p>Loading...</p>;
    
        const currentStepData = steps[0].contents[currentStep];
        const currentHighlights = mode === 'guided' && currentStepData ? currentStepData.highlight : [];
    
        return (
            <>
                {mode !== 'guided' && (
                    <div className='tokenomifchat left'>
                        {hoveredItems.length > 0 ? (
                            <p>{message}</p>
                        ) : (
                            <>
                                <button className="butn mini green" onClick={() => setMode('guided')}>Yef, guide me pleaf</button>
                                <p>Need a guide, Fer?</p>
                            </>
                        )}
                        {mode === 'self-explore' && (
                            <button className="butn mini red close" onClick={() => setMode('initial')}>X</button>
                        )}
                    </div>
                )}

                {mode === 'guided' && currentStepData && (
                    <div className='tokenomifchat left bounce'>
                        <div className="navigation-buttons">
                            <button 
                                className="butn mini blue" 
                                onClick={() => setCurrentStep(prev => Math.max(0, prev - 1))}
                                disabled={currentStep === 0}
                            >
                                Back
                            </button>
                            <button 
                                className="butn mini green" 
                                onClick={() => setCurrentStep(prev => Math.min(steps[0].contents.length - 1, prev + 1))}
                                disabled={currentStep === steps[0].contents.length - 1}
                            >
                                Next
                            </button>
                        </div>
                        <p>{evaluateSpeech(currentStepData.speech)}</p>
                        <button className="butn mini red close" onClick={() => setMode('initial')}>X</button>
                    </div>
                )}

                {/* Removed the numbers-view section */}

                <div className="charts-view">
                    <div className="chart-container">
                        <PieChart 
                            data={ethData} 
                            title="$ETH" 
                            highlightedSlices={mode === 'guided' ? currentHighlights.filter(h => h.chart === 'eth').map(h => h.slice) : highlightedSlices}
                            setHighlightedSlices={setHighlightedSlices}
                            onSliceHover={handleHover}
                        />
                    </div>
                    <div className="chart-container">
                        <PieChart 
                            data={memeData} 
                            title="$FER" 
                            highlightedSlices={mode === 'guided' ? currentHighlights.filter(h => h.chart === 'fer').map(h => h.slice) : highlightedSlices}
                            setHighlightedSlices={setHighlightedSlices}
                            onSliceHover={handleHover}
                        />
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="tokenomics-chart">
            <div className="self-explore">
                { !isLoading &&
            <PresaleProgress
          dynamicValues={dynamicValues}
          constantTokenomics={constantTokenomics}
          totalTokenSupply={totalTokenSupply} // Pass it here
        />}
                {renderContent()}
                
            </div>
        </div>
    );
};

export default TokenomicsChart; 
