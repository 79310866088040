import React, { useState, useEffect, useContext } from 'react';
import { WalletContext } from '../WalletContext';
import { DataContext } from '../DataProvider';

// Import the curtain images
import Curtain1 from '../Curtain__0000.png';
import Curtain2 from '../Curtain__0001.png';
import Curtain3 from '../Curtain__0002.png';
import Curtain4 from '../Curtain__0003.png';
import Curtain5 from '../Curtain__0004.png';
import Curtain6 from '../Curtain__0005.png';
import Curtain7 from '../Curtain__0006.png';
import Curtain8 from '../Curtain__0007.png';
import Curtain9 from '../Curtain__0008.png';
import Curtain10 from '../Curtain__0009.png';
import Curtain11 from '../Curtain__0010.png';
import Curtain12 from '../Curtain__0011.png';
import Curtain13 from '../Curtain__0012.png';
import Curtain14 from '../Curtain__0013.png';
import Curtain15 from '../Curtain__0014.png';
import Curtain16 from '../Curtain__0015.png';
import Curtain17 from '../Curtain__0016.png';
import Curtain18 from '../Curtain__0017.png';



const CurtainComponent = ({ startAnimation, standby }) => {
  const {  contractsReady, isAuthenticated } = useContext(WalletContext);
  const { isCurtainAnimated, setIsCurtainAnimated } = useContext(DataContext);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isVisible, setIsVisible] = useState(!isCurtainAnimated);
  
    useEffect(() => {
        if (isAuthenticated && !isCurtainAnimated && contractsReady && isAuthenticated && startAnimation && !standby) {
          setIsAnimating(true);
          setTimeout(() => {
            setIsAnimating(false);
            setIsCurtainAnimated(true);
            setIsVisible(false);
          }, 4000); // 5 seconds for the animation
        }
      }, [isAuthenticated, isCurtainAnimated, setIsCurtainAnimated, contractsReady, isAuthenticated, startAnimation]);
  
    if (!isVisible && !standby) {
      return null;
    }
  
    const leftCurtains = [
      Curtain1, Curtain2, Curtain3, Curtain4, Curtain5, Curtain6, 
      Curtain7, Curtain8, Curtain9
    ];
      
    const rightCurtains = [
      Curtain10, Curtain11, Curtain12, Curtain13, Curtain14, Curtain15, 
      Curtain16, Curtain17, Curtain18
    ];
  
    const renderCurtain = (curtain, index, isRight) => (
      <div
        className={`curtain-image ${isAnimating ? 'animate' : ''} ${isRight ? 'right' : 'left'}`}
        key={isRight ? index + 8 : index}
        style={{
          backgroundImage: `url(${curtain})`,
          animationDelay: `${isRight ? index * 0.2 : (8 - index) * 0.2}s`,
          animationDuration: '0.3s',
        }}
      />
    );
  
    return (
      <div className='curtain'>
        {leftCurtains.map((curtain, index) => renderCurtain(curtain, index, false))}
        {rightCurtains.map((curtain, index) => renderCurtain(curtain, index, true))}
      </div>
    );
  };
  
  export default CurtainComponent;