// src/utils/imageUtils.js
export const svgToPng = (svgString, maxWidth = 4000, maxHeight = 4000) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
  
        // Calculate the scale factor to fit within maxWidth and maxHeight
        const scale = Math.min(maxWidth / img.width, maxHeight / img.height);
  
        // Set canvas size to the scaled dimensions
        canvas.width = img.width * scale;
        canvas.height = img.height * scale;
  
        // Draw the image at the scaled size
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
        // Get the full-size PNG data URL
        const fullSizePng = canvas.toDataURL('image/png');
  
        // Create a new image for downscaling
        const downscaledImg = new Image();
        downscaledImg.onload = () => {
          const downscaledCanvas = document.createElement('canvas');
          const downscaledCtx = downscaledCanvas.getContext('2d');
  
          downscaledCanvas.width = canvas.width / 3;
          downscaledCanvas.height = canvas.height / 3;
  
          downscaledCtx.drawImage(downscaledImg, 0, 0, downscaledCanvas.width, downscaledCanvas.height);
  
          const downscaledPng = downscaledCanvas.toDataURL('image/png');
          resolve(downscaledPng);
        };
        downscaledImg.src = fullSizePng;
      };
      img.onerror = reject;
      img.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgString);
    });
  };
  