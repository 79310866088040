import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { fabric } from 'fabric';


const FabricCanvas = forwardRef(({ 
    activeLayer, 
    setActiveLayer, 
    triggerDeselect, 
    dimensions, 
    brushSize, 
    setBrushSize, 
    smoothing, 
    setSmoothing,
    setSelectedText,
    setTextContent,
    setTextFontSize,
    setTextColor,
    setTextFontFamily,
    paintBrushSize,
    brushColor,
    paintEnabled,
    onLayersChange,
    onSelectionChange,
}, ref) => {
    const canvasRef = useRef(null);
    const [canvas, setCanvas] = useState(null);

    
    useEffect(() => {
        if (canvas) {
            canvas.setDimensions({ width: dimensions.width, height: dimensions.height });
            canvas.renderAll();
        }
    }, [dimensions, canvas]);

    useEffect(() => {
        if (!canvas) {
            const fabricCanvas = new fabric.Canvas(canvasRef.current, {
                width: dimensions.width,
                height: dimensions.height,
                zIndex: 10
            });
            setCanvas(fabricCanvas);

            fabricCanvas.on('selection:cleared', handleSelectionCleared);
            fabricCanvas.on('selection:created', handleSelection);
            fabricCanvas.on('selection:updated', handleSelection);
        } else {
            const scaleX = dimensions.width / canvas.width;
            const scaleY = dimensions.height / canvas.height;
            canvas.setWidth(dimensions.width);
            canvas.setHeight(dimensions.height);
            canvas.getObjects().forEach(obj => {
                obj.scaleX = obj.scaleX * scaleX;
                obj.scaleY = obj.scaleY * scaleY;
                obj.left = obj.left * scaleX;
                obj.top = obj.top * scaleY;
                obj.setCoords();
            });
            canvas.renderAll();
        }
    }, [dimensions, canvas]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Delete') {
                deleteSelectedObjects(canvas);
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [canvas]);

    useEffect(() => {
        if (canvasRef.current) {
            const canvasWrapper = canvasRef.current.parentNode;
            const lowerCanvas = canvasWrapper.querySelector('.lower-canvas');
            const upperCanvas = canvasWrapper.querySelector('.upper-canvas');

            const pointerEvents = activeLayer === 'fabric' ? 'auto' : 'none';
            canvasWrapper.style.pointerEvents = pointerEvents;
            lowerCanvas.style.pointerEvents = pointerEvents;
            upperCanvas.style.pointerEvents = pointerEvents;
        }
    }, [activeLayer]);

    useEffect(() => {
        if (triggerDeselect && canvas) {
            canvas.discardActiveObject();
            canvas.renderAll();
        }
    }, [triggerDeselect, canvas]);

    useImperativeHandle(ref, () => ({
        toDataURL: (format) => canvas ? canvas.toDataURL(format) : null,
        resetFabricCanvas: () => {
          if (canvas) {
            canvas.clear();
            setActiveLayer("liquify")
            canvas.renderAll();
          }
        },
        getCanvas: () => canvas,
        updateDimensions: (newDimensions) => {
            if (canvas) {
              const scaleX = newDimensions.width / canvas.width;
              const scaleY = newDimensions.height / canvas.height;
              
              canvas.setDimensions(newDimensions);
              
              canvas.getObjects().forEach(obj => {
                obj.scaleX *= scaleX;
                obj.scaleY *= scaleY;
                obj.left *= scaleX;
                obj.top *= scaleY;
                obj.setCoords();
              });
              
              canvas.renderAll();
            }
          },
          selectObjects: (fabricObjects) => {
            if (canvas) {
                canvas.discardActiveObject();
                if (fabricObjects.length === 1) {
                    canvas.setActiveObject(fabricObjects[0]);
                } else if (fabricObjects.length > 1) {
                    const activeSelection = new fabric.ActiveSelection(fabricObjects, { canvas: canvas });
                    canvas.setActiveObject(activeSelection);
                }
                canvas.requestRenderAll();
            }
        },
        toggleObjectVisibility: (fabricObject) => {
            if (canvas) {
                fabricObject.visible = !fabricObject.visible;
                canvas.renderAll();
                updateLayers();
            }
        },
        deleteObjects: (fabricObjects) => {
            if (canvas) {
                fabricObjects.forEach(obj => canvas.remove(obj));
                canvas.discardActiveObject();
                canvas.renderAll();
                updateLayers();
            }
        },
        loadNewBaseImage: (imageUrl) => {
            if (canvas) {
                fabric.Image.fromURL(imageUrl, (img) => {
                    canvas.clear();
                    img.scaleToWidth(canvas.width);
                    canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
                });
            }
        },
    }));


    const handleSelection = (e) => {
        const activeObject = e.selected[0];
        if (activeObject) {
            if (activeObject.type === 'i-text') {
                setSelectedText(activeObject);
                setTextContent(activeObject.text);
                setTextFontSize(activeObject.fontSize);
                setTextColor(activeObject.fill);
                setTextFontFamily(activeObject.fontFamily);
            } else {
                setSelectedText(null);
            }
        } else {
            setSelectedText(null);
        }
    };

    const handleSelectionCleared = () => {
        setSelectedText(null);
        setTextContent('');
        setTextFontSize(24);
        setTextColor('#000000');
        setTextFontFamily('Arial');
    };



    const deleteSelectedObjects = (fabricCanvas) => {
        const activeObject = fabricCanvas.getActiveObject();
        if (activeObject) {
            if (activeObject.type === 'activeSelection') {
                activeObject.forEachObject((obj) => {
                    fabricCanvas.remove(obj);
                });
                fabricCanvas.discardActiveObject();
            } else {
                fabricCanvas.remove(activeObject);
            }
        }
    };

    const getFilenameWithoutExtension = (path) => {
        const parts = path.split('/');
        const filename = parts[parts.length - 1];
        return filename.split('.')[0];



    };

    const updateLayers = () => {
        if (canvas) {
            const objects = canvas.getObjects();
            const layersList = objects.map((obj) => ({
                fabricObject: obj,
                type: obj.type,
                name: obj.name || `${obj.type} ${canvas.getObjects().indexOf(obj) + 1}`,
                visible: obj.visible !== false,
                category: obj.category // Add this line
            }));
            onLayersChange(layersList);
        }
    };

    const updateSelection = () => {
        if (canvas) {
            const activeObjects = canvas.getActiveObjects();
            onSelectionChange(activeObjects);
        }
    };


    useEffect(() => {
        if (canvas) {
            canvas.on('object:added', updateLayers);
            canvas.on('object:removed', updateLayers);
            canvas.on('object:modified', updateLayers);
            canvas.on('selection:created', updateSelection);
            canvas.on('selection:updated', updateSelection);
            canvas.on('selection:cleared', updateSelection);

            updateLayers(); // Initial update

            return () => {
                canvas.off('object:added', updateLayers);
                canvas.off('object:removed', updateLayers);
                canvas.off('object:modified', updateLayers);
                canvas.off('selection:created', updateSelection);
                canvas.off('selection:updated', updateSelection);
                canvas.off('selection:cleared', updateSelection);
            };
        }
    }, [canvas, onLayersChange, onSelectionChange]);

    

    return (
        <div className="editor-container" style={{ width: '100%', height: '100%' }}>
            <div
                className={`canvas-container ${activeLayer === 'fabric' ? 'fabric-active' : ''}`}
                style={{
                    width: dimensions.width,
                    height: dimensions.height,
                    position: 'absolute',
                    top: 0,
                    left: 0
                }}
            >
                <canvas
                    ref={canvasRef}
                    className='uppercanvas'
                    style={{
                        display: 'block',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                     
                        pointerEvents: activeLayer === 'fabric' ? 'auto' : 'none'
                    }}
                />
            </div>
        </div>
    );
});

export default FabricCanvas;
