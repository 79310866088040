import React from 'react';
import PropTypes from 'prop-types';
import { shortenAddress, formatEther, formatAmount, FERIcon, applyFerSpeak } from '../../utils/formatUtils';
import Heart from './Heart';
import './UserSectionCSS.css';
import Hourglass from './Hourglass';

const UserSection = ({
  maxSelectablePoints,
  pointsToAllocate,
  isProcessing,
  votesPerName = [],
  isLoadingVotesReceived,
  blacklistStatus,
  estimatedRewards = [],
  isClaimingRewards,
  handleClaimAllRewards,
  votingHistory = [],
  isLoadingVotingHistory,
  selectedCandidate,
  onSelectCandidate,
  handleAllocatePoints,
  heartsArray = [],
  updateHearts,
  totalPoints,
  usedPoints,
  actualClaimableRewards = [],
  seasons = [],
  votesReceivedPerSeason = {},
  rewardsPerSeason = {},
  votingData = [],
  seasonStatus,
  currentSeason,
  handleClaimReward,
  timeLeft,
  timeLeftPercent,
  inputName,
setInputName,
isNameValid,
isLoadingTotalPoints,
}) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [animatingHearts, setAnimatingHearts] = React.useState([]);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const maxSelected = pointsToAllocate >= maxSelectablePoints;

  const handleHeartClick = (heart) => {
    if (heart.status === 'used') return;
    setAnimatingHearts((prev) => [...prev, heart.id]);
    setTimeout(() => {
      setAnimatingHearts((prev) => prev.filter((id) => id !== heart.id));
      updateHearts(heart.id);
    }, 300);
  };

  const pastSeasons = seasons
  .filter((season) => season.status === 'ended' && season.id < currentSeason)
  .sort((a, b) => b.id - a.id);


  const formatReward = (reward) => {
    if (typeof reward === 'string') {
      return formatEther(reward);
    }
    return '0';
  };

  const renderSeasonRewards = (seasonId) => {
    const seasonVotes = [];
    const seasonVotesData = votesReceivedPerSeason[seasonId] || {};
  
    // Match tokenIds with names from votesPerName
    Object.entries(seasonVotesData).forEach(([tokenId, votes]) => {
      const nameData = votesPerName.find(v => v.tokenId === tokenId);
      if (nameData && Number(votes) > 0) {
        seasonVotes.push({
          name: nameData.name,
          tokenId,
          votes: Number(votes)
        });
      }
    });
  
    const reward = rewardsPerSeason[seasonId];
    const formattedReward = reward ? formatAmount(formatEther(reward.toString())) : '0';
    const isClaimable = actualClaimableRewards.some(
      (reward) => Number(reward.seasonId) === seasonId
    );
  
    // Calculate total votes for this season
    const totalVotes = seasonVotes.reduce((sum, vote) => sum + vote.votes, 0);
  
    // Only show the section if there were any votes
    if (totalVotes === 0) {
      return (
        <div key={seasonId} className="season-section">
          <span className='textmedium'>Feafon {seasonId}</span>
          <div className="textlarge">
            <Heart
              key={'display'}
              status={'available'}
              isMaxed={false}
              isAnimating={false}
              canHover={false}
              pointer={false}
              scale={'1.5'}
              style={{transform:'translateY(-5%)'}}
             
            />
           {<span className='textmedium'> x </span>} {'0 ≈ 0'}<FERIcon style={{transform:'translate(8%, -5%)'}} />
          </div>
          <i className="textmedium graytext">No votes for this season</i>
        </div>
      );
    }
  
    return (
      <div key={seasonId} className="season-section">
        <span className='textmedium'>Feafon {seasonId}</span>
        <div className="textlarge">
          <Heart
            key={'display'}
            status={'available'}
            isMaxed={false}
            isAnimating={false}
            canHover={false}
            pointer={false}
            scale={1.5}
            style={{transform:'translateY(-5%)'}}
          />
          {<span className='textmedium'> x </span>}
          {totalVotes}
          {' ≈ '}
          {formattedReward}<FERIcon style={{transform:'translate(8%, -5%)'}} />
        </div>
        
        
  
        {seasonVotes.length > 0 ? (
  <table className="personal-scoreboard">
    <thead>
      <tr>
        <th>Name</th>
        <th>FERGiven</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {seasonVotes.map((vote) => (
        <tr key={`${seasonId}-${vote.tokenId}`} className="scorerow">
          <td className='textmedium'>{vote.name}</td>
          <td className='textmedium votesrow'> <Heart
            key={'display'}
            status={'available'}
            isMaxed={false}
            isAnimating={false}
            canHover={false}
            pointer={false}
          />x{vote.votes}</td>
          <td>
            {actualClaimableRewards.some(
              reward => reward.seasonId === seasonId && 
                       reward.nameTokenId === vote.tokenId
            ) ? (
              <button
                className="butn claimfergiving"
                onClick={() => handleClaimReward(seasonId, vote.tokenId)}
                disabled={isClaimingRewards}
              >
                {isClaimingRewards ? 'Claiming...' : 'Claim'}
              </button>
            ) : (
              <span  className='textmedium'>Claimed</span>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
) : ''}
      </div>
    );
  };

  return (
    <div className="user-voting-section">
      <div className="user-voting-section-tab textmedium">
        <button
          onClick={() => handleTabClick(0)}
          className={`user-voting-section-tab-button fergivetab textmedium ${
            activeTab === 0 ? 'active fergivetab' : ''
          }`}
        >
          FERGive{activeTab ===0 ? ' :' : ''}
        </button>
        <button
          onClick={() => handleTabClick(1)}
          className={`user-voting-section-tab-button textmedium ${
            activeTab === 1 ? 'active' : ''
          }`}
        >
          FERGiven
        </button>
        <button
          onClick={() => handleTabClick(2)}
          className={`user-voting-section-tab-button textmedium ${
            activeTab === 2 ? 'active' : ''
          }`}
        >
          FERGave
        </button>
      </div>

      <div className="user-section-content">
        {activeTab === 0 && (
          <div className="votes-amount-tab">
            <div className='fergiveinputholder' style={{ 
  display: 'flex', 
  alignItems: 'center', 
  padding: '10px',
  marginBottom: '1%',

  backgroundColor: 'rgba(28, 10, 0, 0.42)',

}}>

  <span className="textmedium" style={{ marginRight: '5px' }}>FER</span>
  <input
    type="text"
    value={inputName}
    onChange={(e) => setInputName(applyFerSpeak(e.target.value))}
    disabled={isProcessing || seasonStatus!=='active'}
    placeholder={isProcessing ? "Loading..." : seasonStatus!=='active' ? 'FEAFON NOT ACTIVE' : "ENTER FERFONA"}
    className="textmedium enterfer"
    style={{
      flex: '1 1 0%',
      padding: '8px 12px',
      backgroundColor: 'rgba(179, 179, 179, 0.25)',
      borderRadius: '0.15vw',
      textTransform: 'uppercase',
      outline: 'none',
      border: 'none',
      maxWidth: '98%',
      cursor: seasonStatus!=='active' ? 'not-allowed' : 'text'
 
    }}
  />
  <span style={{ marginLeft: '5px' }}>
    {isNameValid === true
      ? '✅'
      : isNameValid === false
      ? '❌'
      : seasonStatus !=='active' ? '' : '❌'}
  </span>
</div>
            <div className="points-summary">
              <div className="current-allocation">
                <div
                  className="heartstats textmedium"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Heart
                    key={'display'}
                    status={'available'}
                    isMaxed={false}
                    isAnimating={false}
                    canHover={false}
                    pointer={false}
                    
                  />
                  {Math.max(
                    0,
                    Number(totalPoints || 0) - Number(pointsToAllocate || 0) - Number(usedPoints || 0)
                  )}{' '}
                  / {totalPoints || '0'}
                </div>
                {maxSelected && totalPoints > 0 && (
                  <div className="maximum-indicator">
                    <span className="textmediumsmall">Makf 20% per Fer, Fer.</span>
                  </div>
                )}
              </div>

              
            </div>
            <div className="hearts-container">
              {heartsArray.length > 0 ? (
                heartsArray
                  .filter((heart) => !heart.isSelected)
                  .map((heart) => (
                    <Heart
                      key={heart.id}
                      status={heart.status}
                      isMaxed={maxSelected}
                      isAnimating={animatingHearts.includes(heart.id)}
                      canHover={seasonStatus !== 'active' ? false : true}
                      onClick={seasonStatus !== 'active' ? '' : () => handleHeartClick(heart) }
                      style={seasonStatus !== 'active' ? { opacity: 0.4, cursor:'default' } : null}
                      
                    />
                  ))
              ) : (
                <i style={{color:'gray'}} className="textmediumsmall">No <span className='darkgoldtext'>F</span>er<span className='darkgoldtext'>F</span>core, can't FERGive </i>
              )}
            </div>
            <div className="heart-info">
                  {blacklistStatus ? (
                    'You are blacklisted. No negofiafonf.'
                  ) : (
                    <i className="textmediumsmall">x1 <span className='darkgoldtext'>F</span>er<span className='darkgoldtext'>F</span>core = x1     
                    <Heart
                    key={'display'}
                    status={'available'}
                    isMaxed={false}
                    isAnimating={false}
                    canHover={false}
                    scale={0.5}
                    pointer={false}
                    style={{transform:'translate(-15%, 0%)', opacity:'0.8'}}
                  /> </i>
                  )}
                </div>
          </div>
        )}

        {activeTab === 1 && (
          
          <div className="votes-received-tab">
  
            {isLoadingVotesReceived ? (
              <p>Loading votes received...</p>
            ) : (
              <>
              {seasonStatus !== 'not ftarted'&& (
                <div className="current-season-section">
                  <h3 className="textlarge">
                    Feafon {currentSeason}{' '}
                    
                  </h3>
                  <div style={{maxWidth:'10%', position: 'absolute', left:'0'}}>

                  </div>
                  <span className="fergiving goldtext" style={{position: 'relative', lineHeight: '0'}}>
                      <i className='textmedium'>
                        {seasonStatus === 'concluding' ? (
                          <span className='textmediumsmall'>concluding...</span>
                        ) : seasonStatus === 'active' ? (
                          'Active!'
                        ) : (
                          seasonStatus
                        )}
                      </i>
                    </span>
                  {/* Replace the current season voting data display with: */}
                  {(seasonStatus === 'active' || seasonStatus === 'concluding' || seasonStatus === 'ended') && (
                      <>
                        <div className="textlarge">
                        
                          <Heart
                            key={'display'}
                            status={'available'}
                            isMaxed={false}
                            isAnimating={false}
                            canHover={false}
                            scale={1.5}
                            pointer={false}
                            style={{transform:'translateY(-5%)'}}
                          />
                        
                          {<span className='textmedium'> x </span>}
                          {Object.values(votesReceivedPerSeason[currentSeason] || {})
                            .reduce((sum, votes) => sum + Number(votes), 0)}
                          {' ≈ '}
                          <span className='goldtext'>{formatAmount(
                            estimatedRewards.reduce(
                              (sum, detail) => sum + Number(formatEther(detail.reward)),
                              0
                            )
                          )}
                          </span>
                          <FERIcon style={{transform:'translate(8%, -5%)'}} />
                        </div>

                        {Object.values(votesReceivedPerSeason[currentSeason] || {}).length > 0 ? (
                          <table className="personal-scoreboard">
                            <thead>
                              <tr>
                                <th className='textmedium'>Name</th>
                                <th className='textmedium'>FERGiven</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {votesPerName
                                .filter(vote => {
                                  const currentSeasonVotes = votesReceivedPerSeason[currentSeason]?.[vote.tokenId] || '0';
                                  return Number(currentSeasonVotes) > 0;
                                })
                                .map((vote) => (
                                  <tr key={vote.tokenId} className="scorerow">
                                    <td className='textmedium'>{vote.name}</td>
                                    <td className='textmedium'> <Heart
                                          key={'display'}
                                          status={'available'}
                                          isMaxed={false}
                                          isAnimating={false}
                                          canHover={false}
                                          pointer={false}
                                          
                                        />
                                        x{votesReceivedPerSeason[currentSeason]?.[vote.tokenId] || '0'}</td>
                                    <td>
                                      {actualClaimableRewards.some(
                                        reward => reward.nameTokenId === vote.tokenId
                                      ) ? (
                                        <button
                                          className="butn claimfergiving"
                                          onClick={() => handleClaimReward(currentSeason, vote.tokenId)}
                                          disabled={isClaimingRewards || seasonStatus !== 'ended'}
                                        >
                                          {isClaimingRewards ? 'Claiming...' : 'Claim'}
                                        </button>
                                      ) : (
                                        seasonStatus === 'ended' && <span className="claimed-status">Claimed</span>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        ) : (
                          <div className="textmedium graytext">
                            <i className='textmediumsmall'>Not FERGiven thif Feafon</i>
                          </div>
                        )}
                      </>
                    )}
                </div>
              )}
                {pastSeasons.length > 0 ? (
                 <>
                 <div style={{width:'100%', display:'flex', justifyContent: 'center', marginTop:'0.5vw'}}>
                    <span className='textmedium'>Paft Feafonf</span>
                    </div>
                    <div className="past-seasons-section">
                    {pastSeasons.map((season) => renderSeasonRewards(season.id))}
                  </div>
                


                <div className="blacklist-status">
                  {blacklistStatus ? (
                    'You are blacklisted. No negofiafonf.'
                  ) : (
                    <i className="textmediumsmall">Ftatuf: No blacklifting                         
                    <Heart
                    key={'display'}
                    status={'available'}
                    isMaxed={false}
                    isAnimating={false}
                    canHover={false}
                    scale={0.5}
                    pointer={false}
                    style={{transform:'translate(-15%, 0%)', opacity:'0.8'}}
                  /> </i>
                  )}
                  
                </div>
                </>
                ): <i className='textmediumsmall' style={{color:'gray', margin:'1%'}}> Feafon ftarting after prefale </i>}
              </>
            )}
          </div>
        )}

{activeTab === 2 && (
  <div className="voting-history-tab">
    <table className="textmediumsmall">
      <thead>
        <tr>
          <th>Feafon</th>
          <th>FERFona</th>
          <th>FERGave</th>
          <th>Wen</th>
        </tr>
      </thead>
      <tbody>
        {!isLoadingVotingHistory && votingHistory.length > 0 && 
          votingHistory.map((vote) => {
            const nameData = votesPerName.find(v => v.tokenId === vote.nameTokenId);
            const displayName = nameData ? nameData.name : vote.name;
            
            return (
              <tr key={`${vote.seasonId}-${vote.nameTokenId}`}>
                <td className="textmediumsmall">{vote.seasonId}</td>
                <td className="textmediumsmall">{displayName}</td>
                <td className="textmediumsmall">
                  <Heart
                    key={'display'}
                    status={'available'}
                    isMaxed={false}
                    isAnimating={false}
                    canHover={false}
                    scale={0.7}
                    pointer={false}
                  />
                  x{vote.pointsAllocated}
                </td>
                <td className="textmediumsmall">
                  {new Date(vote.timestamp * 1000).toLocaleString()}
                </td>
              </tr>
            );
          })
        }
      </tbody>
    </table>
    {isLoadingVotingHistory && (
      <div className="textmediumsmall">Loading FERGave data..</div>
    )}
    {!isLoadingVotingHistory && votingHistory.length === 0 && (
      <div className="textmediumsmall gray"><i style={{color:'gray', margin:'1%'}}>You did not FERGive anyone yet.</i></div>
    )}
  </div>
)}
      </div>
    </div>
  );
};

export default UserSection;