// contractHelper.js

import { ethers } from 'ethers';
import CoreERC20ABI from './abis/CoreERC20.json';
import MemeNFTABI from './abis/MemeNFT.json';
import PresaleManagerABI from './abis/PresaleManager.json';
import VestingManagerABI from './abis/VestingManager.json';
import VotingManagerABI from './abis/VotingManager.json';
import TokenomicsManagerABI from './abis/TokenomicsManager.json';
import NameNFTABI from './abis/NameNFT.json'
import NameRegistryABI from './abis/NameRegistry.json'
import NameSVGABI from './abis/SVGGenerator.json'
import MasterSVGABI from './abis/MasterSVGCompiler.json'
import AchievementManagerABI from './abis/AchievementManager.json'

import { 
  CoreERC20Address, 
  MemeNFTAddress, 
  PresaleManagerAddress, 
  VestingManagerAddress, 
  TokenomicsManagerAddress,
  VotingManagerAddress,
  NameNFTAddress,
  NameRegistryAddress,
  SVGGeneratorAddress,
  MasterSVGAddress,
  AchievementManagerAddress
} from './contractAddresses';

export const initializeContracts = async (signer) => {
  try {
    const contracts = [
      { name: 'coreERC20Contract', contract: new ethers.Contract(CoreERC20Address, CoreERC20ABI.abi, signer) },
      { name: 'memeNFTContract', contract: new ethers.Contract(MemeNFTAddress, MemeNFTABI.abi, signer) },
      { name: 'presaleManagerContract', contract: new ethers.Contract(PresaleManagerAddress, PresaleManagerABI.abi, signer) },
      { name: 'vestingManagerContract', contract: new ethers.Contract(VestingManagerAddress, VestingManagerABI.abi, signer) },
      { name: 'tokenomicsManagerContract', contract: new ethers.Contract(TokenomicsManagerAddress, TokenomicsManagerABI.abi, signer) },
      { name: 'votingManagerContract', contract: new ethers.Contract(VotingManagerAddress, VotingManagerABI.abi, signer) },
      { name: 'nameNFTContract', contract: new ethers.Contract(NameNFTAddress, NameNFTABI.abi, signer) },
      { name: 'nameRegistryContract', contract: new ethers.Contract(NameRegistryAddress, NameRegistryABI.abi, signer) },
      { name: 'nameSVGContract', contract: new ethers.Contract(SVGGeneratorAddress, NameSVGABI.abi, signer) },
      { name: 'masterSVGContract', contract: new ethers.Contract(MasterSVGAddress, MasterSVGABI.abi, signer) },
      { name: 'achievementManagerContract', contract: new ethers.Contract(AchievementManagerAddress, AchievementManagerABI.abi, signer) }
    ];

    return contracts;
  } catch (error) {
    console.error("Error initializing contracts:", error);
    throw error;
  }
};
