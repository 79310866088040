import React from 'react';
import './HatWithNamesCSS.css';
import Heart from './Heart';
import { applyFerSpeak } from '../../utils/formatUtils';

const HatWithNames = ({ 
  names, 
  selectedCandidate, 
  onSelectCandidate,
  handleAllocatePoints,
  handleClearAllocation,
  isProcessing,
  heartsArray,
  updateHearts,
  seasonStatus,
  inputName,
  setInputName,
  isNameValid,
  pointsToAllocate,
  selectedNameTokenId,
  isLoadingTotalPoints,
  candidateNickname
}) => {
  const selectedHearts = heartsArray?.filter(heart => heart.isSelected);

  return (
    <>
    <div className="allocated-hearts">
    {selectedHearts?.length > 0 && selectedHearts.map((heart) => {
        // Generate random positions for each heart
        const rotation = Math.random() * 60 - 30;
        const translateX = (Math.random() * 10) - 5;
        const translateY = (Math.random() * 10) - 5;
        
        return (
          <div 
            className="heart-wrapper" 
            key={heart.id}
            style={{
              display: 'inline-block',
              transform: `rotate(${rotation}deg) translate(${translateX}%, ${translateY}%)`,
              position: 'relative'
            }}
          >
            <Heart
              status="selected"
              isAnimating={false}
              isMaxed={false}
              isLarge={true}
              onClick={() => updateHearts(heart.id)}
            />
          </div>
        );
      })}
      </div>
    <div className="hat-container">
      {/* Hearts Section */}
      

      {/* Input Controls */}
      

      {/* Upside Down Hat using two divs */}
      <div className="relative">
   
        

      </div>

        {/* Brim - now at top */}
        <div 
          className="bg-black mx-auto"
          style={{
            width: '100%',
            height: '100px',
          }} 
        >



<button
  className='caftvote'
  onClick={handleAllocatePoints}
  disabled={!isNameValid || !pointsToAllocate || isProcessing || isLoadingTotalPoints || seasonStatus !== "active"}
>
  {seasonStatus !== "active" ? (
    `Feafon ${seasonStatus}`
  ) : !isNameValid ? (
    pointsToAllocate ? (
      <>
        {<><span className='darkgoldtext'>ENTER FERFONA</span> To FERGive </>}
        <span style={{position:'relative', top:'-5%'}}>
          <Heart
            key={'amountheart'}
            status="used"
            isAnimating={false}
            isMaxed={false}
            canHover={false}
            isLarge={false}
            scale={2}
          />
        </span>
        {` ${pointsToAllocate}x`}
      </>
    ) : (
      "FERGive a Fellow FER"
    )
  ) : !pointsToAllocate ? (
    `How Muf doef FER ${inputName} DeFerve?`
  ) : (
    <>
      {`FERGive FER ${inputName} `}
      <span style={{position:'relative', top:'-5%'}}>
        <Heart
          key={'amountheart'}
          status="selected"
          isAnimating={false}
          isMaxed={false}
          canHover={false}
          isLarge={false}
          scale={2}
        />
      </span>
      {` x${pointsToAllocate}`}
    </>
  )}
</button>

     </div>
     <div className='cylinderholder'>
        
        {/* Crown - below brim */}
        <div 
  className="bg-black mx-auto"
  style={{
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '30vw',
    height:'100%',
    background: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 15%, rgba(255, 255, 255, 0.02) 85%, rgba(255, 255, 255, 0) 100%)'
  }} 
>
          {/* Names List - inside the crown */}
          <div className="global-voting-table">
  <table className="global-scoreboard">
    <thead>
      <tr>
        <th className="textmedium">FER</th>
        <th className="textmedium">FERGiven</th>
      </tr>
    </thead>
    <tbody className='rowholder'>
      {names?.length === 0 ? (
        <tr>
          <td colSpan={2} className="textmedium graytext">
            <i className="textmediumsmall">No votef received yet.</i>
          </td>
        </tr>
      ) : (
        names.map((item) => (
          <tr
            key={item.tokenId}
            className={`global-scorerow ${selectedCandidate === String(item.tokenId) ? 'selected' : ''}`}
            onClick={() => onSelectCandidate(String(item.tokenId), item.name)}
         
          >
            <td className="textmedium">{item.name}</td>
            <td className="textmedium ">
              <Heart
                key="display"
                status="available"
                isMaxed={false}
                isAnimating={false}
                canHover={false}
                pointer={false}
                scale={0.7}
              />
              x{item.votes}
            </td>
          </tr>
        ))
      )}
    </tbody>
  </table>
</div>
        </div>
      </div>
    </div>
    </>
  );
};

export default HatWithNames;