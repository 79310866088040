import React, { useEffect, useRef, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import * as THREE from 'three';
import { isMobile } from 'web3modal';

const vertexShader = `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

const fragmentShader = `
  uniform sampler2D tDiffuse;
  uniform sampler2D tDisplacement;
  uniform vec2 brushPos;
  uniform vec2 brushDir;
  uniform float brushSize;
  uniform float strength;
  varying vec2 vUv;

  void main() {
    vec2 uv = vUv;
    vec2 displacement = texture2D(tDisplacement, uv).rg;
    
    vec2 dir = uv - brushPos;
    float dist = length(dir);
    
    if (dist < brushSize) {
      float falloff = pow(1.0 - dist / brushSize, 2.0);
      falloff *= exp(-2.0 * dist * dist / (brushSize * brushSize));
      float pull = falloff * strength;
      
      vec2 pullDir = normalize(brushDir);
      displacement += pullDir * pull;
    }
    
    gl_FragColor = vec4(displacement, 0.0, 1.0);
  }
`;

const renderShader = `
  uniform sampler2D tDiffuse;
  uniform sampler2D tDisplacement;
  varying vec2 vUv;

  void main() {
    vec2 displacement = texture2D(tDisplacement, vUv).rg;
    vec2 uv = vUv - displacement;
    gl_FragColor = texture2D(tDiffuse, clamp(uv, 0.0, 1.0));
  }
`;

const WebGLLiquify = forwardRef(({ imageUrl, width, height, brushSize, strength, smoothing, activeLayer, onWebGLAvailability, userTier }, ref) => {
 
    const canvasRef = useRef(null);
    const sceneRef = useRef(null);
    const cameraRef = useRef(null);
    const rendererRef = useRef(null);
    const displacementRenderTargetRef = useRef(null);
    const displacementMaterialRef = useRef(null);
    const renderMaterialRef = useRef(null);
    const lastPosRef = useRef(new THREE.Vector2(-1, -1));
    const isDrawingRef = useRef(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const historyRef = useRef([]);
    const currentStateIndexRef = useRef(-1);
    const currentImageRef = useRef(null);
    const originalTextureRef = useRef(null);

    const strokeTimeoutRef = useRef(null);
    
 const [hasStroked, setHasStroked] = useState(false);

    const FPS_LIMIT = 30;
    const fpsInterval = 1000 / FPS_LIMIT;
    let lastFrameTime = 0;


    const MIN_DISTANCE = 0.001;

    const MAX_HISTORY = 5;
    const imageHistoryRef = useRef([]);

    const render = useCallback(() => {
        if (!rendererRef.current || !sceneRef.current || !cameraRef.current || !displacementRenderTargetRef.current) return;
    
        const renderer = rendererRef.current;
        const scene = sceneRef.current;
        const camera = cameraRef.current;
        const { read: readTarget, write: writeTarget } = displacementRenderTargetRef.current;
        const displacementMaterial = displacementMaterialRef.current;
        const renderMaterial = renderMaterialRef.current;
    
        if (!displacementMaterial || !renderMaterial) return;
    
        displacementMaterial.uniforms.tDisplacement.value = readTarget.texture;
        renderer.setRenderTarget(writeTarget);
        scene.overrideMaterial = displacementMaterial;
        renderer.render(scene, camera);
    
        displacementRenderTargetRef.current = { read: writeTarget, write: readTarget };
    
        renderMaterial.uniforms.tDisplacement.value = writeTarget.texture;
        renderer.setRenderTarget(null);
        scene.overrideMaterial = renderMaterial;
        renderer.render(scene, camera);
    }, []);

  
    
    const updateBaseImage = useCallback(() => {
        if (!rendererRef.current || !currentStateRenderTargetRef.current) return;
    
        const renderer = rendererRef.current;
        const currentStateTarget = currentStateRenderTargetRef.current;
    
        renderer.setRenderTarget(currentStateTarget);
        renderer.render(sceneRef.current, cameraRef.current);
        renderer.setRenderTarget(null);
    
        // Update materials
        displacementMaterialRef.current.uniforms.tDiffuse.value = currentStateTarget.texture;
        renderMaterialRef.current.uniforms.tDiffuse.value = currentStateTarget.texture;
    
        // Clear displacement
        const { read, write } = displacementRenderTargetRef.current;
        renderer.setRenderTarget(read);
        renderer.clear();
        renderer.setRenderTarget(write);
        renderer.clear();
        renderer.setRenderTarget(null);
    
        return { renderTarget: currentStateTarget };
    }, []);


    const loadImageState = useCallback((stateIndex) => {
        if (stateIndex < 0 || stateIndex >= imageHistoryRef.current.length) return;
    
        const stateTarget = imageHistoryRef.current[stateIndex];
        
        // Update the current image texture and material uniforms
        currentImageRef.current = stateTarget.texture;
        renderMaterialRef.current.uniforms.tDiffuse.value = stateTarget.texture;
        displacementMaterialRef.current.uniforms.tDiffuse.value = stateTarget.texture;
    
        // Clear displacement
        const { read, write } = displacementRenderTargetRef.current;
        rendererRef.current.setRenderTarget(read);
        rendererRef.current.clear();
        rendererRef.current.setRenderTarget(write);
        rendererRef.current.clear();
        rendererRef.current.setRenderTarget(null);
    
        currentStateIndexRef.current = stateIndex;
       // alert(currentStateIndexRef.current)

        render();
    }, [render]);

    const currentStateRenderTargetRef = useRef(null);

    const statePoolRef = useRef([]);

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const saveState = useCallback(() => {
        const renderer = rendererRef.current;
        const scene = sceneRef.current;
        const camera = cameraRef.current;

        const isMobileDevice = isMobile;
        const resolutionFactor = isMobileDevice ? 1.5 : 5;
        const textureType = isMobileDevice ? THREE.HalfFloatType : THREE.FloatType;

        const newStateTarget = new THREE.WebGLRenderTarget(width * resolutionFactor, height * resolutionFactor, {
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            format: THREE.RGBAFormat,
            type: textureType,
        });

        renderer.setRenderTarget(newStateTarget);
        renderer.render(scene, camera);
        renderer.setRenderTarget(null);

        const newHistory = imageHistoryRef.current.slice(0, currentStateIndexRef.current + 1);
        newHistory.push(newStateTarget);

        while (newHistory.length > MAX_HISTORY) {
            const oldestTarget = newHistory.shift();
            if (oldestTarget instanceof THREE.WebGLRenderTarget) {
                oldestTarget.dispose();
            }
        }

        imageHistoryRef.current = newHistory;
        currentStateIndexRef.current = newHistory.length - 1;

        currentImageRef.current = newStateTarget.texture;
        displacementMaterialRef.current.uniforms.tDiffuse.value = newStateTarget.texture;
        renderMaterialRef.current.uniforms.tDiffuse.value = newStateTarget.texture;

        const { read, write } = displacementRenderTargetRef.current;
        renderer.setRenderTarget(read);
        renderer.clear();
        renderer.setRenderTarget(write);
        renderer.clear();
        renderer.setRenderTarget(null);
    }, [width, height]);
    
    // Helper function to compare textures
    const areTexturesEqual = (texture1, texture2) => {
        if (texture1.image.width !== texture2.image.width || texture1.image.height !== texture2.image.height) {
            return false;
        }
        const data1 = texture1.image.data;
        const data2 = texture2.image.data;
        for (let i = 0; i < data1.length; i++) {
            if (Math.abs(data1[i] - data2[i]) > 0.001) { // Use a small epsilon for float comparison
                return false;
            }
        }
        return true;
    };

    const loadState = useCallback((stateIndex) => {
        if (stateIndex < 0 || stateIndex >= historyRef.current.length) return;

        const state = historyRef.current[stateIndex];
        const texture = new THREE.DataTexture(state, width, height, THREE.RGBAFormat, THREE.FloatType);
        texture.needsUpdate = true;

        currentImageRef.current = texture;
        displacementMaterialRef.current.uniforms.tDiffuse.value = texture;
        renderMaterialRef.current.uniforms.tDiffuse.value = texture;

        const { read, write } = displacementRenderTargetRef.current;
        rendererRef.current.setRenderTarget(read);
        rendererRef.current.clear();
        rendererRef.current.setRenderTarget(write);
        rendererRef.current.clear();
        rendererRef.current.setRenderTarget(null);

        currentStateIndexRef.current = stateIndex;
        render();
    }, [width, height, render]);

    
    const undo = useCallback(() => {
        if (currentStateIndexRef.current > 0) {
            currentStateIndexRef.current--;
            loadImageState(currentStateIndexRef.current);
        }
    }, [loadImageState]);
    
    const redo = useCallback(() => {
        if (currentStateIndexRef.current < imageHistoryRef.current.length - 1) {
            currentStateIndexRef.current++;
            loadImageState(currentStateIndexRef.current);
        }
    }, [loadImageState]);

    const loadTexture = useCallback(() => {
        new THREE.TextureLoader().load(imageUrl, (texture) => {
            if (displacementMaterialRef.current && renderMaterialRef.current && displacementRenderTargetRef.current) {
                originalTextureRef.current = texture;
                currentImageRef.current = texture;
                displacementMaterialRef.current.uniforms.tDiffuse.value = texture;
                renderMaterialRef.current.uniforms.tDiffuse.value = texture;
                displacementMaterialRef.current.uniforms.tDisplacement.value = displacementRenderTargetRef.current.read.texture;
                
                // Initialize the history with the original texture
                const initialTarget = new THREE.WebGLRenderTarget(width * 5, height * 5, {
                    minFilter: THREE.LinearFilter,
                    magFilter: THREE.LinearFilter,
                    format: THREE.RGBAFormat,
                    type: THREE.FloatType,
                });
                rendererRef.current.setRenderTarget(initialTarget);
                rendererRef.current.render(sceneRef.current, cameraRef.current);
                rendererRef.current.setRenderTarget(null);
    
                imageHistoryRef.current = [initialTarget];
                currentStateIndexRef.current = 0;
                
                render();
            }
        });
    }, [imageUrl, width, height, render]);

    const initThreeJS = useCallback(() => {
        const scene = new THREE.Scene();
        sceneRef.current = scene;
    
        const camera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, 0.1, 10);
        camera.position.z = 1;
        cameraRef.current = camera;
    
        const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, alpha: true });
        renderer.setSize(width, height);
        rendererRef.current = renderer;
    
        // Detect if we're on a mobile device
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        
        // Adjust resolution based on device
        const resolutionFactor = isMobile ? 2 : 3;
      
        const internalWidth = width * resolutionFactor;
        const internalHeight =height * resolutionFactor;
    
        const displacementRenderTarget = new THREE.WebGLRenderTarget(internalWidth, internalHeight, {
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            format: THREE.RGBAFormat,
            type: THREE.FloatType,
            stencilBuffer: false,
            depthBuffer: false
        });
    
        const displacementRenderTarget2 = displacementRenderTarget.clone();
    
        renderer.setRenderTarget(displacementRenderTarget);
        renderer.clear();
        renderer.setRenderTarget(displacementRenderTarget2);
        renderer.clear();
        renderer.setRenderTarget(null);
    
        displacementRenderTargetRef.current = {
            read: displacementRenderTarget,
            write: displacementRenderTarget2
        };
    
        const displacementMaterial = new THREE.ShaderMaterial({
            uniforms: {
                tDiffuse: { value: null },
                tDisplacement: { value: null },
                brushPos: { value: new THREE.Vector2(-1, -1) },
                brushDir: { value: new THREE.Vector2(0, 0) },
                brushSize: { value: brushSize / Math.max(width, height) },
                strength: { value: strength }
            },
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
        });
        displacementMaterialRef.current = displacementMaterial;
    
        const renderMaterial = new THREE.ShaderMaterial({
            uniforms: {
                tDiffuse: { value: null },
                tDisplacement: { value: displacementRenderTarget.texture },
            },
            vertexShader: vertexShader,
            fragmentShader: renderShader,
        });
        renderMaterialRef.current = renderMaterial;
    
        const geometry = new THREE.PlaneGeometry(1, 1);
        const mesh = new THREE.Mesh(geometry, renderMaterial);
        scene.add(mesh);
    
        loadTexture();


    }, [width, height, brushSize, strength, loadTexture]);

    const reset = useCallback(() => {
        if (rendererRef.current && displacementRenderTargetRef.current && originalTextureRef.current) {
            const { read, write } = displacementRenderTargetRef.current;
            rendererRef.current.setRenderTarget(read);
            rendererRef.current.clear();
            rendererRef.current.setRenderTarget(write);
            rendererRef.current.clear();
            rendererRef.current.setRenderTarget(null);

            // Dispose of all textures in history
            imageHistoryRef.current.forEach(target => {
                if (target instanceof THREE.WebGLRenderTarget) {
                    target.dispose();
                }
            });
            imageHistoryRef.current = [];

            currentImageRef.current = originalTextureRef.current.clone();
            displacementMaterialRef.current.uniforms.tDiffuse.value = currentImageRef.current;
            renderMaterialRef.current.uniforms.tDiffuse.value = currentImageRef.current;

            // Save the initial state after reset
            requestAnimationFrame(() => {
                saveState();
                render();
            });
        }
    }, [render, saveState]);

    useImperativeHandle(ref, () => ({
        getContext: (contextId) => canvasRef.current?.getContext(contextId),
        reset,
        undo,
        redo,
        toDataURL: () => {
            render();
            return canvasRef.current.toDataURL();
        },
        updateDimensions: (newDimensions) => {
            if (rendererRef.current && cameraRef.current && displacementMaterialRef.current) {
                rendererRef.current.setSize(newDimensions.width, newDimensions.height);
                displacementMaterialRef.current.uniforms.brushSize.value = brushSize / Math.max(newDimensions.width, newDimensions.height);
                displacementMaterialRef.current.uniforms.tDiffuse.value = currentImageRef.current;
                renderMaterialRef.current.uniforms.tDiffuse.value = currentImageRef.current;
        
                // Don't call render() or loadImageState during initial mobile load
                const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                const isInitialLoad = !imageHistoryRef.current || imageHistoryRef.current.length === 1;
                
                if (isMobile && isInitialLoad) {
                    // Skip the problematic calls during mobile initialization
                    render();
                    return;
                }
        
                // Otherwise proceed normally
                if (imageHistoryRef.current.length > 0 && currentStateIndexRef.current >= 0) {
                    loadImageState(currentStateIndexRef.current);
                }
            }
        }
    }));
    

    const updateCoords = useCallback((e) => {
        const now = performance.now();
        if (now - lastFrameTime < fpsInterval) return;
      
        lastFrameTime = now;
    
        if (isDrawingRef.current && canvasRef.current) {
            const rect = canvasRef.current.getBoundingClientRect();
            const clientX = e.clientX || e.touches[0].clientX;
            const clientY = e.clientY || e.touches[0].clientY;
            const x = (clientX - rect.left) / rect.width;
            const y = 1 - (clientY - rect.top) / rect.height;
            
            const brushPos = new THREE.Vector2(x, y);
            const brushDir = brushPos.clone().sub(lastPosRef.current);
            
            if (brushDir.length() >= MIN_DISTANCE) {
                brushDir.normalize();
    
                displacementMaterialRef.current.uniforms.brushPos.value.copy(brushPos);
                displacementMaterialRef.current.uniforms.brushDir.value.copy(brushDir);
                
                lastPosRef.current.copy(brushPos);
                render();
            }
        }
    }, [render]);

    const handleMouseDown = useCallback((event) => {
        if (userTier === 'NOT A' && hasStroked) {
            alert("You felt the power. If you want to wield it, you need at leaft 1 $FER in your wallet");
            return; // Return immediately without setting any drawing state
        }
    
        const rect = canvasRef.current.getBoundingClientRect();
        isDrawingRef.current = true;
        
        lastPosRef.current.set(
            (event.clientX - rect.left) / rect.width,
            1 - (event.clientY - rect.top) / rect.height
        );
        updateCoords(event);
    }, [hasStroked, userTier, updateCoords]);



    const handleMouseUp = useCallback(() => {
        if (isDrawingRef.current) {
            isDrawingRef.current = false;
            if (userTier === 'NOT A') {
                setHasStroked(true);
            }
            saveState();
        }
    }, [saveState, userTier]);
    


    const handleMouseMove = useCallback((e) => {
        if (canvasRef.current) {
            const rect = canvasRef.current.getBoundingClientRect();
            const x = (e.clientX - rect.left) / rect.width;
            const y = (e.clientY - rect.top) / rect.height;
            setCursorPosition({ x, y });
        }
        updateCoords(e);
    }, [updateCoords]);

    useEffect(() => {
        if (canvasRef.current && !isInitialized) {
            try {
                initThreeJS();
                setIsInitialized(true);
                onWebGLAvailability(true);
            } catch (error) {
                console.error("WebGL initialization failed:", error);
                onWebGLAvailability(false);
            }
        }
    }, [imageUrl, width, height, isInitialized, onWebGLAvailability, initThreeJS]);

    useEffect(() => {
        if (displacementMaterialRef.current) {
            displacementMaterialRef.current.uniforms.brushSize.value = brushSize / Math.max(width, height);
            displacementMaterialRef.current.uniforms.strength.value = strength;
        }
    }, [brushSize, strength, width, height]);

    const [isStroking, setIsStroking] = useState(false);

    const handleTouchStart = useCallback((event) => {
        if (userTier === 'NOT A' && hasStroked) {
            alert("You felt the power. If you want to wield it, you need at leaft 1 $FER in your wallet");
            return; // Return immediately without setting any drawing state
        }


        if (event.touches.length === 1) {
            event.preventDefault();
            setIsStroking(true);
            isDrawingRef.current = true;
            const touch = event.touches[0];
            const rect = canvasRef.current.getBoundingClientRect();
            lastPosRef.current.set(
                (touch.clientX - rect.left) / rect.width,
                1 - (touch.clientY - rect.top) / rect.height
            );
            updateCoords(touch);
        }
    }, [updateCoords]);
    
    const handleTouchMove = useCallback((event) => {
        if (isStroking) {
            event.preventDefault();
            const touch = event.touches[0];
            if (canvasRef.current) {
                const rect = canvasRef.current.getBoundingClientRect();
                const x = (touch.clientX - rect.left) / rect.width;
                const y = (touch.clientY - rect.top) / rect.height;
                setCursorPosition({ x, y });
            }
            updateCoords(touch);
        }
    }, [isStroking, updateCoords]);
    
    const handleTouchEnd = useCallback((event) => {
        if (isStroking) {
            event.preventDefault();
            setIsStroking(false);
            isDrawingRef.current = false;
            if (userTier === 'NOT A') {
                setHasStroked(true);
            }
            saveState();
        }
    }, [isStroking, saveState]);
    


    return (
        <div  style={{ position: 'relative', width, height }}>
                <canvas 
                   
                    ref={canvasRef} 
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    style={{ 
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        pointerEvents: activeLayer === 'liquify' ? 'auto' : 'none',
                        touchAction: 'pinch-zoom',
                    }}
                />
            {activeLayer === 'liquify' && (
                <div
                    className="custom-cursor"
                    style={{
                        width: `${brushSize}px`,
                        height: `${brushSize}px`,
                        borderRadius: '50%',
                        border: '2px solid green',
                        position: 'absolute',
                        pointerEvents: 'none',
                        left: `${cursorPosition.x * 100}%`,
                        top: `${cursorPosition.y * 100}%`,
                        transform: 'translate(-50%, -50%)',
                        zIndex: 15,
                    }}
                />
            )}
        </div>
    );
});

export default WebGLLiquify;
