import React from 'react';

const Footer = ({ footerClass, backgroundColor }) => {
  return (
    <div 
      className={`footer ${footerClass}`} 
      style={{ backgroundColor: backgroundColor || '#61dafb' }}
    >
      {/* Footer content can go here if needed */}
    </div>
  );
};

export default Footer;