// LegalDisclaimer.js

import React from 'react';

const LegalDisclaimer = () => {
    const styles = {
        container: {
            padding: '30px',
            maxWidth: '800px',
            margin: '0 auto',
            fontFamily: 'Arial, sans-serif',
            lineHeight: '1.6',
            color: '#333',
            backgroundColor:'#fff'
        },
        header: {
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '20px',
            color: '#1a1a1a'
        },
        sectionHeader: {
            fontSize: '22px',
            fontWeight: 'bold',
            marginTop: '30px',
            marginBottom: '15px',
            color: '#1a1a1a'
        },
        subHeader: {
            fontSize: '20px',
            fontWeight: 'bold',
            marginTop: '25px',
            marginBottom: '15px',
            color: '#1a1a1a'
        },
        paragraph: {
            marginBottom: '15px',
            lineHeight: '1.6'
        },
        list: {
            marginBottom: '15px',
            paddingLeft: '20px',
            listStyleType: 'disc'
        },
        listItem: {
            marginBottom: '8px',
            lineHeight: '1.5'
        },
        warningBox: {
            backgroundColor: '#ffebee',
            padding: '20px',
            borderRadius: '5px',
            marginBottom: '25px',
            border: '1px solid #ffcdd2'
        },
        warningHeader: {
            color: '#c62828',
            fontWeight: 'bold',
            marginBottom: '10px',
            fontSize: '18px'
        },
        noticeBox: {
            backgroundColor: '#fff3e0',
            padding: '20px',
            borderRadius: '5px',
            marginBottom: '25px',
            border: '1px solid #ffe0b2'
        },
        infoBox: {
            backgroundColor: '#e3f2fd',
            padding: '20px',
            borderRadius: '5px',
            marginBottom: '25px',
            border: '1px solid #bbdefb'
        },
        divider: {
            margin: '25px 0',
            border: 'none',
            borderTop: '1px solid #e0e0e0'
        },
        strong: {
            fontWeight: 'bold',
            color: '#1a1a1a'
        }
    };

    const handleTouchMove = (e) => {
        e.stopPropagation();
    };

    return (
        <div onTouchMove={handleTouchMove} style={styles.container}>
            {/* Main Header */}
            <h1 style={styles.header}>Legal Disclaimer for Order of Fer NFT Memberships Presale and the $FER memecoin-token distribution</h1>

            {/* Effective Date */}
            <p><strong>Effective Date:</strong> [2024-11-11]</p>

            {/* IMPORTANT Notice */}
            <p><strong>IMPORTANT:</strong> READ THIS DISCLAIMER CAREFULLY BEFORE PARTICIPATING IN THE PRESALE. BY PURCHASING ORDER OF FER NFT MEMBERSHIPS AND RECEIVING $FER TOKENS, YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT PURCHASE THE MEMBERSHIPS OR PARTICIPATE IN THE PRESALE.</p>

            {/* Divider */}
            <hr style={styles.divider} />

            {/* High-Risk Warning Box */}
            <div style={styles.warningBox}>
                <p style={styles.warningHeader}>HIGH-RISK WARNING:</p>
                <ul style={styles.list}>
                    <li style={styles.listItem}>This is a high-risk crypto-asset that may not be suitable for all investors.</li>
                    <li style={styles.listItem}>You could lose your entire investment.</li>
                    <li style={styles.listItem}>This is a decentralized Memecoin-project with no intrinsic value. This project and the code associated with it should be viewed as a fun and technical experiment, rather than a investment.</li>
                    <li style={styles.listItem}>This crypto-asset could be highly volatile and is speculative in nature.</li>
                    <li style={styles.listItem}>The creators of this technical project is not promising an effort to increase the value of the $FER tokens.</li>
                </ul>
            </div>

            {/* Membership Structure and Token Distribution */}
            <div style={styles.infoBox}>
                <h2 style={styles.subHeader}>Membership Structure and Token Distribution</h2>
                
                <h3 style={styles.subHeader}>NFT Membership Benefits:</h3>
                <ul style={styles.list}>
                    <li style={styles.listItem}>Access to the Order of Fer image editor tool.</li>
                    <li style={styles.listItem}>Participation in the Fer Score system as is controlled by Smart Contracts. Any bugs or exploits will impact this and cannot be fixed.</li>
                    <li style={styles.listItem}>The NFT Memberships are soulbound and non-transferable, controlled by the Smart Contract.</li>
                </ul>
                
                <h3 style={styles.subHeader}>$FER Token Distribution:</h3>
                <ul style={styles.list}>
                    <li style={styles.listItem}>$FER tokens are provided as complimentary additions to NFT Membership purchases.</li>
                    <li style={styles.listItem}>The amount of future $FER distributions is determined by member's Fer Score and other functionalities in the Smart Contract, given those function as intended.</li>
                    <li style={styles.listItem}>These distributions are not guaranteed and may vary or cease at any time.</li>
                    <li style={styles.listItem}>Distributions do not represent returns on investment or profit sharing.</li>
                </ul>

                {/* Important Notice Box */}
                <div style={styles.noticeBox}>
                    <p style={styles.strong}>Important Notice:</p>
                    <ul style={styles.list}>
                        <li style={styles.listItem}>The primary purchase is the NFT Membership and its associated utilities.</li>
                        <li style={styles.listItem}>$FER tokens are complementary additions with no guaranteed value.</li>
                        <li style={styles.listItem}>Future token distributions are not guaranteed rights or entitlements.</li>
                        <li style={styles.listItem}>The Fer Score system is subject to change, bugs and may be discontinued.</li>
                    </ul>
                </div>
            </div>

            {/* Main Content Sections */}
            {/* 1. Introduction */}
            <h2 style={styles.sectionHeader}>1. Introduction</h2>
            <p style={styles.paragraph}>
                Welcome to Order of Fer ("Order of Fer," "we," "us," or "our"). This Legal Disclaimer ("Disclaimer") 
                governs your participation in the presale of Order of Fer NFT Memberships and the receipt of $FER 
                tokens ("$FER Tokens"). By participating in the presale, you agree to comply with and be bound by the terms outlined herein.
            </p>

            {/* 2. Nature of $FER Tokens and NFT Memberships */}
            <h2 style={styles.sectionHeader}>2. Nature of $FER Tokens and NFT Memberships</h2>
            
            <h3 style={styles.subHeader}>2.1 $FER Tokens</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}>$FER Tokens are distributed to purchasers of Order of Fer NFT Memberships during the presale phase.</li>
                <li style={styles.listItem}>The primary purpose of $FER Tokens is to facilitate the purchase of additional Order of Fer NFT Memberships post-presale.</li>
                <li style={styles.listItem}>$FER Tokens <strong style={styles.strong}>are resellable</strong> and will be available on a decentralized liquidity pool decentralized exchange (DEX) after the presale.</li>
                <li style={styles.listItem}>The resale and liquidity features of $FER Tokens introduce additional risks, including but not limited to market volatility and liquidity issues.</li>
            </ul>

            <h3 style={styles.subHeader}>2.2 Order of Fer NFT Memberships</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}>Order of Fer NFT Memberships are <strong style={styles.strong}>soulbound</strong> and <strong style={styles.strong}>cannot be resold, transferred, or otherwise disposed of</strong>.</li>
                <li style={styles.listItem}>Ownership of an Order of Fer NFT Membership grants you a subjective membership status within the Order of Fer community.</li>
                <li style={styles.listItem}>NFT Memberships <strong style={styles.strong}>do not</strong> provide access to governance functionalities or confer any ownership or economic rights in Order of Fer.</li>
            </ul>

            {/* 3. No Investment or Financial Obligations */}
            <h2 style={styles.sectionHeader}>3. No Investment or Financial Obligations</h2>
            
            <h3 style={styles.subHeader}>3.1 No Price Appreciation Guarantee</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}>The founders and developers of Order of Fer have <strong style={styles.strong}>no obligations</strong> to support or ensure the future price appreciation of $FER Tokens, Order of Fer NFT Memberships, or any related assets.</li>
                <li style={styles.listItem}>Participation in the presale does not constitute an investment, and $FER Tokens should not be considered as financial instruments or securities.</li>
            </ul>

            <h3 style={styles.subHeader}>3.2 No Liability for Losses</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}>Interactions with our smart contracts are performed at your own risk.</li>
                <li style={styles.listItem}>In the event of smart contract failures, breaches, or any other technical issues, you may lose all funds associated with your participation. We <strong style={styles.strong}>shall not be liable</strong> for any such losses.</li>
                <li style={styles.listItem}>This presale and the use of smart contracts are part of a <strong style={styles.strong}>technical decentralized experiment</strong>, and all participants acknowledge and accept the inherent risks.</li>
            </ul>

            {/* 4. Smart Contract Risks */}
            <h2 style={styles.sectionHeader}>4. Smart Contract Risks</h2>
            
            <h3 style={styles.subHeader}>4.1 Non-Re-deployable Contracts</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}>Our smart contracts are immutable and <strong style={styles.strong}>cannot be redeployed or modified</strong> once deployed.</li>
                <li style={styles.listItem}>Any interactions with the smart contracts are final and irreversible.</li>
            </ul>

            <h3 style={styles.subHeader}>4.2 Risk of Malfunction</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}>Smart contracts may contain bugs or vulnerabilities that could lead to loss of funds or malfunction.</li>
                <li style={styles.listItem}>You acknowledge and accept these risks by interacting with our smart contracts.</li>
            </ul>

            {/* 5. Participation Terms */}
            <h2 style={styles.sectionHeader}>5. Participation Terms</h2>
            
            <h3 style={styles.subHeader}>5.1 Purchase Process</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}>To participate, you must purchase an Order of Fer NFT Membership through our official platform.</li>
                <li style={styles.listItem}>Upon purchase, you will receive $FER Tokens directly to your connected wallet.</li>
            </ul>

            <h3 style={styles.subHeader}>5.2 No Refunds</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}>All purchases of NFT Memberships are final. There are <strong style={styles.strong}>no refunds or cancellations</strong> except as required by applicable law.</li>
            </ul>

            {/* 6. Security */}
            <h2 style={styles.sectionHeader}>6. Security</h2>
            
            <h3 style={styles.subHeader}>6.1 Personal Responsibility</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}>You are solely responsible for securing your wallet and private keys.</li>
                <li style={styles.listItem}>Loss or theft of your private keys may result in the permanent loss of your $FER Tokens and NFT Memberships.</li>
            </ul>

            <h3 style={styles.subHeader}>6.2 No Liability for Security Breaches</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}>We are not liable for any losses resulting from unauthorized access to your wallet or breaches in security.</li>
            </ul>

            {/* 7. Tax Obligations */}
            <h2 style={styles.sectionHeader}>7. Tax Obligations</h2>
            <p style={styles.paragraph}>
                You are responsible for determining and complying with any tax obligations arising from your participation. We <strong style={styles.strong}>do not provide tax advice</strong> and recommend consulting with a qualified tax professional.
            </p>

            {/* 8. Eligibility */}
            <h2 style={styles.sectionHeader}>8. Eligibility</h2>
            
            <h3 style={styles.subHeader}>8.1 U.S. Persons</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}>Only individuals who are U.S. Persons, as defined in Rule 902(k) of Regulation S under the U.S. Securities Act of 1933, and who meet the requirements to be deemed an "accredited investor" as defined under Regulation D, may participate in the presale.</li>
                <li style={styles.listItem}>U.S. Persons must certify their status as accredited investors and acknowledge that their participation complies with applicable U.S. laws and regulations.</li>
            </ul>

            <h3 style={styles.subHeader}>8.2 Non-U.S. Persons</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}>Only individuals who are not U.S. Persons, are not accessing this site for the account or benefit of any U.S. Person, and are accessing this site from outside of the United States may participate in the presale.</li>
                <li style={styles.listItem}>By participating, non-U.S. Persons certify that they are not U.S. Persons and are complying with all applicable local laws and regulations.</li>
            </ul>

            {/* 9. Compliance with MiCA */}
            <h2 style={styles.sectionHeader}>9. Compliance with MiCA (Markets in Crypto-Assets Regulation)</h2>
            
            <h3 style={styles.subHeader}>9.1 Overview</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}>Order of Fer's intention is to fully comply with the European Union's Markets in Crypto-Assets Regulation (MiCA).</li>
                <li style={styles.listItem}>MiCA establishes a comprehensive regulatory framework for crypto-assets within the EU, aiming to enhance consumer protection and market integrity.</li>
            </ul>

            <h3 style={styles.subHeader}>9.2 Responsibilities Under MiCA</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}><strong style={styles.strong}>Transparency:</strong> We provide clear and comprehensive information about the $FER Tokens and NFT Memberships, including their functionalities, risks, and terms of sale.</li>
            </ul>

            <h3 style={styles.subHeader}>9.3 Participant Obligations</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}><strong style={styles.strong}>Legal Compliance:</strong> Participants must ensure that their participation complies with all applicable local laws and regulations in their jurisdiction.</li>
            </ul>

            {/* 10. Disclaimers */}
            <h2 style={styles.sectionHeader}>10. Disclaimers</h2>
            
            <h3 style={styles.subHeader}>10.1 No Warranties</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}>The presale, $FER Tokens, and NFT Memberships are provided "as is" without any warranties, express or implied.</li>
                <li style={styles.listItem}>We disclaim all warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</li>
            </ul>

            <h3 style={styles.subHeader}>10.2 No Regulatory Approval</h3>
            <ul style={styles.list}>
                <li style={styles.listItem}>No regulatory authority has examined or approved the presale, $FER Tokens, or NFT Memberships.</li>
                <li style={styles.listItem}>The presale and $FER Tokens are not registered under the U.S. Securities Act or any other securities laws.</li>
            </ul>

            {/* 11. Limitation of Liability */}
            <h2 style={styles.sectionHeader}>11. Limitation of Liability</h2>
            <p style={styles.paragraph}>
                To the fullest extent permitted by law, Order of Fer and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your participation in the presale or use of $FER Tokens and NFT Memberships. Our total liability shall not exceed the amount you have paid for the NFT Memberships.
            </p>

            {/* 12. Indemnification */}
            <h2 style={styles.sectionHeader}>12. Indemnification</h2>
            <p style={styles.paragraph}>
                You agree to indemnify, defend, and hold harmless Order of Fer and its affiliates from any claims, damages, losses, liabilities, costs, and expenses arising from your participation in the presale or your violation of this Disclaimer.
            </p>

            {/* 13. Governing Law and Jurisdiction */}
            <h2 style={styles.sectionHeader}>13. Governing Law and Jurisdiction</h2>
            <p style={styles.paragraph}>
                This Disclaimer shall be governed by and construed in accordance with the laws of Europe, without regard to its conflict of law principles. Any disputes arising out of or related to this Disclaimer shall be resolved exclusively in the courts located within Europe.
            </p>

            {/* 14. Severability */}
            <h2 style={styles.sectionHeader}>14. Severability</h2>
            <p style={styles.paragraph}>
                If any provision of this Disclaimer is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary, and the remaining provisions shall remain in full force and effect.
            </p>

            {/* 15. Amendments */}
            <h2 style={styles.sectionHeader}>15. Amendments</h2>
            <p style={styles.paragraph}>
                We reserve the right to modify this Disclaimer at any time. Any changes will be effective immediately upon posting on our official website. It is your responsibility to review this Disclaimer periodically for updates.
            </p>

            {/* 16. Contact Information */}
            <h2 style={styles.sectionHeader}>16. Contact Information</h2>
            <p style={styles.paragraph}>
                For any questions or concerns regarding this Disclaimer, please contact us at <strong style={styles.strong}>info@fer.meme</strong>.
            </p>

            {/* Divider */}
            <hr style={styles.divider} />

            {/* Final Acknowledgment */}
            <p style={{ ...styles.paragraph, ...styles.strong }}>
                By purchasing an Order of Fer NFT Membership and receiving $FER Tokens, you acknowledge that you have read, understood, and agree to be bound by this Legal Disclaimer.
            </p>
        </div>
    );
};

export default LegalDisclaimer;
