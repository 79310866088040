// frameOverlayUtils.js

import { addFrameAndText, setPlaqueText, setLogoImage } from './frameUtils';
import { applyFerSpeakForg } from '../../utils/formatUtils';
import ferCoinLogo from '../../fer_coin_logo.png'; // Ensure correct path

let storedText = '';

/**
 * Utility function to detect if the device is mobile.
 * This can be adjusted based on specific requirements.
 */


function isMobileDevice() {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function getStoredText() {
    return storedText;
}


export function initializeFrameOverlay(
    container,
    imageDimensions,
    frameThickness,
    userTier,
    userMainName
) {
    const minDimension = Math.min(imageDimensions.width, imageDimensions.height);
    const adjustedFrameThickness = minDimension * 0.07;
    const dpr = window.devicePixelRatio || 1;
    const canvasWidth = imageDimensions.width + 2 * adjustedFrameThickness;
    const canvasHeight = imageDimensions.height + 2 * adjustedFrameThickness;

    const frameWrapper = document.createElement('div');
    frameWrapper.style.position = 'absolute';
    frameWrapper.style.top = `${-adjustedFrameThickness}px`;
    frameWrapper.style.left = `${-adjustedFrameThickness}px`;
    frameWrapper.style.width = `${canvasWidth}px`;
    frameWrapper.style.height = `${canvasHeight}px`;
    frameWrapper.style.pointerEvents = 'none';
    frameWrapper.style.zIndex = '10';

    const frameCanvas = document.createElement('canvas');
    frameCanvas.width = canvasWidth * dpr;
    frameCanvas.height = canvasHeight * dpr;
    frameCanvas.style.width = `${canvasWidth}px`;
    frameCanvas.style.height = `${canvasHeight}px`;

    frameWrapper.appendChild(frameCanvas);

    const ctx = frameCanvas.getContext('2d');
    ctx.scale(dpr, dpr);

    const plaqueFontSize = Math.floor(adjustedFrameThickness * 0.8);
    const padding = Math.floor(adjustedFrameThickness * 0.4);
    const overlap = adjustedFrameThickness * 0.05;

    // Updated Y position without scaling factor
    const plaqueTextY = canvasHeight - adjustedFrameThickness - overlap - (plaqueFontSize / 2) - padding;

    const inputField = document.createElement('input');
    inputField.type = 'text';
    inputField.placeholder = '<ADD TITLE>';
    inputField.style.position = 'absolute';
    inputField.style.backgroundColor = 'black';
    // Set initial color based on whether there's a value, using the red-grayish color for placeholder
    inputField.style.color = storedText ? 'white' : '#810303';
    inputField.style.border = 'none';
    inputField.style.outline = 'none';
    inputField.style.textAlign = 'center';
    inputField.style.fontSize = `${plaqueFontSize}px`;
    inputField.style.fontWeight = 'bold';
    inputField.style.fontFamily = '"Segoe UI", sans-serif';
    inputField.style.height = `${plaqueFontSize + padding * 2}px`;
    inputField.style.lineHeight = `${plaqueFontSize + padding * 2}px`;
    inputField.style.padding = `0 ${padding}px`;
    inputField.style.pointerEvents = 'auto';
    inputField.style.zIndex = '20';
    
    // Remove text decorations and shadows
    inputField.style.textDecoration = 'none';
    inputField.style.webkitTextFillColor = inputField.value ? 'white' : '#810303';
    inputField.style.WebkitBoxShadow = '0 0 0px 1000px black inset';
    inputField.spellcheck = false;
    inputField.autocomplete = 'off';
    inputField.autocorrect = 'off';
    inputField.autocapitalize = 'off';
    
    // Move the input event listener here and modify it
    inputField.addEventListener('input', function() {
        const hasValue = this.value.trim().length > 0;
        this.style.color = hasValue ? 'white' : '#810303';
        this.style.webkitTextFillColor = hasValue ? 'white' : '#810303';
    });
    
    // Also add a focus event to handle placeholder color
    inputField.addEventListener('focus', function() {
        if (!this.value) {
            this.style.color = '#810303';
            this.style.webkitTextFillColor = '#810303';
        }
    });
    
    // Add blur event to reset color if empty
    inputField.addEventListener('blur', function() {
        if (!this.value) {
            this.style.color = '#810303';
            this.style.webkitTextFillColor = '#810303';
        }
    });

    if (storedText) {
        inputField.value = applyFerSpeakForg(storedText);
    }

    frameWrapper.appendChild(inputField);

    /**
     * Adjusts the input field and plaque size based on the input text.
     * @param {string} text - The text to measure and adjust the input field for.
     */
    function adjustInputField(text) {
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        tempCtx.font = `bold ${plaqueFontSize}px "Segoe UI", sans-serif`;
        const displayText = text || inputField.placeholder;
        const textMetrics = tempCtx.measureText(displayText);
        const textWidth = textMetrics.width;
        const buffer = 5; // Add a small buffer to prevent wrapping
        const totalWidth = Math.ceil(textWidth + padding * 2 + buffer);

        // Updated inputTop calculation
        const inputTop = plaqueTextY - (plaqueFontSize + padding ) / 2;
        inputField.style.width = `${totalWidth}px`;
        inputField.style.top = `${inputTop}px`;
        inputField.style.left = `${(canvasWidth - totalWidth) / 2}px`;
    }

    adjustInputField(storedText ? applyFerSpeakForg(storedText) : '');

    // Load the logo
    const logo = new Image();
    logo.src = ferCoinLogo;
    logo.onload = () => {
        setLogoImage(logo); // Store the logo image globally in frameUtils.js
        setPlaqueText(storedText ? applyFerSpeakForg(storedText) : '');
        addFrameAndText(
            ctx,
            canvasWidth,
            canvasHeight,
            adjustedFrameThickness,
            userTier,
            userMainName,
            storedText ? applyFerSpeakForg(storedText) : '',
            overlap,
            logo // Pass the logo image for the overlay
        );
    };

    logo.onerror = () => {
        console.error('Failed to load ferCoinLogo image.');
        addFrameAndText(
            ctx,
            canvasWidth,
            canvasHeight,
            adjustedFrameThickness,
            userTier,
            userMainName,
            storedText ? applyFerSpeakForg(storedText) : '',
            overlap
            // No logo passed
        );
    };

    /**
     * Handles text input updates for both desktop and mobile.
     * @param {string} currentText - The current text input.
     * @param {boolean} updateDisplayText - Whether to update the input field's value.
     */
    function handleTextInput(currentText, updateDisplayText = true) {
        const cursorPosition = inputField.selectionStart;
        storedText = currentText;
        const ferSpokenText = applyFerSpeakForg(storedText);
        setPlaqueText(ferSpokenText);
        
        if (updateDisplayText) {
            inputField.value = ferSpokenText;
        }
        
        adjustInputField(ferSpokenText);
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        addFrameAndText(
            ctx,
            canvasWidth,
            canvasHeight,
            adjustedFrameThickness,
            userTier,
            userMainName,
            ferSpokenText,
            overlap,
            logo
        );

        // If on mobile, update clickableText as well
        if (isMobileDevice()) {
            const clickableText = frameWrapper.querySelector('.clickable-text');
            if (clickableText) {
                clickableText.textContent = ferSpokenText;
            }
        }

        inputField.setSelectionRange(cursorPosition, cursorPosition);
    }

    // Desktop inputField event listeners
    const inputFieldHandler = () => {
        handleTextInput(inputField.value);
    };

    inputField.addEventListener('input', inputFieldHandler);

    inputField.addEventListener('blur', () => {
        handleTextInput(storedText, false);
    });

    /**
     * Mobile-Specific Popup Implementation
     */
    if (isMobileDevice()) {
        // Hide the original input field
        inputField.style.display = 'none';

        // Create a clickable text element to trigger the popup
        const clickableText = document.createElement('div');
        clickableText.classList.add('clickable-text'); // Add a class for easier selection
        clickableText.textContent = storedText ? applyFerSpeakForg(storedText) : inputField.placeholder;
        clickableText.style.position = 'absolute';
        clickableText.style.top = inputField.style.top;
        clickableText.style.left = inputField.style.left;
        clickableText.style.width = inputField.style.width;
        clickableText.style.height = inputField.style.height;
        clickableText.style.lineHeight = inputField.style.lineHeight;
        clickableText.style.backgroundColor = inputField.style.backgroundColor;
        clickableText.style.color = inputField.style.color;
        clickableText.style.textAlign = inputField.style.textAlign;
        clickableText.style.fontSize = inputField.style.fontSize;
        clickableText.style.fontWeight = inputField.style.fontWeight;
        clickableText.style.fontFamily = inputField.style.fontFamily;
        clickableText.style.padding = inputField.style.padding;
        clickableText.style.pointerEvents = 'auto';
        clickableText.style.zIndex = '21'; // Above the input field
        clickableText.style.cursor = 'pointer';
        clickableText.style.display = 'flex';
        clickableText.style.alignItems = 'center';
        clickableText.style.justifyContent = 'center';
        clickableText.style.border = '1px solid #555';
        clickableText.style.borderRadius = '4px';
        clickableText.style.boxSizing = 'border-box';
        clickableText.style.overflow = 'hidden';
        clickableText.style.wordBreak = 'break-word';

        frameWrapper.appendChild(clickableText);

        // Create the popup overlay
        const popupOverlay = document.createElement('div');
        popupOverlay.style.position = 'fixed';
        popupOverlay.style.top = '0';
        popupOverlay.style.left = '0';
        popupOverlay.style.width = '100%';
        popupOverlay.style.height = '100%';
        popupOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        popupOverlay.style.display = 'none'; // Hidden by default
        popupOverlay.style.alignItems = 'center';
        popupOverlay.style.justifyContent = 'center';
        popupOverlay.style.zIndex = '1000';

        // Create the popup container
        const popupContainer = document.createElement('div');
        popupContainer.style.backgroundColor = '#222';
        popupContainer.style.padding = '20px';
        popupContainer.style.borderRadius = '8px';
        popupContainer.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
        popupContainer.style.width = '80%';
        popupContainer.style.maxWidth = '400px';
        popupContainer.style.display = 'flex';
        popupContainer.style.flexDirection = 'column';
        popupContainer.style.alignItems = 'center';

        // Create the popup input field
        const popupInput = document.createElement('input');
        popupInput.type = 'text';
        popupInput.placeholder = '<Input Name>';
        popupInput.value = storedText ? applyFerSpeakForg(storedText) : '';
        popupInput.style.width = '100%';
        popupInput.style.padding = '10px';
        popupInput.style.marginBottom = '20px';
        popupInput.style.fontSize = '16px';
        popupInput.style.border = 'none';
        popupInput.style.borderRadius = '4px';
        popupInput.style.outline = 'none';
        popupInput.style.backgroundColor = '#333';
        popupInput.style.color = 'white';
        popupInput.style.boxSizing = 'border-box';

        // Create the apply button
        const applyButton = document.createElement('button');
        applyButton.textContent = 'Apply';
        applyButton.style.padding = '10px 20px';
        applyButton.style.fontSize = '16px';
        applyButton.style.border = 'none';
        applyButton.style.borderRadius = '4px';
        applyButton.style.backgroundColor = '#4CAF50';
        applyButton.style.color = 'white';
        applyButton.style.cursor = 'pointer';
        applyButton.style.marginTop = '10px';
        applyButton.style.width = '100%';
        applyButton.style.boxSizing = 'border-box';

        // Append input and button to popup container
        popupContainer.appendChild(popupInput);
        popupContainer.appendChild(applyButton);

        // Append popup container to overlay
        popupOverlay.appendChild(popupContainer);

        // Append overlay to body
        document.body.appendChild(popupOverlay);

        /**
         * Opens the popup for mobile input.
         */
        function openPopup() {
            popupInput.value = storedText ? applyFerSpeakForg(storedText) : '';
            popupOverlay.style.display = 'flex';
            popupInput.focus();
        }

        /**
         * Closes the popup without applying changes.
         */
        function closePopup() {
            popupOverlay.style.display = 'none';
        }

        // Event listener to open the popup when clickableText is clicked
        clickableText.addEventListener('click', openPopup);

        // Add input event listener to popupInput for real-time updates
        popupInput.addEventListener('input', () => {
            handleTextInput(popupInput.value, false);
        });

        // Event listener for the apply button
        applyButton.addEventListener('click', () => {
            const newText = popupInput.value.trim();
            if (newText !== '') {
                handleTextInput(newText, false);
            }
            closePopup();
        });

        // Optional: Close popup when clicking outside the container
        popupOverlay.addEventListener('click', (e) => {
            if (e.target === popupOverlay) {
                closePopup();
            }
        });

        // Adjust the clickableText when storedText changes
        function updateClickableText() {
            clickableText.textContent = storedText ? applyFerSpeakForg(storedText) : inputField.placeholder;
            adjustInputField(storedText ? applyFerSpeakForg(storedText) : '');
        }
    }

    frameWrapper.frameCanvas = frameCanvas;
    frameWrapper.inputField = inputField;
    frameWrapper.logo = logo; // Store the logo reference

    container.style.position = 'relative';
    container.appendChild(frameWrapper);

    return frameWrapper;
}

export function updateFrameOverlay(
    frameWrapper,
    newImageDimensions,
    frameThickness,
    userTier,
    userMainName
) {
    if (!frameWrapper) return;

    const frameCanvas = frameWrapper.frameCanvas;
    const inputField = frameWrapper.inputField;
    const logo = frameWrapper.logo; // Access the stored logo

    const minDimension = Math.min(newImageDimensions.width, newImageDimensions.height);
    const adjustedFrameThickness = minDimension * 0.07;
    const dpr = window.devicePixelRatio || 1;
    const newCanvasWidth = newImageDimensions.width + 2 * adjustedFrameThickness;
    const newCanvasHeight = newImageDimensions.height + 2 * adjustedFrameThickness;

    frameWrapper.style.width = `${newCanvasWidth}px`;
    frameWrapper.style.height = `${newCanvasHeight}px`;
    frameWrapper.style.top = `${-adjustedFrameThickness}px`;
    frameWrapper.style.left = `${-adjustedFrameThickness}px`;

    frameCanvas.width = newCanvasWidth * dpr;
    frameCanvas.height = newCanvasHeight * dpr;
    frameCanvas.style.width = `${newCanvasWidth}px`;
    frameCanvas.style.height = `${newCanvasHeight}px`;

    const ctx = frameCanvas.getContext('2d');
    ctx.scale(dpr, dpr);

    const ferSpokenText = applyFerSpeakForg(storedText);
    inputField.value = ferSpokenText;

    const plaqueFontSize = Math.floor(adjustedFrameThickness * 0.8);
    const padding = Math.floor(adjustedFrameThickness * 0.4);
    const overlap = adjustedFrameThickness * 0.05;

    // Updated Y position without scaling factor
    const plaqueTextY = newCanvasHeight - adjustedFrameThickness - overlap - (plaqueFontSize / 2) - padding;

    inputField.style.fontSize = `${plaqueFontSize}px`;
    inputField.style.height = `${plaqueFontSize + padding * 2}px`;
    inputField.style.lineHeight = `${plaqueFontSize + padding * 2}px`;
    inputField.style.padding = `0 ${padding}px`; 

    /**
     * Adjusts the input field and plaque size based on the input text.
     * @param {string} text - The text to measure and adjust the input field for.
     */
    function adjustInputField(text) {
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        tempCtx.font = `bold ${plaqueFontSize}px "Segoe UI", sans-serif`;
        const displayText = text || inputField.placeholder;
        const textMetrics = tempCtx.measureText(displayText);
        const textWidth = textMetrics.width;
        const buffer = 5; // Add a small buffer to prevent wrapping
        const totalWidth = Math.ceil(textWidth + padding * 2 + buffer);

        // Updated inputTop calculation
        const inputTop = plaqueTextY - (plaqueFontSize + padding ) / 2;
        inputField.style.width = `${totalWidth}px`;
        inputField.style.top = `${inputTop}px`;
        inputField.style.left = `${(newCanvasWidth - totalWidth) / 2}px`;
    }

    adjustInputField(ferSpokenText);

    // If on mobile, update the clickable text as well
    if (isMobileDevice()) {
        const clickableText = frameWrapper.querySelector('.clickable-text');
        if (clickableText) {
            clickableText.textContent = ferSpokenText || inputField.placeholder;
            clickableText.style.width = `${inputField.style.width}`;
            clickableText.style.top = `${inputField.style.top}`;
            clickableText.style.left = `${inputField.style.left}`;
        }
    }

    addFrameAndText(
        ctx,
        newCanvasWidth,
        newCanvasHeight,
        adjustedFrameThickness,
        userTier,
        userMainName,
        ferSpokenText,
        overlap,
        logo // Pass the logo to re-render
    );
}
