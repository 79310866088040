// src/components/AdminVoting.jsx

import React, { useState, useContext, useEffect } from 'react';
import { ethers } from 'ethers';
import { WalletContext } from '../WalletContext';
import config from '../config';

const AdminVoting = () => {
    // State variables
    const [holders, setHolders] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [nicknameAddress, setNicknameAddress] = useState('');
    const [nickname, setNickname] = useState('');
    const [currentSeasonInfo, setCurrentSeasonInfo] = useState(null);
    const [isPresaleEnded, setIsPresaleEnded] = useState(false);
    const [blacklistAddress, setBlacklistAddress] = useState('');
    const [blacklistStatus, setBlacklistStatus] = useState({});

    // Context
    const {
        isAuthenticated,
        userAddress,
        contracts,
        contractsReady,
    } = useContext(WalletContext);
    const { memeNFTContract, votingManagerContract, nameNFTContract } = contracts;

    // Determine if the user is admin
    const isAdmin = userAddress && userAddress.toLowerCase() === config.adminAddress.toLowerCase();

    // Fetch NFT holders and current season info on component mount and when admin status changes
    useEffect(() => {
        if (isAdmin && isAuthenticated && contractsReady) {
            fetchCurrentSeasonInfo();
            fetchBlacklistStatus();
        }
    }, [isAdmin, isAuthenticated, contractsReady]);



    // Function to fetch current season information
    const fetchCurrentSeasonInfo = async () => {
        try {
            const seasonId = await votingManagerContract.currentSeason();
            const isActive = await votingManagerContract.votingSeasonActive();
            setCurrentSeasonInfo({
                seasonId: seasonId.toString(),
                isActive
            });
        } catch (error) {
            console.error('Error fetching current season info:', error);
            alert('Failed to fetch current season information.');
        }
    };

    // Function to fetch blacklist status for a specific address
    const fetchBlacklistStatus = async () => {
        try {
            const isBlacklisted = await votingManagerContract.blacklistedUsers(blacklistAddress);
            setBlacklistStatus(prev => ({ ...prev, [blacklistAddress]: isBlacklisted }));
        } catch (error) {
            console.error('Error fetching blacklist status:', error);
            alert('Failed to fetch blacklist status.');
        }
    };

    // Function to start a voting season
    const connectWalletAndStartSeason = async () => {
        if (!isAuthenticated) {
            alert('Please connect your wallet first.');
            return;
        }

        if (currentSeasonInfo && currentSeasonInfo.seasonId >= 6) {
            alert('Maximum number of seasons reached.');
            return;
        }

        setIsProcessing(true);
        try {
            await votingManagerContract.startVotingSeason();
            alert('Voting season started successfully.');
            fetchCurrentSeasonInfo();
        } catch (error) {
            console.error('Error starting season:', error);
            alert(`Failed to start season: ${error.message}`);
        } finally {
            setIsProcessing(false);
        }
    };

    // Function to end a voting season
    const connectWalletAndEndSeason = async () => {
        if (!isAuthenticated) {
            alert('Please connect your wallet first.');
            return;
        }

        setIsProcessing(true);
        try {
            await votingManagerContract.endVotingSeason();
            alert('Voting season ended successfully.');
            fetchCurrentSeasonInfo();
        } catch (error) {
            console.error('Error ending season:', error);
            alert(`Failed to end season: ${error.message}`);
        } finally {
            setIsProcessing(false);
        }
    };

    const connectWalletAndSnapshotSeasonData = async () => {
        if (!isAuthenticated) {
            alert('Please connect your wallet first.');
            return;
        }

        setIsProcessing(true);
        try {
            await votingManagerContract.snapshotSeasonData();
            alert('Voting season ended successfully.');
            fetchCurrentSeasonInfo();
        } catch (error) {
            console.error('Error ending season:', error);
            alert(`Failed to end season: ${error.message}`);
        } finally {
            setIsProcessing(false);
        }
    };

    // Function to set presale as ended
    const setPresaleAsEnded = async () => {
        if (!isAuthenticated) {
            alert('Please connect your wallet first.');
            return;
        }

        setIsProcessing(true);
        try {
            await votingManagerContract.setPresaleEnded();
            alert('Presale marked as ended.');
            setIsPresaleEnded(true);
        } catch (error) {
            console.error('Error setting presale as ended:', error);
            alert(`Failed to set presale as ended: ${error.message}`);
        } finally {
            setIsProcessing(false);
        }
    };

    // Function to blacklist a user
    const blacklistUser = async () => {
        if (!isAuthenticated) {
            alert('Please connect your wallet first.');
            return;
        }

        if (!ethers.isAddress(blacklistAddress)) {
            alert('Please enter a valid Ethereum address.');
            return;
        }

        setIsProcessing(true);
        try {
            await votingManagerContract.blacklistUser(blacklistAddress);
            alert(`Address ${blacklistAddress} has been blacklisted.`);
            setBlacklistStatus(prev => ({ ...prev, [blacklistAddress]: true }));
            setBlacklistAddress('');
        } catch (error) {
            console.error('Error blacklisting user:', error);
            alert(`Failed to blacklist user: ${error.message}`);
        } finally {
            setIsProcessing(false);
        }
    };

    // Function to unblacklist a user
    const unblacklistUser = async () => {
        if (!isAuthenticated) {
            alert('Please connect your wallet first.');
            return;
        }

        if (!ethers.isAddress(blacklistAddress)) {
            alert('Please enter a valid Ethereum address.');
            return;
        }

        setIsProcessing(true);
        try {
            await votingManagerContract.unblacklistUser(blacklistAddress);
            alert(`Address ${blacklistAddress} has been unblacklisted.`);
            setBlacklistStatus(prev => ({ ...prev, [blacklistAddress]: false }));
            setBlacklistAddress('');
        } catch (error) {
            console.error('Error unblacklisting user:', error);
            alert(`Failed to unblacklist user: ${error.message}`);
        } finally {
            setIsProcessing(false);
        }
    };

    // Function to handle blacklist address input change
    const handleBlacklistAddressChange = (e) => {
        setBlacklistAddress(e.target.value);
    };

    return (
        <div className='admin-voting-container'>
            <h2>Admin Voting Panel</h2>

            {/* Section to manage voting seasons */}
            <div className='manage-season-section'>
                <h3>Manage Voting Season</h3>
                <div className='season-info'>
                    {currentSeasonInfo ? (
                        <>
                            <p><strong>Current Season:</strong> {currentSeasonInfo.seasonId}</p>
                            <p><strong>Status:</strong> {currentSeasonInfo.isActive ? 'Active' : 'Inactive'}</p>
                        </>
                    ) : (
                        <p>Loading current season information...</p>
                    )}
                </div>
                <button
                    className="btn btn-info"
                    onClick={connectWalletAndStartSeason}
                    disabled={isProcessing || (currentSeasonInfo && currentSeasonInfo.seasonId >= 6)}
                >
                    {isProcessing ? 'Processing...' : 'Start Voting Season'}
                </button>
                <button
                    className="btn btn-success"
                    onClick={connectWalletAndSnapshotSeasonData}
                    disabled={isProcessing || !currentSeasonInfo || !currentSeasonInfo.isActive}
                >
                    {isProcessing ? 'Processing...' : 'Snapshot SeasonData'}
                </button>
                <button
                    className="btn btn-success"
                    onClick={connectWalletAndEndSeason}
                    disabled={isProcessing || !currentSeasonInfo || !currentSeasonInfo.isActive}
                >
                    {isProcessing ? 'Processing...' : 'End Season'}
                </button>
            </div>

            {/* Section to set presale as ended */}
            <div className='manage-presale-section'>
                <h3>Manage Presale</h3>
                <button
                    className="btn btn-warning"
                    onClick={setPresaleAsEnded}
                    disabled={isProcessing || isPresaleEnded}
                >
                    {isProcessing ? 'Processing...' : isPresaleEnded ? 'Presale Ended' : 'End Presale'}
                </button>
            </div>

            {/* Section to manage blacklisted users */}
            <div className='manage-blacklist-section'>
                <h3>Manage Blacklisted Users</h3>
                <input
                    type="text"
                    placeholder="Enter user address"
                    value={blacklistAddress}
                    onChange={handleBlacklistAddressChange}
                    className='blacklist-input'
                />
                <button
                    className="btn btn-danger"
                    onClick={blacklistUser}
                    disabled={isProcessing || !blacklistAddress}
                >
                    {isProcessing ? 'Processing...' : 'Blacklist User'}
                </button>
                <button
                    className="btn btn-secondary"
                    onClick={unblacklistUser}
                    disabled={isProcessing || !blacklistAddress}
                >
                    {isProcessing ? 'Processing...' : 'Unblacklist User'}
                </button>
            </div>

            {/* Section to display NFT holders */}
            <div className='nft-holders-section'>
                <h3>NFT Holders</h3>
                {isProcessing ? (
                    <p>Loading NFT holders...</p>
                ) : holders.length > 0 ? (
                    <div className="holders-grid">
                        {holders.map((holder, index) => (
                            <div key={index} className="nft-card-admin">
                                <div className="holder-info">
                                    <p><strong>Tier:</strong> {holder.tier}</p>
                                    <p><strong>Token ID:</strong> {holder.tokenId}</p>
                                    <p><strong>Owner:</strong> {holder.owner}</p>
                                    {blacklistStatus[holder.owner.toLowerCase()] && <p className="blacklisted">Blacklisted</p>}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>No NFT holders found.</p>
                )}
            </div>
        </div>
    );
};

export default AdminVoting;
