// PieChart.jsx
import React from 'react';
import ferCoinLogo from '../fer_coin_logo.png';
import ethLogo from '../eth_logo.png';
import { formatAmount } from '../utils/formatUtils'; // Import formatAmount

const PieChart = ({ data, highlightedSlices, setHighlightedSlices, onSliceHover, title }) => {
    const total = data.reduce((sum, item) => sum + item.value, 0);
    const radius = 80;
    const lineLength = 10;
    const minPercentageForInnerLabel = 8;

    const sortedData = [...data].sort((a, b) => b.value - a.value);
    const largestSliceAngle = (sortedData[0].value / total) * 360;
    let startAngle = 90 - (largestSliceAngle / 2);

    // Function to get the appropriate logo based on label
    const getLogo = (label) => {
        if (label === "$FER") return ferCoinLogo;
        if (label === "$ETH") return ethLogo;
        return null;
    };

    return (
        <div className="pie-chart-container">
            <svg 
                viewBox="10 20 170 160" 
                className={'pie-chart highlightedcontent'}
                style={{filter: 'drop-shadow(rgba(0, 0, 0, 0.8) -0.3vw 0.3vw 0.1vw)'}}
            >
                {sortedData.map((item, index) => {
                    const angle = (item.value / total) * 360;
                    const percentage = (item.value / total) * 100;
                    const largeArcFlag = angle > 180 ? 1 : 0;
                    const endAngle = startAngle + angle;

                    const midAngle = startAngle + angle / 2;
                    const midAngleRad = (midAngle * Math.PI) / 180;

                    const isHighlighted = highlightedSlices.includes(item.label);
                    const highlightOffset = isHighlighted ? 10 : 0;

                    const labelInside = percentage >= minPercentageForInnerLabel;

                    const translateX = isHighlighted ? highlightOffset * Math.cos(midAngleRad) : 0;
                    const translateY = isHighlighted ? highlightOffset * Math.sin(midAngleRad) : 0;

                    const startX = 100 + (radius) * Math.cos(midAngleRad);
                    const startY = 100 + (radius) * Math.sin(midAngleRad);

                    const lineEndX = 100 + (radius + lineLength + highlightOffset) * Math.cos(midAngleRad);
                    const lineEndY = 100 + (radius + lineLength + highlightOffset) * Math.sin(midAngleRad);

                    let labelX, labelY;
                    if (labelInside) {
                        labelX = 100 + (radius * 0.6) * Math.cos(midAngleRad);
                        labelY = 100 + (radius * 0.6) * Math.sin(midAngleRad);
                    } else {
                        labelX = 100 + (radius + lineLength + 5 + highlightOffset) * Math.cos(midAngleRad);
                        labelY = 100 + (radius + lineLength + 5 + highlightOffset) * Math.sin(midAngleRad);
                    }

                    const pathData = [
                        `M 100 100`,
                        `L ${100 + radius * Math.cos((Math.PI * startAngle) / 180)} ${100 + radius * Math.sin((Math.PI * startAngle) / 180)}`,
                        `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${100 + radius * Math.cos((Math.PI * endAngle) / 180)} ${100 + radius * Math.sin((Math.PI * endAngle) / 180)}`,
                        `Z`
                    ].join(' ');

                    const scaleFactor = labelInside ? Math.min(1.8, Math.max(0.5, angle / 50)) : 1;

                    startAngle = endAngle;

                    return (
                        <g 
                            key={index} 
                            transform={`translate(${translateX}, ${translateY})`}
                            onMouseEnter={() => onSliceHover(item.label)}
                            onMouseLeave={() => onSliceHover(null)}
                        >
                            <path 
                                d={pathData} 
                                fill={item.color} 
                                className={isHighlighted ? 'highlightedslice' : 'slice'}
                            />
                            {!labelInside && (
                                <line
                                    x1={startX}
                                    y1={startY}
                                    x2={lineEndX}
                                    y2={lineEndY}
                                    stroke="#fff"
                                    strokeWidth="0.08vw"
                                />
                            )}
                            <text
                                x={labelX}
                                y={labelY}
                                textAnchor={labelInside ? "middle" : (midAngle > 90 && midAngle < 270 ? 'end' : 'start')}
                                dominantBaseline="middle"
                                fill="#fff"
                                
                                fontSize={labelInside 
                                    ? `clamp(6px, ${0.08 * radius * scaleFactor}px, 48px)` 
                                    : `clamp(10px, ${0.06 * radius}px, 32px)`}
                                    
                                fontWeight={isHighlighted ? '650' : '650'}
                                className={isHighlighted ? 'highlightedcontent' : ''}
                                style={{textShadow: ' -0.08vw 0.08vw 0px rgb(0, 0, 0)', filter: 'drop-shadow(0px 0px 0.5px black)'}}
                                pointerEvents="none"
                            >
                                {labelInside ? (
                                    <>
                                        <tspan x={labelX} dy="-0.5em">{item.label}</tspan>
                                        <tspan x={labelX} dy="1.2em">({percentage.toFixed(1)}%)</tspan>
                                        {/* Added line for the dynamic amount with formatAmount */}
                                        {isHighlighted && (
                                            <tspan 
                                                x={labelX} 
                                                dy="1.4em" // Adjusted dy to lower the text a bit
                                                fontSize="60%"
                                                fontWeight={"bold"} 
                                                fill="#fff"
                                                style={{textShadow: ' -0.04vw 0.04vw 0px rgb(0, 0, 0)', filter: 'none'}}
                                            >
                                                {formatAmount(item.value)}
                                            </tspan>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <tspan x={labelX} dy="-0.5em">{item.label}</tspan>
                                        <tspan x={labelX} dy="1.2em">({percentage.toFixed(1)}%)</tspan>
                                        {/* Added line for the dynamic amount with formatAmount */}
                                        {isHighlighted && (
                                            <tspan 
                                                x={labelX} 
                                                dy="1.4em" // Adjusted dy to lower the text a bit
                                                fontSize="60%" 
                                                fill="#fff"
                                            >
                                                {formatAmount(item.value)}
                                            </tspan>
                                        )}
                                    </>
                                )}
                            </text>
                            {labelInside && (
                                <image
                                    href={getLogo(item.label)}
                                    x={labelX - 12}
                                    y={labelY - 12}
                                    width="24"
                                    height="24"
                                    preserveAspectRatio="xMidYMid meet"
                                    pointerEvents="none"
                                />
                            )}
                        </g>
                    );
                })}

                <image
                    href={getLogo(title)}
                    x="84"
                    y="84"
                    width="32"
                    height="32"
                    preserveAspectRatio="xMidYMid meet"
                    style={{filter: 'drop-shadow(0.1vw 0vw 0.1vw black)'}}
                    pointerEvents="none"
                />
            </svg>
        </div>
    );
};

export default PieChart;
