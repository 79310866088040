import React, { useContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { WalletContext } from '../WalletContext';
import { formatAmount, formatDate, formatTimeLeft } from '../utils/formatUtils';



const ClaimTokens = () => {
  const { isAuthenticated, signer, contracts, userAddress } = useContext(WalletContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const [vestingDetails, setVestingDetails] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [batchSize, setBatchSize] = useState(10);
  const [totalVestingEntries, setTotalVestingEntries] = useState(0);

  const fetchTotalVestingEntries = async () => {
    try {
      const count = await contracts.vestingManagerContract.getVestingScheduleCount(userAddress);
      setTotalVestingEntries(Number(count.toString()));
    } catch (error) {
      console.error('Error fetching total vesting entries:', error);
    }
  };

  const fetchVestingDetails = async (start = startIndex, size = batchSize) => {
    setIsProcessing(true);
    try {
      if (start >= totalVestingEntries) {
        setVestingDetails([]);
        return;
      }
  
      const end = Math.min(start + size, totalVestingEntries);
      const details = await contracts.vestingManagerContract.getUserVestingDetails(userAddress, start, end);
      const [amounts, releaseTimes, claimed, claimTimes] = details;
  
      const formattedDetails = amounts.map((amount, index) => {
        const amountFormatted = formatAmount(parseFloat(ethers.formatEther(amount)));
        const releaseTime = Number(releaseTimes[index].toString());
        const secondsLeft = Math.max(0, releaseTime - Math.floor(Date.now() / 1000));
        const timeLeft = formatTimeLeft(secondsLeft);
  
        return {
          amount: amountFormatted,
          status: claimed[index] ? 'Claimed' : timeLeft,
          claimDate: claimed[index] ? formatDate(claimTimes[index]) : '',
          timeUntil: claimed[index] ? '' : timeLeft,
          claimed: claimed[index]
        };
      });
  
      setVestingDetails(formattedDetails);
    } catch (error) {
      console.error('Error fetching vesting details:', error);
      alert('Failed to fetch vesting details.');
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && contracts.vestingManagerContract) {
      fetchTotalVestingEntries();
    }
  }, [isAuthenticated, contracts.vestingManagerContract]);

  useEffect(() => {
    if (isAuthenticated && contracts.vestingManagerContract && totalVestingEntries > 0) {
      fetchVestingDetails();
    }
  }, [isAuthenticated, contracts.vestingManagerContract, totalVestingEntries, startIndex, batchSize]);

  const claimAllVestedTokens = async () => {
    setIsProcessing(true);
    try {
      const endIndex = Math.min(startIndex + batchSize, totalVestingEntries);
      const transaction = await contracts.vestingManagerContract.batchReleaseVestedTokens(startIndex, endIndex);
      await transaction.wait();
      alert('Vested tokens claimed successfully.');
      fetchVestingDetails();
    } catch (error) {
      console.error('Error claiming vested tokens:', error);
      alert('Failed to claim vested tokens.');
    } finally {
      setIsProcessing(false);
    }
  };

  const loadMore = () => {
    const newStartIndex = startIndex + batchSize;
    setStartIndex(newStartIndex);
    fetchVestingDetails(newStartIndex);
  };

  if (!isAuthenticated) return null;

  return (
    <>
      <div className='row'>
        <div className="col-sm">
          <h2 className='header smol vefted'>Vefted Fer</h2>
        </div>
      </div>
      <div className='row'>
        <div className="col-sm signin">
          <div className='veftedtable'>
            {vestingDetails.map((detail, index) => {
              let className = 'butn vefted ';
              if (detail.claimed) {
                className += 'disabled claimed';
              } else if (detail.status === 'Claimable') {
                className += 'claimable';
              } else {
                className += 'disabled claimcoming';
              }
  
              return (
                <span key={index} className={className}>
                  <span> {detail.amount} FER<br />
                    {detail.claimed ? (
                      <i className='textmedium'>claimed {detail.claimDate}</i>
                    ) : detail.status === 'Claimable' ? (
                      <>Claimable</>
                    ) : (
                      <>time until claim: {detail.timeUntil}</>
                    )}
                  </span>
                </span>
              );
            })}
          </div>
        </div>
      </div>
      <div className='row'>
        <div className="col-sm signin">
          <button
            type='button'
            className={isProcessing || !vestingDetails.some(detail => detail.status === 'Claimable') ? 'butn claimfer disabled' : 'butn claimfer'}
            onClick={claimAllVestedTokens}
            disabled={isProcessing || !vestingDetails.some(detail => detail.status === 'Claimable')}
          >
            {isProcessing ? 'Processing...' : vestingDetails.some(detail => detail.status === 'Claimable') ? 'Claim claimable' : <i className='textmedium darkgoldtext'>No claimable tokens</i>}
          </button>
        </div>
      </div>
      {startIndex + batchSize < totalVestingEntries && (
        <div className='row'>
          <div className="col-sm signin">
            <button onClick={loadMore} className='butn'>Load More</button>
          </div>
        </div>
      )}
    </>
  );
};

export default ClaimTokens;