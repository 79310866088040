import React, { useContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { WalletContext } from '../WalletContext';
import { formatDate } from '../utils/formatUtils';

const ClaimVotingRewards = () => {
  const { isAuthenticated, signer, userAddress, contracts } = useContext(WalletContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const [rewardDetails, setRewardDetails] = useState([]);
  const [currentSeason, setCurrentSeason] = useState(null);

 


  useEffect(() => {
    if (isAuthenticated) {
      fetchCurrentSeason();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (currentSeason !== null) {
      fetchVotingRewards();
    }

    
  }, [currentSeason]);

  const fetchCurrentSeason = async () => {
    try {
      const fetchCurrentSeason = await contracts.votingManagerContract.currentSeason();
      setCurrentSeason(Number(fetchCurrentSeason));
    } catch (error) {
      console.error('Error fetching current season:', error);
    }
  };


  const fetchVotingRewards = async () => {
  

    const seasonsToFetch = 5;


 
    let detailsArray = [];

    for (let seasonId = 1; seasonId <= seasonsToFetch; seasonId++) {
      try {
        const [votes, claimed, claimTime] = await contracts.votingManagerContract.getSeasonVotes(seasonId, userAddress);
        detailsArray.push({
          seasonId,
          votes: votes.toString(),
          claimable: !claimed && votes > 0 && currentSeason>seasonId,
          claimed,
          claimTime: claimed ? formatDate(claimTime) : 'N/A'
        });
      
      } catch (error) {
        console.error(`Error fetching details for season ${seasonId}:`, error);
      }
     
    }

    setRewardDetails(detailsArray);
  };

  const claimVotingRewards = async (seasonId) => {
    setIsProcessing(true);
    try {
      const transaction = await contracts.votingManagerContract.claimVotingRewards(seasonId, userAddress);
      await transaction.wait();
      alert('Voting rewards claimed successfully.');
      fetchVotingRewards();
    } catch (error) {
      console.error('Error claiming voting rewards:', error);
      alert('Failed to claim voting rewards.');
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchVotingRewards();
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) return null;

  return (
    <>
      <div className='row'>
        <div className="col-sm">
          <h2 className='header smol ferfcore'><a className='ff'>F</a>er <a className='ff'>F</a>core</h2>
        </div>
      </div>
      <div className='row'>
        <div className="col-sm signin">
          <div className='fcoretable'>
            {rewardDetails.map((detail, index) => {
              const statusClass = detail.claimed
                ? 'claimed disabled'
                : detail.claimable
                  ? 'claimable disabled'
                  : detail.seasonId === currentSeason
                    ? 'currentseason disabled'
                    : detail.seasonId < currentSeason && !detail.claimable
                      ? 'pastseason disabled'
                    : 'notstarted disabled';

              return (
                <React.Fragment key={index}>
                  <span
                    className={`butn seasonscore ${statusClass}`}
                    onClick={() => detail.claimable && !isProcessing && claimVotingRewards(detail.seasonId)}
                  >
                    
                 
                  
                    <span className='textmedium'> Feafon {detail.seasonId} {detail.seasonId === currentSeason ? "Ongoing!" : detail.seasonId<currentSeason ? "Ended" : ""} </span> 
                    <span className={`textlarge ${detail.seasonId<currentSeason && !detail.claimable || detail.seasonId ===currentSeason && detail.votes<1 ?  'graytext' : detail.seasonId>currentSeason ? 'darkgoldtext' :''}`}>
                        
                        {detail.claimable ? "Claim " + detail.votes + " FF" : ""} 
                        {detail.seasonId>currentSeason ? "Coming foon" : ""}
                        {detail.seasonId <=currentSeason && !detail.claimable ? detail.votes + " FF" : ""}
                        
                         {detail.claimed ? " Claimed" : ""} 
                        
                        </span>
                    {detail.claimed ?  "" : detail.claimable ? (
                      isProcessing ? 'Processing...' : ''
                    ) :  ""}
                  </span>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClaimVotingRewards;
