import React, { useRef, useEffect, useState } from 'react';
import './NameCard.css';
import tier1 from '../goldmember.png';
import tier2 from '../filvermember.png';
import tier3 from '../bronfmember.png';
import ferCoinLogo from '../fer_coin_logo.png';

const tierIcons = {
  1: tier1,
  2: tier2,
  3: tier3,
};

const getTierName = (tier) => {
  switch (tier) {
    case 1:
      return 'Gold';
    case 2:
      return 'Filver';
    case 3:
      return 'Bronf';
    default:
      return 'Unknown';
  }
};

const NameCard = ({ name, tier, eligible, onMint, isProcessing, isFerminator }) => {
  const plateRef = useRef(null);
  const [plateWidth, setPlateWidth] = useState(100);

  useEffect(() => {
    if (plateRef.current) {
      const width = plateRef.current.scrollWidth;
      setPlateWidth(width + 25);
    }
  }, [name.name]);

  const getPriceTagColor = (price, tier) => {
    if (tier === 1) {
      return 'url(#gold-gradient)';
    }

    if (tier === 2) {
      if (price < 1) {
        return '#818181';
      }
      return price < 2500 ? '#E0E0E0' : '#A9A9A9';
    }

    if (tier === 3) {
      if (price < 1) {
        return '#818181';
      }
      return '#8B4513';
    }

    return '#c3c3c3';
  };

  const displayPrice = name.price === '0' ? 'x1 Free' : `${name.price}`;
  const priceColor = getPriceTagColor(name.priceInt, tier);


  return (
    <div className={`name-card-wrapper ${!eligible ? 'disabled' : ''}`}>
      <div
        className={`name-card-container lifted ${!eligible ? 'disabled' : ''} ${isProcessing ? 'processing' : ''} ${isFerminator ? 'ferm-terminator' : ''}`}
        onClick={() => eligible && onMint(name.tokenId)}
      >
        <svg
          className="name-card-svg"
          xmlns="http://www.w3.org/2000/svg"
          height={120}
          width={plateWidth}
        >
          {tier === 1 && (
            <defs>
              <linearGradient id="gold-gradient" x1="0" y1="0" x2="1" y2="1">
                <stop offset="0%" stopColor="#FFD700" />
                <stop offset="100%" stopColor="#FFEC8B" />
              </linearGradient>
            </defs>
          )}
          {/* Adjusted lines */}
          <line
            x1={plateWidth / 2 - 85}
            y1={90}
            x2={plateWidth / 2}
            y2={33}
            stroke="#000"
            strokeWidth="2"
          />
          <line
            x1={plateWidth / 2 + 85}
            y1={90}
            x2={plateWidth / 2}
            y2={33}
            stroke="#000"
            strokeWidth="2"
          />

          {/* Conditional Tier Icon or Ferminator Icon */}
          {!isFerminator ? (
            <image
              href={tierIcons[tier]}
              x={plateWidth / 2 - 33}
              y={-6}
              width="66"
              height="66"
              alt={`${getTierName(tier)} Tier Icon`}
            />
          ) : (
            // Ferminator Icon (e.g., Silver Nail)
            <circle
              cx={plateWidth / 2}
              cy={35}
              r={6} // Further reduced radius for smaller size
              fill="black"
              stroke="black"
              strokeWidth="1"
            />
          )}
        </svg>

        <div
          className={`name-plate ${isFerminator ? 'ferm-plate' : getTierName(tier).toLowerCase()}`}
          ref={plateRef}
          style={{
            width: plateWidth,
            fontSize: name.name.length > 10 ? '0.8em' : '1em',
          }}
        >
          {!isFerminator &&
          <span className={`name-plate-title textmediumsmall ${getTierName(tier).toLowerCase()}text ${isFerminator ? 'ferm-text' : `${getTierName(tier).toLowerCase()}text`}`}>
            FER
          </span>
          }
          <span
            className={`name-text textmedium ${isFerminator ? 'ferm-text' : `${getTierName(tier).toLowerCase()}text`} ${isFerminator ? 'ferm-text' : ''}`}
            title={name.name}
          >
            {name.name}
          </span>
        </div>

        {/* Conditionally render Price Tag */}
        {!isFerminator && (
          <svg
            className="price-tag"
            width="55"
            height="26"
            viewBox="0 0 55 26"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="55"
              height="26"
              rx="5"
              ry="5"
              fill={priceColor}
            />
            <text
              x={name.price !== '0' ? '20' : '27'}
              y="13"
              dominantBaseline="middle"
              textAnchor="middle"
              fill="#fff"
              fontSize="11"
              fontWeight="bold"
              className='darkbgsmall'
            >
              {displayPrice}
            </text>

            {name.price !== '0' && (
              <image
                href={ferCoinLogo}
                x="32"
                y="5"
                width="14"
                height="14"
              />
            )}
            <circle cy="13" r="2" fill="#000" cx="4" />
          </svg>
        )}
      </div>
    </div>
  );
};

export default NameCard;
