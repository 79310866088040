import React from 'react';
import { ETHIcon } from '../utils/formatUtils';

const PresaleProgress = ({ dynamicValues, constantTokenomics, totalTokenSupply }) => {
  const calculateMaxETH = () => {
    if (!constantTokenomics.initialRate || !totalTokenSupply) return 'loading...';

    const initialRate = Number(constantTokenomics.initialRate);
    const totalTokenPercentage =
      50 + // Immediate Release
      50 + // Vested Release
      Number(constantTokenomics.memeLPPercent) +
      Number(constantTokenomics.memeVotingPercent) +
      Number(constantTokenomics.memeCexListingPercent) +
      Number(constantTokenomics.memeMarketingPercent) +
      Number(constantTokenomics.memeFoundersPercent);

    const totalTokenPercentageRatio = totalTokenPercentage / 100;
    const tokensDistributedPerETH = initialRate * totalTokenPercentageRatio;
    const maxETH = totalTokenSupply / tokensDistributedPerETH;

    return maxETH;
  };

  const maxETH = calculateMaxETH();
  const currentETH = parseFloat(dynamicValues.ethRaised || '0');
  const progressPercentage = maxETH > 0 ? (currentETH / maxETH) * 100 : 0;

  // Define thresholds for dynamic positioning
  const lowThreshold = 6; // Below 10%
  const highThreshold = 100; // Above 90%

  // Calculate positioning based on progressPercentage
  let leftPosition = `${progressPercentage}%`;
  let transformValue = 'translate(-50%, -50%)';

  if (progressPercentage < lowThreshold) {
    // Position slightly inside the progress bar to the right
    leftPosition = `0%`;
    transformValue = 'translate(-0%, -50%)';
  } else if (progressPercentage > highThreshold) {
    // Position slightly inside the progress bar to the left
    leftPosition = `${highThreshold}%`;
    transformValue = 'translate(-100%, -50%)';
  }

  const progressContentStyles = {
    position: 'absolute',
    top: '50%',
    left: leftPosition,
    transform: transformValue,
    color: '#fff',
    fontSize: 'clamp(8px, 0.9vw, 14px)',
    whiteSpace: 'nowrap',
    textShadow: '-0.05vw 0.05vw 0px black',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '0.2em',
 
    padding: '0.1em 0.3em',
    borderRadius: '0.2em',
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: progressPercentage < lowThreshold ? 'left' : progressPercentage > highThreshold ? 'right' : 'center',
  };

  const containerStyles = {
    marginTop: '-3%',
    width: '100%',
    maxWidth: '100%',
    marginBottom: 'clamp(8px, 1.4vw, 22px)',
    padding: '0.4vw',
    paddingLeft: '0.8vw',
    paddingRight: '0.8vw',
    backgroundColor: 'rgb(28 10 0 / 42%)',
    borderRadius: '0.4vw',
    boxShadow: '-0.2vw 0.2vw 0px 0.05vw rgba(0, 0, 0, 0.7)',
    border: '0.1vw solid  rgba(0, 0, 0, 0.7)',
    color: '#ffffff',
    transition: 'all 0.15s ease-in-out',
    background: `
        linear-gradient(
            20deg,
            #ccaa00 0%,
            #ffcc00 25%,
            #e6b800 50%,
            #ffea8f 75%,
            #ccaa00 100%
        ),
        linear-gradient(
            20deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 30%,
            rgba(0, 0, 0, 0.2) 50%,
            rgba(0, 0, 0, 0) 69%,
            rgba(0, 0, 0, 0) 100%
        )`,
  };

  const headerStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0.2vw',
    fontSize: 'clamp(10px, 1vw, 24px)',
    fontWeight: '900',
    textShadow: '-0.09vw 0.09vw 0px black',
  };

  const progressBarContainerStyles = {
    position: 'relative',
    width: '100%',
    height: 'clamp(12px, 1.4vw, 22px)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '0.4vw',
    overflow: 'visible', // Allow content to overflow
    border: '0.1vw solid #000',
    boxShadow: 'inset -0.1vw 0.1vw 0.2vw rgba(0, 0, 0, 0.5)',
  };

  const progressBarStyles = {
    height: '100%',
    backgroundColor: '#4db431',
    transition: 'width 500ms ease-in-out',
    width: `${Math.min(progressPercentage, 100)}%`,
    borderRadius: '0.3vw',
  };

  const statsStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '0.2vw',
    fontSize: 'clamp(8px, 0.9vw, 14px)',
    color: '#fff',
    textShadow: '-0.05vw 0.05vw 0px black',
  };

  return (
    <div style={containerStyles}>
      <div style={headerStyles}>
        <span className='textmedium darkbg'>Prefale Progreff</span>
        {/* Percentage removed from header */}
      </div>

      <div style={progressBarContainerStyles}>
        <div style={progressBarStyles} />
        <span className = 'textmedium' style={progressContentStyles}>
          <ETHIcon style={{ marginRight: '0.15em' }} />
         
          {currentETH.toFixed(2)} <span className='textsmall'> ({progressPercentage.toFixed(1)}%)
          </span>
        </span>
      </div>

      <div style={statsStyles}>
        <span className="textmedium">
          {/* Optionally remove or keep this section */}
          {/* <ETHIcon style={{ marginRight: '0.15em' }} />{currentETH.toFixed(2)} */}
        </span>
        <span className="textmedium darkbg">
          Goal: <ETHIcon style={{ marginRight: '0.15em', position: 'relative', right: '2%', bottom: '6%' }} />{maxETH}
        </span>
      </div>
    </div>
  );
};

export default PresaleProgress;
