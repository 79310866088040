// RegisterNameCard.jsx

import React, { useState, useRef, useEffect } from 'react';
import './NameComponentCSS.css'; // Import the shared CSS
import tier1 from '../goldmember.png';
import tier2 from '../filvermember.png';
import tier3 from '../bronfmember.png';
import ferCoinLogo from '../fer_coin_logo.png'; // Import the ferCoinLogo
import { formatAmount, formatCooldown, applyFerSpeak } from '../utils/formatUtils';

const tierIcons = {
  2: tier2,
  3: tier3,
};

const getTierName = (tier) => {
  switch (tier) {
    case 2:
      return 'filver';
    case 3:
      return 'bronf';
    default:
      return 'unknown';
  }
};

const RegisterNameCard = ({
  newName,
  setNewName,
  newTier,
  setNewTier,
  newPrice,
  setNewPrice,
  handleRegister,
  isAddingName,
  allowedPricesPerTier, // Updated prop
  holdsTier1,
  listingCooldownTimeLeft, // Initial cooldown time in seconds
}) => {
  const plateRef = useRef(null);
  const [plateWidth, setPlateWidth] = useState(100); // Initial width
  const [cooldown, setCooldown] = useState(listingCooldownTimeLeft || 0); // Local cooldown state

  // Hidden span to measure text width
  const hiddenSpanRef = useRef(null);

  // Effect to set the plate width based on the name
  useEffect(() => {
    if (hiddenSpanRef.current) {
      hiddenSpanRef.current.textContent = newName || 'Enter Name';
      const width = hiddenSpanRef.current.scrollWidth;
      if (width < 50) {
        setPlateWidth(100);
      } else {
        setPlateWidth(width + 50); // Adjust padding as needed
      }
    }
  }, [newName]);

  // Effect to set the initial price to the lowest available price when the tier changes
  useEffect(() => {
    if (newTier && allowedPricesPerTier[newTier] && allowedPricesPerTier[newTier].length > 0) {
      const sortedPrices = [...allowedPricesPerTier[newTier]].sort((a, b) => a - b);
      setNewPrice(sortedPrices[0]);
    } else {
      setNewPrice(0); // Default to free if no prices are available
    }
  }, [newTier, allowedPricesPerTier, setNewPrice]);

  // Countdown Timer Effect
  useEffect(() => {
    // Initialize local cooldown state with prop
    setCooldown(listingCooldownTimeLeft || 0);

    if (listingCooldownTimeLeft > 0) {
      const interval = setInterval(() => {
        setCooldown((prevCooldown) => {
          const newCooldown = prevCooldown - 5;
          if (newCooldown <= 0) {
            clearInterval(interval);
            return 0;
          }
          return newCooldown;
        });
      }, 5000); // Decrement every 5 seconds

      // Cleanup on unmount or when listingCooldownTimeLeft changes
      return () => clearInterval(interval);
    }
  }, [listingCooldownTimeLeft]);

  // Coordinates and dimensions for SVG elements
  const svgHeight = 120; // Must match the SVG height in CSS
  const nailRadius = 33; // Increased from 25 to 33 (33% larger)

  // Coordinates for the nail (centered)
  const nailX = plateWidth / 2;
  const nailY = 30; // Position the nail a bit below the top of the SVG

  // Strings originate from the name plate
  const stringStartY = svgHeight; // Bottom of the SVG (120px)
  const stringEndY = nailY + nailRadius; // Bottom point where strings attach to the nail (30 + 33 = 63px)

  // Define increased offsets for more tilt
  const stringOffsetX = 85; // Increased horizontal offset for wider separation
  const stringLeftX = plateWidth / 2 - stringOffsetX;
  const stringRightX = plateWidth / 2 + stringOffsetX;

  // Both strings attach to the same nail point
  const nailAttachmentX = nailX;
  const nailAttachmentY = stringEndY;

  // Helper function to apply Fer-speak transformation
 
  // Function to cycle through tiers (2 -> 3 -> 2 ...)
  const cycleTier = () => {
    if (newTier === 2) setNewTier(3);
    else setNewTier(2);
  };

  // Function to cycle through available prices for the current tier
  const cyclePrice = () => {
    if (!newTier || !allowedPricesPerTier[newTier] || allowedPricesPerTier[newTier].length === 0) return;

    const sortedPrices = [...allowedPricesPerTier[newTier]].sort((a, b) => a - b);
    const currentIndex = sortedPrices.indexOf(newPrice);
    const nextIndex = (currentIndex + 1) % sortedPrices.length;
    setNewPrice(sortedPrices[nextIndex]);
  };

  /**
   * Determines the fill color for the price tag based on price and tier.
   * @param {number} price - The price as a number.
   * @param {number} tier - The tier number.
   * @returns {string} - The corresponding color.
   */
  const getPriceTagColor = (price, tier) => {
  
    if (tier === 1) {
      // Gold tier: Shiny gold gradient
      return 'url(#gold-gradient)';
    }

    if (tier === 2) {
      // Filver Tier: Silver colors based on price
      if (price < 1) {
        return '#818181'; // Light Silver for free
      }
      return price < 2500 ? '#E0E0E0' : '#A9A9A9'; // Light Silver or Dark Silver
    }

    if (tier === 3) {
      // Bronf Tier: Bronze colors based on price
      if (price < 1) {
        return '#818181'; // Light Bronze for free
      }
      return price < 600 ? '#8B4513' : 'rgb(99 41 0)'; // Light Bronze or Dark Bronze
    }

    // Default color for unknown tiers
    return '#c3c3c3';
  };

  const handleNameChange = (e) => {
    const ferSpokenText = applyFerSpeak(e.target.value);
    setNewName(ferSpokenText);
  };

  const displayPrice = newPrice === 0 ? '?' : `${formatAmount(newPrice)}`;
  const priceColor = getPriceTagColor(newPrice, newTier);

  return (
    <>
      {/* Display cooldown if active */}
   
      
      <h3 className='textlarge'>Fell FERFona</h3>

      <div className={`register-name-card-wrapper ${isAddingName ? 'disabled' : ''}`}>
        <button
          className={`butn listname ${isAddingName || !holdsTier1 ? 'disabled' : ''}`}
          onClick={handleRegister}
          disabled={
            isAddingName ||
            cooldown >0 ||
            !newTier ||
            ((newTier === 2 || newTier === 3) && !newPrice)
          }
        >
          {!holdsTier1 && (
            <img
              src={tier1}
              alt="Gold Tier Required"
              width="66"
              height="66"
              className="register-tier-image required"
              draggable='false'
            />
          )}

          {isAddingName
            ? '...'
            : cooldown>=1 ? formatCooldown(cooldown) : '-->'}
        </button>
        
        <div className="name-card-container register">
          {/* Hidden span for measuring text width */}
          <span
            ref={hiddenSpanRef}
            style={{
              position: 'absolute',
              visibility: 'hidden',
              whiteSpace: 'nowrap',
              fontSize: '1em',
              fontWeight: 'normal',
              fontFamily: 'inherit',
            }}
          ></span>

          {/* SVG for strings and nail */}
          <svg
            className="name-card-svg"
            xmlns="http://www.w3.org/2000/svg"
            height={svgHeight}
            width={plateWidth}
          >
            {/* Left String */}
            <line
              x1={plateWidth / 2 - 85}
              y1={90} // Adjusted from 120 to 90
              x2={plateWidth / 2}
              y2={33} // Adjusted from 63 to 33
              stroke="#000"
              strokeWidth="2"
            />
            <line
              x1={plateWidth / 2 + 85}
              y1={90} // Adjusted from 120 to 90
              x2={plateWidth / 2}
              y2={33} // Adjusted from 63 to 33
              stroke="#000"
              strokeWidth="2"
            />

            {/* Nail (Tier Icon) */}
            <image
              href={tierIcons[newTier] || tierIcons[2]} // Default to Filver if no tier selected
              x={plateWidth / 2 - 33}
              y={-6} // Adjusted from 25 to 10
              width="66"
              height="66"
              alt={`${getTierName(newTier).toUpperCase()} Tier Icon`}
              onClick={cycleTier}
              className='register-tier-image'
            />

            {/* Define gold gradient if tier 1 is selected (optional, since RegisterNameCard doesn't handle tier 1) */}
            {newTier === 1 && (
              <defs>
                <linearGradient id="gold-gradient" x1="0" y1="0" x2="1" y2="1">
                  <stop offset="0%" stopColor="#FFD700" />
                  <stop offset="100%" stopColor="#FFEC8B" />
                </linearGradient>
              </defs>
            )}

            {/* Price Tag SVG */}
          </svg>
          
          <svg
            className="price-tag register-tag"
            width="55"
            height="26"
            viewBox="0 0 55 26"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              cursor: 'pointer', // Indicate that it's clickable
            }}
            onClick={cyclePrice} // Add click handler to toggle price
          >
            <rect width="55" height="26" rx="5" ry="5" fill={priceColor} />
            <text
                x='20'
                y="13"
                dominantBaseline="middle"
                textAnchor="middle"
                fill="#fff"
                fontSize="11"
                fontWeight="bold"
                className='darkbgsmall'
                >
                {displayPrice}
                </text>

            {newPrice !== 0 && (
              <image href={ferCoinLogo} x="32" y="6" width="14" height="14" />
            )}
            <circle cy="13" r="2" fill="#000" cx="4" />
          </svg>

          {/* Plate with the name input */}
          <div
            className={`name-plate input ${getTierName(newTier)}`}
            ref={plateRef}
            style={{
              width: plateWidth,
              fontSize: newName.length > 10 ? '0.8em' : '1em',
            }}
          >
            <span className={`name-plate-title  ${getTierName(newTier).toLowerCase()}text ${getTierName(newTier)}`}>FER</span>
            <input
              type="text"
              value={newName}
              onChange={handleNameChange}
              placeholder=""
              className={`name-input textmedium ${getTierName(newTier)}text`}
              maxLength={14}
              spellCheck={false}
              required
             
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterNameCard;
