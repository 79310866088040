// src/components/FolioHeader.js
import React, { useContext, useMemo } from 'react';
import { DataContext } from '../DataProvider';
import { ethers } from 'ethers';
import { formatAmount } from '../utils/formatUtils';
import ferCoinLogo from '../fer_coin_logo.png';
import './Folio.css'; // Ensure the path is correct

const FolioHeader = () => {
  const { ferBalance, totalVested, nftData, userMainName } = useContext(DataContext);

  // Compute safeHighestTier locally
  const safeHighestTier = useMemo(() => {
    if (!nftData || nftData.length === 0) return 'NOT A';

    // Define tier order and priority
    const tierOrder = ['GOLD', 'FILVER', 'BRONF', 'BAFIC'];
    const tierPriorityMap = {
      'NOT A': 0,
      'BAFIC': 1,
      'BRONF': 2,
      'FILVER': 3,
      'GOLD': 4,
    };

    const userTiers = new Set(['NOT A']);

    nftData.forEach(nft => {
      if (nft && typeof nft.tier === 'number') {
        const tierName = tierOrder[nft.tier - 1];
        if (tierName) {
          userTiers.add(tierName);
        }
      }
    });

    const tokenBalanceTier = parseFloat(ferBalance) >= 1 ? 'BAFIC' : 'NOT A';
    userTiers.add(tokenBalanceTier);

    const sortedTiers = Array.from(userTiers).sort((a, b) => tierPriorityMap[b] - tierPriorityMap[a]);

    return sortedTiers[0] || 'NOT A';
  }, [nftData, ferBalance]);

  return (
    <div className="overview-top">
      <div className="dashboard-header">
        <div className={`user-info ${safeHighestTier.toLowerCase()}text ${userMainName.length >10 ? 'textlarge' : 'titletext mediumlarge'} `}>
          <div className={`user-name ${safeHighestTier.toLowerCase()}text `}>
            <span className="fertext folio">FER </span>
            {userMainName || '(No FERFona)'}
          </div>
        </div>
      </div>
      <div style={{ position: 'absolute', right: '0' }}>
        <i style={{ width: '100%', textAlign: 'right' }}>
          {totalVested > 0 && (
            <span className="overview-vested-fer">
              +
              <img
                src={ferCoinLogo}
                style={{
                  height: '0.8em',
                  filter: 'drop-shadow(0.05vw 0.05vw 2px black)',
                  verticalAlign: 'middle',
                  position: 'relative',
                  marginRight: '1%',
                  bottom: '0.1em',
                }}
                alt="Fer Coin Logo"
              />
              {formatAmount(totalVested)} vefted
            </span>
          )}
        </i>
        <h1 className={`overview-total-fer titletext mediumlarge ${safeHighestTier.toLowerCase()}text`}>
          <img
            src={ferCoinLogo}
            style={{
              height: '0.8em',
              filter: 'drop-shadow(0.05vw 0.05vw 2px black)',
              verticalAlign: 'middle',
              position: 'relative',
              marginRight: '2%',
              top: '0.05em',
            }}
            alt="Fer Coin Logo"
          />
          {formatAmount(ferBalance)}
        </h1>
      </div>
    </div>
  );
};

export default FolioHeader;
