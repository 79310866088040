import React, { useState, useContext } from 'react';
import { WalletContext } from '../WalletContext'; // Make sure this path is correct
import config from '../config';

const TogglePresale = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const {isAuthenticated, userAddress,
   contracts} = useContext(WalletContext);
  const isAdmin = userAddress === config.adminAddress; // Check if the user is the admin

  const connectWalletAndTogglePresale = async () => {
    if (!isAdmin || !isAuthenticated) return; // Early return if not admin, for extra safety

    setIsProcessing(true);
    try {
 

      await contracts.presaleManagerContract.togglePreSale();
      alert('Pre-sale toggled successfully.');
    } catch (error) {
      console.error('Error toggling pre-sale:', error);
      alert('Failed to toggle pre-sale. Make sure you are the owner.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Only render the button if the user is the admin
  if (!isAdmin) return null;

  return (
    <button 
    className='btn btn-primary'
      onClick={connectWalletAndTogglePresale} 
      disabled={isProcessing}
    >
      {isProcessing ? 'Processing...' : 'Toggle Pre-Sale'}
    </button>
  );
};

export default TogglePresale;
