import React from 'react';
import { formatAmount, formatAmountPot } from '../../utils/formatUtils';
import { FERIcon } from '../../utils/formatUtils';
import { color } from 'chart.js/helpers';

const Pot = ({ currentSeason, seasonVotingPot }) => {
  const styles = {
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      lineHeight: '1.1',
      color:'#fff'
    },
    textContainer: {
      textAlign: 'center',
      color: '#fff',
      position: 'absolute',
      zIndex: '2',
      padding: '8px',
      top:'13%',
      lineHeight:'0.8'
    },
    svgContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };

  return (
    <div style={styles.container}>
         <div className='textmediumsmall goldtext'>
          Feafon {currentSeason} Pot
        </div>
      <div style={styles.textContainer}>
     

<div className='textlarge'><FERIcon style={{transform:'scale(0.8)'}} /></div><span className='textlarge goldtext'>{formatAmountPot(seasonVotingPot)}</span>
      </div>
      
      <div style={styles.svgContainer}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          preserveAspectRatio="none"
          width="100%"
          height="100%"
          viewBox="0 0 1920 1080"
          aria-label="Top Hat"
        >
          <defs>
            <g id="Layer0_0_FILL">
              <path
                fill="#000000"
                stroke="none"
                d="
                M 1879.9 1039.8
                L 1879.9 858 1510.9 858 1510.9 37.9 392.85 37.9 392.85 858 23.85 858 23.85 1039.8 1879.9 1039.8 Z"
              />
            </g>
          </defs>
          <g transform="matrix( 1, 0, 0, 1, 0,0) ">
            <use xlinkHref="#Layer0_0_FILL" />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Pot;