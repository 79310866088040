import React, { useState, useContext } from 'react';
import { ethers } from 'ethers';
import { WalletContext } from '../WalletContext';
import { DataContext } from '../DataProvider';
import { formatAmount } from '../utils/formatUtils';
import { FERIcon } from '../utils/formatUtils';

const Alerts = ({ handleMenuClick, disconnectWallet, connectWallet, setIsExpanded, setShowMintPopup, handleSectionChange, toggleFolio, isFolio }) => {
  const { 

    userAddress,
  
   contracts



 } = useContext(WalletContext);

 const {  

  refreshData,
  hasClaimableTokens,
  totalClaimableTokens,
  claimableRewards,
  hasClaimableRewards,
  canMintName,
  hasNameSellRewards,
  hasNameCommunitySellRewards,
  nameCommunitySellRewards,
  nameSellRewards,
  loadFolioData
    
} = useContext(DataContext);


  const [isRewardsMinimized, setIsRewardsMinimized] = useState(true);
  const [isCommunityRewardsMinimized, setIsCommunityRewardsMinimized] = useState(true);
  const [isNameSellRewardsMinimized, setIsNameSellRewardsMinimized] = useState(true);
  const [isTokensMinimized, setIsTokensMinimized] = useState(true);
  const [isMintingMinimized, setIsMintingMinimized] = useState(true);
  
  const [isProcessingRewards, setIsProcessingRewards] = useState(false);
  const [isClaimingCommunityRewards, setIsClaimingCommunityRewards] = useState(false);
  const [isClaimingOwnSalesRewards, setIsClaimingOwnSalesRewards] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isProcessingMint, setIsProcessingMint] = useState(false);



  const claimRewards = async () => {
    setIsProcessingRewards(true);
    try {
      const transaction = await contracts.memeNFTContract.claimRewards();
      await transaction.wait();
      await loadFolioData();
      await refreshData();

      alert('Successfully claimed New Member rewardf!');
    } catch (error) {
      console.error('Error claiming rewardf:', error);
      alert(`Failed to claim New Member Rewardf: ${error.message}`);
    } finally {
      setIsProcessingRewards(false);
    }
  };

  const claimCommunityRewards = async () => {
    setIsClaimingCommunityRewards(true);
    try {
      const transaction = await contracts.nameNFTContract.claimRewards();
      await transaction.wait();
      await loadFolioData();
      await refreshData();
      alert('Successfully claimed community rewards!');
    } catch (error) {
      console.error('Error claiming community rewards:', error);
      alert(`Failed to claim community rewards: ${error.message}`);
    } finally {
      setIsClaimingCommunityRewards(false);
    }
  };

  const claimOwnSalesRewardsHandler = async () => {
    setIsClaimingOwnSalesRewards(true);
    try {
      const transaction = await contracts.nameNFTContract.claimOwnSalesRewards();
      await transaction.wait();
      await loadFolioData();
      await refreshData();
      alert('Successfully claimed own sales rewards!');
    } catch (error) {
      console.error('Error claiming own sales rewards:', error);
      alert(`Failed to claim own sales rewards: ${error.message}`);
    } finally {
      setIsClaimingOwnSalesRewards(false);
    }
  };

  const claimAllVestedTokens = async () => {
    setIsProcessing(true);
    try {
      const vestingCount = await contracts.vestingManagerContract.getVestingScheduleCount(userAddress);
      const transaction = await contracts.vestingManagerContract.batchReleaseVestedTokens(0, vestingCount);
      await transaction.wait();
      await loadFolioData();
      await refreshData();
      alert('Successfully claimed vested tokens!');
    } catch (error) {
      console.error('Error claiming vested tokens:', error);
      alert(`Failed to claim vested tokens: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };
  return (
    <div className={`alerts ${isFolio ? 'maximized' : ''}`}>
      {/* Recruit Rewards Notification */}
      {hasClaimableRewards && (
        <div className='notification'>
          <span 
            className={`alert-icon ${isFolio ? '' : (isRewardsMinimized ? 'minimized' : '')}`}
            onClick={() => !isFolio && setIsRewardsMinimized(!isRewardsMinimized)}
          >
            {isFolio ? '!' : (isRewardsMinimized ? '!' : '−')}
          </span>
          
          {(isFolio || !isRewardsMinimized) && (
            <button
              className={`butn medium ${isProcessingRewards ? 'disabled' : ''}`}
              onClick={() => {
                claimRewards();
                if(!isFolio){
                  setIsExpanded(true);
            
                }
              }}
              disabled={isProcessingRewards}
            >
              <span className='textmediumsmall goldtext'>New Member Rewardf</span>
              {isProcessingRewards
                ? 'Processing...'
                : `Claim ${
                    claimableRewards > 1000 
                      ? formatAmount(claimableRewards) 
                      : Number(claimableRewards).toFixed(0)
                  }`}<FERIcon style={{bottom: '-0.1em', marginLeft: '0.1em'}} />
            </button>
          )}
        </div>
      )}
  
      {/* Community Rewards Notification */}
      {hasNameCommunitySellRewards && (
        <div className='notification'>
          <span 
            className={`alert-icon ${isFolio ? '' : (isCommunityRewardsMinimized ? 'minimized' : '')}`}
            onClick={() => !isFolio && setIsCommunityRewardsMinimized(!isCommunityRewardsMinimized)}
          >
            {isFolio ? '!' : (isCommunityRewardsMinimized ? '!' : '−')}
          </span>
          
          {(isFolio || !isCommunityRewardsMinimized) && (
            <button
              className={`butn medium ${isClaimingCommunityRewards ? 'disabled' : ''}`}
              onClick={() => {
                claimCommunityRewards();
                if(!isFolio){
                  setIsExpanded(true);
            
                }
              }}
              disabled={isClaimingCommunityRewards}
            >
              <span className='textmediumsmall goldtext'>Community Rewards</span>
              {isClaimingCommunityRewards
                ? 'Processing...'
                : `Claim ${
                    nameCommunitySellRewards > 1000 
                      ? formatAmount(nameCommunitySellRewards) 
                      : Number(nameCommunitySellRewards).toFixed(0)
                  }`} <FERIcon style={{bottom: '-0.1em', marginLeft: '0.1em'}} />
            </button>
          )}
        </div>
      )}
  
      {/* Own Sales Rewards Notification */}
      {hasNameSellRewards && (
        <div className='notification'>
          <span 
            className={`alert-icon ${isFolio ? '' : (isNameSellRewardsMinimized ? 'minimized' : '')}`}
            onClick={() => !isFolio && setIsNameSellRewardsMinimized(!isNameSellRewardsMinimized)}
          >
            {isFolio ? '!' : (isNameSellRewardsMinimized ? '!' : '−')}
          </span>
          
          {(isFolio || !isNameSellRewardsMinimized) && (
            <button
              className={`butn medium ${isClaimingOwnSalesRewards ? 'disabled' : ''}`}
              onClick={() => {
                claimOwnSalesRewardsHandler();
                if(!isFolio){
                  setIsExpanded(true);
                
                }
              }}
              disabled={isClaimingOwnSalesRewards}
            >
              <span className='textmediumsmall goldtext'>Own Sales Rewards</span>
              {isClaimingOwnSalesRewards
                ? 'Processing...'
                : `Claim ${
                    nameSellRewards > 1000 
                      ? formatAmount(nameSellRewards) 
                      : Number(nameSellRewards).toFixed(0)
                  }`} <FERIcon style={{bottom: '-0.1em', marginLeft: '0.1em'}} />
            </button>
          )}
        </div>
      )}
  
      {/* Vested Tokens Notification */}
      {hasClaimableTokens && (
        <div className='notification vefting'>
          <span 
            className={`alert-icon ${isFolio ? '' : (isTokensMinimized ? 'minimized' : '')}`}
            onClick={() => !isFolio && setIsTokensMinimized(!isTokensMinimized)}
          >
            {isFolio ? '!' : (isTokensMinimized ? '!' : '−')}
          </span>
          {(isFolio || !isTokensMinimized) && (
            <button
              className={`butn medium ${isProcessing ? 'disabled' : ''}`}
              onClick={() => {
                claimAllVestedTokens();
                if(!isFolio){
                  setIsExpanded(true);
              
                }
              }}
              disabled={isProcessing}
            >
              <span className='textmediumsmall goldtext'>Vesting</span>
              {isProcessing 
                ? 'Processing...' 
                : `Redeem ${formatAmount(totalClaimableTokens)}` 
              } <FERIcon style={{bottom: '-0.1em', marginLeft: '0.1em'}} />
            </button>
          )}
        </div>
      )}
  
      {/* Mint Name Notification */}
      {canMintName && (
        <div className='notification ferfona'>
          <span
            className={`alert-icon ${isFolio ? '' : (isMintingMinimized ? 'minimized' : '')}`}
            onClick={() => !isFolio && setIsMintingMinimized(!isMintingMinimized)}
          >
            {isFolio ? '!' : (isMintingMinimized ? '!' : '−')}
          </span>
          {(isFolio || !isMintingMinimized) && (
            <button
              className={`butn medium ${isProcessingMint ? 'disabled' : ''}`}
              onClick={() => {
                handleSectionChange('ferfona');
                if(isFolio){
                  toggleFolio();
                }
              }}
              disabled={isProcessingMint}
            >
              Free FERFona!
            </button>
          )}
        </div>
      )}
    </div>
  );
  
};

export default Alerts;
