// src/components/VotingComponent.jsx

import React, { useState, useEffect } from 'react';
import useVotingComponentLogic from './VotingComponentLogic';
import VotingStatsAll from './VotingStatsAll';
import './VotingComponentCSS.css';
import { shortenAddress, formatEther, applyFerSpeak, applyFerSpeakForg } from '../../utils/formatUtils';
import HeartStats from './HeartStats';
import HatWithNames from './HatWithNames';
import UserSection from './UserSection';
import Hourglass from './Hourglass';
import Pot from './Pot';

const VotingComponent = () => {
  const {
    // State Variables
    availableNames,
    selectedNameTokenId,
    candidateNickname,
    pointsToAllocate,
    seasonId,
    currentSeason,
    isProcessing,
    seasonStatus,
    timeLeft,
    claimableRewards,
    claimableRewardsDetails,
    valuePerPoint,
    isLoadingValuePerPoint,
    isClaimingRewards,
    totalPoints,
    availablePoints,
    usedPoints,
    seasons,
    votingHistory,
    rewardsSummary,
    blacklistStatus,
    totalVotesReceived, // New
    votesPerName, // New
    isLoadingVotesReceived, // New
    isVotingDataLoading,
    votingData,
    selectedCandidate,
    // Loading States
    isLoadingSeasonData,
    isLoadingAvailableNames,
    isLoadingTotalPoints,
    isLoadingSeasons,
    isLoadingBlacklistStatus,
    isLoadingClaimableRewards,
    isLoadingRewardsSummary,
    isLoadingVotingHistory,
    participationPercent,
    // Context Variables
    presaleEnded,
    seasonActive,
    userAddress,
    userMainName,
    inputName, // Add this
    isNameValid, // Add this
    setInputName, // Add this
    timeLeftPercent,
    // Functions
    handleAllocatePoints, // Backend function to cast votes
    handleClaimReward,
    handleClaimAllRewards,
    actualClaimableRewards,
    estimatedRewards,
    setPointsToAllocate,
    handleRowClick, // Backend handler from the hook
    votesReceivedPerSeason,
    handleClaimAllRewardsForSeason,
    currentSeasonTotalVotes,
    userVotesInCurrentSeason,
    rewardsPerSeason,
    
  } = useVotingComponentLogic();

  // Additional calculations
  const currentSeasonData = seasons.find(season => season.id === Number(currentSeason)) || "loading...";

  // Adjust maxSelectablePoints to allow multiple selections
  const calculatedMaxSelectablePoints = Math.min(
    availablePoints,
    Math.max(Math.round(totalPoints * 0.2), 2) // Ensures at least 2 points can be allocated
  );

  const maxSelectablePoints = calculatedMaxSelectablePoints;

  const pointsArray = Array.from({ length: maxSelectablePoints }, (_, i) => i + 1);

  // Dynamic colors based on participation rate
  const progressBarColor =
    participationPercent > 75
      ? '#4caf50'
      : participationPercent > 50
      ? '#ff9800'
      : '#f44336';

  // Manage hearts state
  const [heartsArray, setHeartsArray] = useState([]);
  const [maxSelected, setMaxSelected] = useState(false);

  // Generate heartsArray based on totalPoints and usedPoints
  const generateHeartsArray = () => {
    const hearts = [];
    for (let i = 1; i <= totalPoints; i++) {
      hearts.push({
        id: i,
        status: i <= usedPoints ? 'used' : 'available',
        isSelected: false,
      });
    }
    return hearts;
  };

  // Initialize heartsArray on component mount and when totalPoints or usedPoints change
  useEffect(() => {
    setHeartsArray(generateHeartsArray());
    setMaxSelected(false); // Reset maxSelected when points change
  }, [totalPoints, usedPoints]);

  // Update hearts based on user interaction
  const updateHearts = (action, id = null) => {
    if (action === 'clear') {
      const updatedHearts = heartsArray.map(heart => ({
        ...heart,
        isSelected: false,
      }));
      setHeartsArray(updatedHearts);
      setPointsToAllocate(0);
      setMaxSelected(false);
      return;
    }

    if (typeof action === 'number') {
      const updatedHearts = heartsArray.map(heart => {
        if (heart.id === action) {
          if (heart.isSelected) {
            // Deselect the heart
            return { ...heart, isSelected: false };
          } else {
            // Select the heart if maxSelectablePoints not reached
            const currentSelected = heartsArray.filter(h => h.isSelected).length;
            if (currentSelected < maxSelectablePoints) {
              return { ...heart, isSelected: true };
            } else {
              // Maximum reached, do not select more
              return heart;
            }
          }
        }
        return heart;
      });

      // Calculate new pointsToAllocate
      const selectedPoints = updatedHearts.filter(heart => heart.isSelected).length;

      // Update maxSelected state
      if (selectedPoints >= maxSelectablePoints) {
        setMaxSelected(true);
      } else {
        setMaxSelected(false);
      }

      setHeartsArray(updatedHearts);
      setPointsToAllocate(selectedPoints);
    }
  };

  return (
    <div className='largecomponentholder'>

      {/* Header */}
      <header className="title fergiving" >
        
    

      <div style={{ width:'12%', position: 'absolute', right: '35%', bottom:'15%'}}>
      <Pot 
      currentSeason = {currentSeason}
      seasonVotingPot = {currentSeasonData.seasonVotingPot}
      />
    </div>

    <div style={{ width:'8%', position: 'absolute', right: '27%', bottom: '10%'}}>
            <HeartStats
                  participationPercent={participationPercent}
                  valuePerPoint={valuePerPoint}

                />  
</div>

<div style={{ width:'4%', position: 'absolute', right: '20.5%', bottom: '18%'}}>
<Hourglass
        timeLeft={timeLeft}
        timeLeftPercent={timeLeftPercent}
        seasonStatus={seasonStatus}
      />
      </div>
        <span className="titletext fergiving" style={{marginRight:'61%'}}>
          FERGiving
          <span className='textlarge fergiving goldtext'style={{ width:'48%', position: 'absolute', left: '10%', bottom:'64%', transform:'rotate(-8deg)', fontSize:'0.3em'}}>
            {seasonStatus ? 
              <span>Feafon {seasonStatus}</span> 
              : 'Coming foon...'}
            
          </span>
        </span>
   
      </header>

      {/* Voting Info and Statistics */}
      <section className="voting-component-content">
        {currentSeasonData && (

          <>
       
          <div className='leftsection'>
        

          <UserSection
              maxSelectablePoints={maxSelectablePoints}
              pointsToAllocate={pointsToAllocate}
              isProcessing={isProcessing}
              votesPerName={votesPerName}
              isLoadingVotesReceived={isLoadingVotesReceived}
              blacklistStatus={blacklistStatus}
              estimatedRewards={estimatedRewards}
              isClaimingRewards={isClaimingRewards}
              handleClaimAllRewards={handleClaimAllRewards}
              votingHistory={votingHistory}
              isLoadingVotingHistory={isLoadingVotingHistory}
              selectedCandidate={selectedCandidate}
              onSelectCandidate={handleRowClick}
              handleAllocatePoints={handleAllocatePoints}
              heartsArray={heartsArray}
              updateHearts={updateHearts}
              totalPoints={totalPoints}
              seasonStatus={seasonStatus}
              actualClaimableRewards={actualClaimableRewards}
              seasons={seasons}
              votesReceivedPerSeason={votesReceivedPerSeason}
              rewardsPerSeason={rewardsPerSeason}
              handleClaimAllRewardsForSeason={handleClaimAllRewardsForSeason} // Pass the new handler
              isLoadingSeasons={isLoadingSeasons}
              currentSeason={currentSeason}
              currentSeasonTotalVotes={currentSeasonTotalVotes}
              userVotesInCurrentSeason={userVotesInCurrentSeason}
              handleClaimReward={handleClaimReward}
              timeLeft = {timeLeft}
              timeLeftPercent={timeLeftPercent}
              inputName={inputName}
              setInputName={setInputName}
              isNameValid={isNameValid}
              isLoadingTotalPoints={isLoadingTotalPoints}
              usedPoints={usedPoints} 
            />
            </div>
<div className='rightsection'>
<div className='rightsectionrow'>



</div>
        
 <HatWithNames
          names={votingData}
          selectedCandidate={selectedCandidate}
          onSelectCandidate={handleRowClick}
          handleAllocatePoints={handleAllocatePoints}
          handleClearAllocation={() => updateHearts('clear')}
          isProcessing={isProcessing}
          heartsArray={heartsArray}
          updateHearts={updateHearts} // Ensure this function is passed
          // Additional Props Passed After Moving JSX
          seasonStatus={seasonStatus}
          inputName={inputName}
          setInputName={setInputName}
          isNameValid={isNameValid}
          pointsToAllocate={pointsToAllocate}
          selectedNameTokenId={selectedNameTokenId}
          isLoadingTotalPoints={isLoadingTotalPoints}
          candidateNickname={candidateNickname}
          usedPoints={usedPoints}
          seasons={seasons}
          votesReceivedPerSeason={votesReceivedPerSeason}
          rewardsPerSeason={rewardsPerSeason}
        />
    </div>
       
          </>
        )}
      </section>

  </div>
);
};

export default VotingComponent;
